import { useState, useEffect } from 'react';
import { useDebounce } from './useDebouncing'
import { getUserPreference, setUserPreference } from '../utils/localStorageHelper/localStorageMethods'

const useListingActions = (pageType, apiFunction, defaultSortOption='latest', getExtraParmsList = () => ({})) => {
  const defaultPerPage = getUserPreference( 'page_size', pageType) || global.constants.perPage.default;
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(defaultPerPage);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOptionSelected, setSortOptionSelected] = useState(defaultSortOption);

  const debounceSearchTerm = useDebounce(searchTerm, 500)

  const fetchData = async (params, parentCall=false) => {
    // Call API function here with the appropriate parameters
    if (!parentCall){
      // If the call is not from the parent itself then fetch extra parameters to send if there are any
      const extraParams = getExtraParmsList()
      params = {...params, ...extraParams}
    }
    const result = await apiFunction(params);

    setData(result.data.data);
    setCount(result.data.count);
  };

  useEffect(() => {
    setPage(1)
    let params = { page: 1, per_page: perPage, q: searchTerm, sort_by: sortOptionSelected }
    fetchData(params);
  }, [debounceSearchTerm]);

  const handlerForSearchingAndSortingChange = (type, value) => {
    let params = {}
    if (type === 'sort'){
      setPage(1)
      params = { page: 1, per_page: perPage, q: searchTerm, sort_by: value }
      setSortOptionSelected(value)
      fetchData(params)
    } else if(type === 'search') {
      setSearchTerm(value)
    } else if(type === 'page'){
      setPage(value)
      params = { page: value, per_page: perPage, q: searchTerm, sort_by: sortOptionSelected }
      fetchData(params)
    } else if (type === 'pageSize'){
      setPerPage(value)
      setPage(1)
      setUserPreference('page_size', pageType, value)
      params = { page: 1, per_page: value, q: searchTerm, sort_by: sortOptionSelected }
      fetchData(params)
    }
  }

  return {
    data,
    setData,
    count,
    page,
    perPage,
    searchTerm,
    sortOptionSelected,
    handlerForSearchingAndSortingChange,
    fetchData,
  };
};

export default useListingActions;
