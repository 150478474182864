import React, { useEffect, useState } from 'react'
import { Grid, Card, Typography, Divider } from '@mui/material'
import { DashboardApi } from '../api/dashboardApi'
import Tile from '../components/Dashboard/tile'
import OrdersChart from '../components/Dashboard/ordersChart'
import OrdersTable from '../components/Order/ordersTableWidget'

const classes = {
  gridContainer: {
    padding: '2%',
    marginBottom: 0
  },
  viewAllBtn: {
    paddingRight: 0,
    textTransform: 'none'
  },
  ordersChartSelect: {
    paddingTop: '5%',
    paddingBottom: '5%',
    float: 'right'
  },
  topProductsList: {
    bgcolor: 'background.paper'
  },
  topProductsText: {
    fontSize: 'small',
    paddingLeft: '6%',
    color: 'gray'
  },
  ordersChartCard: {
    padding: '1%'
  },
  ordersChartContainer: { padding: '2%'}
}

const DashboardContainer = () => {
  const [orders, setOrders] = useState({ latestOrders: [], displayValue: 0, difference: 0 })
  const [ordersData, setOrdersData] = useState([])
  const [ordersChartOption, setOrdersChartOption] = useState('7days')
  const [sales, setSales] = useState({ displayValue: 0 })
  const storeCurrency = { name: 'Swedish Krone', symbol: 'kr', isoCode: 'sek' } //currency

  const calculateDifference = (object) => {
    if(object.newCount === object.lastMonthCount) {
      return 0
    }
    else if(object.newCount === 0 && object.lastMonthCount > 0) {
      return -100
    }
    else if(object.newCount > 0 && object.lastMonthCount === 0) {
      return 100
    }
    else {
      return parseInt(((object.newCount - object.lastMonthCount) / object.lastMonthCount) * 100);
    }
  }

  const fetchData = async () => {
    const result = await DashboardApi.tiles()
    setSales({ displayValue: result.data.totalSales })
    setOrders({ displayValue: result.data.orders.newCount, difference: calculateDifference(result.data.orders), latestOrders: result.data.orders.latestOrders })
  }

  const fetchOrdersChartData = async () => {
    const result = await DashboardApi.ordersChart(ordersChartOption)
    setOrdersData(result.data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    fetchOrdersChartData()
  }, [ordersChartOption])

  const handleOrderChartChange = (event) => {
    setOrdersChartOption(event.target.value)
  }

  return (
    <Grid container direction="row" spacing={2}>
      <Grid container item spacing={2} xs={9}>
        <Grid item xs={12}>
          <OrdersChart
            ordersData={ordersData}
            ordersChartOption={ordersChartOption}
            classes={classes}
            handleOrderChartChange={handleOrderChartChange} />
        </Grid>
        <Grid item xs={12}>
          <Card elevation={3}>
            <Grid item xs={12} sx={classes.gridContainer}><Typography variant='h5' color="textPrimary">Latest Orders</Typography></Grid>
            <Divider />
            <OrdersTable orders={orders.latestOrders} storeCurrency={storeCurrency} dashboard={true} />
          </Card>
        </Grid>
      </Grid>
      <Grid container direction="column" item spacing={2} xs={3}>
        <Grid item>
          <Tile title='Sales in Last Month' object={sales} module={global.constants.labels.profit} storeCurrency={storeCurrency} />
        </Grid>
        <Grid item>
          <Tile title='Orders in Last Month' object={orders} module={global.constants.labels.order} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DashboardContainer
