const { AbilityBuilder, Ability } = require("@casl/ability");

const PERMISSIONS = {  
  MANAGE: 'manage',  
  CREATE: 'create',  
  UPDATE: 'update',  
  DELETE: 'delete',
  LIST: 'list',
  SHOW: 'show',
  EDIT: 'edit'
}

const fetchAbilitiesForSuperAdmin = (can) => {
  can(PERMISSIONS.MANAGE, 'all');
};

const fetchGroupAbilitiesForManager = (permissions) => {
  let allowedForAll = { action: 'dashboard', subject: 'Home' };
  permissions.push(allowedForAll);
  return new Ability(permissions); // format => [{ action: 'show', subject: 'Order' }]
};

const useAbility = () => {
  const fetchRulesForUser = (permissions, user) => {
    const { can, cannot, rules } = new AbilityBuilder(Ability);
    if (user?.role === global.constants.userRoles.superAdmin) {
      fetchAbilitiesForSuperAdmin(can);
    }
    else if (user?.role === global.constants.userRoles.manager) {
      return fetchGroupAbilitiesForManager(permissions);
    }
    else {
      cannot(PERMISSIONS.MANAGE, 'all');
    }

    return new Ability(rules);
  }
  return { fetchRulesForUser }
}

module.exports = {
  useAbility,
};
