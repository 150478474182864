import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Grid, Typography, Autocomplete, TextField } from '@mui/material'
import { ProductApi } from '../../api/productApi'
import { StoreApi } from '../../api/storeApi'
import { setSuccessNotification } from '../../actions/notificationAction'
import { SortableProductList } from '../../shared-components'

const styles = {
  actionBtn: {
    textTransform: 'none',
    marginTop: '2%',
    marginLeft: '16px',
  },
  autocomplete: {
    width: 200,
  },
}

const ProductPositioningContainer = () => {
  const dispatch = useDispatch()
  const [products, setProducts] = useState([])
  const [stores, setStores] = useState([])
  const [selectedStore, setSelectedStore] = useState(null)

  const fetchProducts = useCallback(async (storeId) => {
    try {
      const response = await ProductApi.getProducts({ all: true, sort_by: 'Position ascending', store_id: storeId })
      if (response.status === 200) {
        setProducts(response.data.data)
      }
    } catch (error) {
      console.error('Error fetching products:', error)
    }
  }, [])

  const fetchAllStores = useCallback(async () => {
    try {
      const response = await StoreApi.getStores({ page: -1 })
      const storeList = response.data.data.map((store) => ({ id: store.id, name: store.name }))
      setStores(storeList)

      if (storeList.length > 0) {
        setSelectedStore(storeList[0])
        fetchProducts(storeList[0].id)
      }
    } catch (error) {
      console.error('Error fetching stores:', error)
    }
  }, [fetchProducts])

  useEffect(() => {
    fetchAllStores()
  }, [fetchAllStores])

  const savePositions = async () => {
    const updatedPositions = products.map((product, index) => ({
      id: product.id,
      position: index + 1,
    }))

    try {
      const response = await ProductApi.updateProductPositions(updatedPositions);
      if (response.status === 200) {
        dispatch(setSuccessNotification({ message: 'Product positions updated successfully.' }));
      }
    } catch (error) {
      console.error('Error updating product positions:', error);
    }
  }

  const handleStoreChange = (e, value) => {
    setSelectedStore(value);
    fetchProducts(value.id);
  }

  const handleMoveToPosition = (product, position) => {
    const pos = parseInt(position, 10);
    if (product && !isNaN(pos) && pos > 0 && pos <= products.length) {
      const newProducts = products.filter((p) => p.id !== product.id);
      newProducts.splice(pos - 1, 0, product);
      setProducts(newProducts);
    }
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={6} lg={6} xl={7}>
        <Typography variant="h3" component="div">Reorder Products</Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={5} container justifyContent="flex-end" alignItems="center">
        <Button
          sx={{ marginRight: '2%', borderColor: 'lightgrey', float: 'right' }}
          variant="outlined"
          href='/admin/products'
        >
          Back to Products
        </Button>
        <Autocomplete
          disablePortal
          id="stores"
          size="small"
          options={stores}
          value={selectedStore}
          getOptionLabel={(store) => store.name}
          onChange={handleStoreChange}
          sx={styles.autocomplete}
          renderInput={(params) => <TextField {...params} label="Search Store" />}
        />
        <Button variant="contained" color="primary" onClick={savePositions} sx={styles.actionBtn}>
          Save
        </Button>
      </Grid>
      <Grid item xs={12}>
        <SortableProductList
          products={products}
          setProducts={setProducts}
          handleMoveToPosition={handleMoveToPosition}
        />
      </Grid>
      <Grid item xs={12} container justifyContent="space-between" alignItems="center">
        <Button
          sx={{ marginRight: '2%', borderColor: 'lightgrey', float: 'right' }}
          variant="outlined"
          href='/admin/products'
        >
          Back to Products
        </Button>
        <Button variant="contained" color="primary" onClick={savePositions} sx={styles.actionBtn}>
          Save
        </Button>
      </Grid>
    </Grid>
  )
}

export default ProductPositioningContainer