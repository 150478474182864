import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import OrderContainer from '../containers/Order/orderContainer'
import ShowOrderContainer from '../containers/Order/showOrderContainer'
import EditOrderContainer from '../containers/Order/editOrderContainer'

const Order = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={OrderContainer} />
    <Route path={`${match.path}/:id/edit`} component={EditOrderContainer} />
    <Route path={`${match.path}/:id`} component={ShowOrderContainer} />
  </Switch>
)

Order.propTypes = {  
  match: PropTypes.object,
}

export default Order
