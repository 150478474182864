import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Divider, Paper, Link, Tooltip, FormHelperText } from '@mui/material'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

const Summary = props => {
  let discountLabel = 'Custom'
  if(props.order.order_discount) {
    if(props.freeDelivery) {
      discountLabel = 'Free Delivery'
    }
    else if(!props.order.order_discount.custom) {
      discountLabel = <Link target="_blank" href={`/admin/discounts/edit/${props.order.order_discount.discount_id}`}>{props.order.order_discount.discount?.name}</Link>
    }
    else{
      discountLabel = 'Custom'
    }
  }
  const styles = {
    freight: { textDecoration: props.freeDelivery ? 'line-through' : 'none' },
    paper: { border: '1px solid lightgrey' },
    paddingLeft: { paddingLeft: '1%' },
    padding: { padding: '1%' }
  }
  return (
    <Paper sx={styles.paper}>
      <Grid container>
        <Grid item xs={12} md={12} lg={12} xl={12} sx={styles.paddingLeft}>
          <h3>Summary</h3>
        </Grid>
        <Divider width="100%" />
        <Grid container spacing={1} sx={styles.padding}>
          <Grid container item>
            <Grid item xs={6}>Subtotal</Grid>
            <Grid item xs={6} textAlign="end" key={props.subtotal}>{ Math.round(props.subtotal*100)/100 } {props.storeCurrency.symbol}</Grid>
          </Grid>
          <br/>
          {(props.freeDelivery || parseFloat(props.order.discount) > 0) && (
            <Grid container item>
              <Grid item xs={6} key={props.order.order_discount}>Discount - {discountLabel}</Grid>
              {!props.freeDelivery && (
                <Grid item xs={6} textAlign="end" key={props.order.discount}>{props.order.discount} {props.storeCurrency.symbol}</Grid>
              )}
            </Grid>
          )}
          {props.editMode && (
            <Grid container item>
              <Grid item xs={6}>
                <Link
                  component="button"
                  onClick={props.handleAddDiscount} >
                  {props.order.order_discount ? 'Edit discount' : 'Add Discount'}
                </Link>
              </Grid>
            </Grid>
          )}
          <Grid container item>
            <Grid item xs={6} sx={styles.freight}>Freight</Grid>
            <Grid container item xs={6} spacing={1} justifyContent="end">
              <Grid item sx={styles.freight}>
                {`${props.order.freight || '0'} ${props.storeCurrency.symbol}`}
              </Grid>
              {props.editMode && (
                <Grid item>
                  <Tooltip
                    title={
                      <>
                        <p>{`Additional/Extra shipping cost: ${props.productFreight} ${props.storeCurrency.symbol}`}</p>
                        <p>{`Shipping cost: ${parseFloat(props.order.freight) - props.productFreight} ${props.storeCurrency.symbol}`}</p>
                      </>
                    }
                    placement="bottom-end">
                    <InfoOutlinedIcon />
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            {props.editMode && props.order.id > 0 && props.freightInfo && (
              <Grid container item justifyContent="end">
                <FormHelperText variant='standard' error={true}>{props.freightInfo}</FormHelperText>
              </Grid>
            )}
          </Grid>
          { props.order.prices_include_vat ? null : (
            <Grid container item>
              <Grid item xs={6}>VAT</Grid>
              <Grid item xs={6} textAlign="end" key={props.order.vat}>{props.order.vat} {props.storeCurrency.symbol}</Grid>
            </Grid>
          )}
          <Grid container item>
            <Grid item xs={6}><label>Total</label></Grid>
            <Grid item xs={6} textAlign="end"><label key={props.order.net_total}>{props.order.prices_include_vat ? props.order.net_total : Math.round((parseFloat(props.order.net_total) + parseFloat(props.order.vat))*100)/100} {props.storeCurrency.symbol}</label></Grid>
          </Grid>
          { props.order.prices_include_vat ? (
            <Grid container item>
              <Grid item xs={6}>{`Including ${Math.round(props.order.vat*100)/100} ${props.storeCurrency.symbol} VAT`}</Grid>
              <Grid item xs={6} textAlign="end"></Grid>
            </Grid>
          ) : null
          }
        </Grid>
      </Grid>
    </Paper>
  )
}

Summary.propTypes = {
  order: PropTypes.object.isRequired,
  productFreight: PropTypes.number,
  editMode: PropTypes.bool,
  onChange: PropTypes.func,
  freeDelivery: PropTypes.bool,
  handleAddDiscount: PropTypes.func,
  handleRemoveDiscount: PropTypes.func,
  validationErrors: PropTypes.object,
  storeCurrency: PropTypes.object.isRequired,
  subtotal: PropTypes.number.isRequired,
  freightInfo: PropTypes.string
}

export default Summary
