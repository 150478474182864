import { CategoryApi } from "../api/categoryApi"

// Create
export const CREATE_CATEGORY = '[Mstore] CREATE_CATEGORY'
export const CREATE_CATEGORY_SUCCESS = '[Mstore] CREATE_CATEGORY_SUCCESS'
export const CREATE_CATEGORY_ERROR = '[Mstore] CREATE_CATEGORY_ERROR'


// Read
export const GET_CATEGORIES = '[Mstore] GET_CATEGORIES'
export const GET_CATEGORIES_SUCCESS = '[Mstore] GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_ERROR = '[Mstore] GET_CATEGORIES_ERROR'


export const GET_CATEGORY = '[Mstore] GET_CATEGORY'
export const GET_CATEGORY_SUCCESS = '[Mstore] GET_CATEGORY_SUCCESS'
export const GET_CATEGORY_ERROR = '[Mstore] GET_CATEGORY_ERROR'

// Update
export const UPDATE_CATEGORY = '[Mstore] UPDATE_CATEGORY'
export const UPDATE_CATEGORY_SUCCESS = '[Mstore] UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_ERROR = '[Mstore] UPDATE_CATEGORY_ERROR'

// Delete
export const DELETE_CATEGORY = '[Mstore] DELETE_CATEGORY'
export const DELETE_CATEGORY_SUCCESS = '[Mstore] DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_ERROR = '[Mstore] DELETE_CATEGORY_ERROR'

export const RESET_CATEGORY_CREATED = '[Mstore] RESET_CREATED'
export const RESET_CATEGORY_UPDATED = '[Mstore] RESET_UPDATED'


// Create
export const createCategory = category => {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_CATEGORY
    })

    CategoryApi.createCategory(category).then(res => {
      dispatch(createCategorySuccess(res.data))
    })
  }
}

export const createCategorySuccess = category => {
  return {
    type: CREATE_CATEGORY_SUCCESS,
    payload: category
  }
}

export const createCategoryFailure = error => {
  return {
    type: CREATE_CATEGORY_ERROR,
    payload: error
  }
}

//Read
export const getCategories = (params, dependent = {}) => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_CATEGORIES
    })

    CategoryApi.getCategories(params, dependent).then(res => {
      dispatch(getCategoriesSuccess(res.data))
    })
  }
}

export const getCategoriesSuccess = data => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: data
  }
}

export const getCategoriesFailure = error => {
  return {
    type: GET_CATEGORIES_ERROR,
    payload: error
  }
}


export const getCategory = id => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_CATEGORY
    })

    CategoryApi.getCategory(id).then(res => {
      dispatch(getCategorySuccess(res.data))
    })
  }
}


export const getCategorySuccess = category => {
  return {
    type: GET_CATEGORY_SUCCESS,
    payload: category
  }
}

export const getCategoryFailure = error => {
  return {
    type: GET_CATEGORY_ERROR,
    payload: error
  }
}

//Update
export const updateCategory = (id, category) => {
  return (dispatch, getState) => {
    //Multiple actions can be sent usign the Redux-Thunk middleware
    dispatch({
      type: UPDATE_CATEGORY,
      payload: category
    })
    CategoryApi.updateCategory(id, category).then(res => {
      dispatch(updateCategorySuccess(res.data))
    })
  }
}
export const updateCategorySuccess = category => {
  return {
    type: UPDATE_CATEGORY_SUCCESS,
    payload: category
  }
}


//Delete
export const deleteCategory = category => {
  return (dispatch, getState) => {
    dispatch({
      type: DELETE_CATEGORY,
      payload: category
    })

    CategoryApi.removeCategory(category).then(res => {
      if (res.status === 204) {
        dispatch(deleteCategorySuccess(category))
      }
    })
  }
}

export const deleteCategorySuccess = category => {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    payload: category
  }
}

export const resetCreated = () => {
  return {
    type: RESET_CATEGORY_CREATED
  }
}

export const resetUpdated= () => {
  return {
    type: RESET_CATEGORY_UPDATED,
  }
}
