import * as notificationActions from "../actions/notificationAction";
import initialState from './initialState'

const notifications = (state = initialState.notifications, action) => {
  switch (action.type) {
    case notificationActions.SET_ERROR_NOTIFICATION:
      return { ...state, ...action.payload }
    case notificationActions.CLOSE_NOTIFICATION:
      return { ...state, ...action.payload }
    case notificationActions.SET_INFO_NOTIFICATION:
      return { ...state, ...action.payload }
    case notificationActions.SET_SUCCESS_NOTIFICATION:
      return { ...state, ...action.payload }
    default: 
      return state
  }
}

export default notifications
