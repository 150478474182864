import React, { useState, useEffect, useRef } from 'react'
import { CSVLink } from 'react-csv'
import { Grid, Button, Autocomplete, TextField } from '@mui/material'

import ListingPage from '../../components/Shared/ListingPage'
import { ListTable } from '../../components/Shared/ListingTable'
import Order from '../../components/Order/order'
import ListTableActions from '../../components/Shared/ListingTable/listTableActions'
import { StoreApi } from '../../api/storeApi'
import { OrderApi } from '../../api/orderApi'
import useListingActions from '../../hooks/useListingActions'
import { orderSorting } from '../../config/sortingLabels'

const styles = {
  actionBtn: {
    textTransform: 'none',
    marginTop: '2%',
    float: 'right'
  },
  autocomplete: {
    width: 200,
  }
}

const OrderContainer = () => {
  const [ordersForExport, setOrdersForExport] = useState([])
  const [stores, setStores] = useState([])
  const [selectedStore, setSelectedStore] = useState(() => JSON.parse(localStorage.getItem('selectedStore')) || null);

  const csvLink = useRef()
  const colsToRemove = ['paid_at', 'team_checkout', 'id', 'user']

  const handleExtraParameters = () => {
    if (selectedStore) {
      return { store_id: selectedStore.id }
    } else {
      return {}
    }
  }

  const {
    data,
    count,
    page,
    perPage,
    searchTerm,
    sortOptionSelected,
    handlerForSearchingAndSortingChange,
    fetchData,
  } = useListingActions('orders', OrderApi.getOrders, 'latest', handleExtraParameters);

  const fetchOrdersForExport = async () => {
    const result = await OrderApi.getOrdersForExport({ q: searchTerm, sort_by: sortOptionSelected })
    setOrdersForExport(result.data.map(order => {
      let copy = {}
      copy['order_id'] = order.id
      copy['customer_name'] = order.user?.name
      copy['customer_email'] = order.user?.email
      copy['customer_contact'] = order.user?.phone
      copy = {...copy, ...order}
      colsToRemove.forEach(col => (delete copy[col]))
      copy['createdAt'] = (new Date(copy.createdAt)).toLocaleDateString('sv')
      return copy
    }))
    csvLink.current.link.click()
  }

  const fetchAllStores = async () => {
    const response = await StoreApi.getStores({ page: -1 })
    setStores(
      response.data.data.map((store) => {
        return { id: store.id, name: store.name }
      })
    )
  }

  useEffect(() => {
    fetchAllStores()
  }, [])

  const handleStoreChange = (e, value) => {
    setSelectedStore(value)
    localStorage.setItem('selectedStore', JSON.stringify(value))
    fetchData({ page: 1, per_page: perPage, q: searchTerm, sort_by: sortOptionSelected, ...value && {store_id: value.id} }, true)
  }

  const tableActions = (
    <ListTableActions
      searchText='Search orders by order no, customer, city and country'
      searchTerm={searchTerm}
      sortOptionSelected={sortOptionSelected}
      getData={handlerForSearchingAndSortingChange}
      sortOptions={orderSorting}
    />
  )

  const storeCurrency = { name: 'Swedish Krone', symbol: 'kr', isoCode: 'sek' }
  const tableHeading = ['Order', 'Date', 'Store Name','Customer', 'City', 'Country', 'Total', 'Payment', 'Status', ''];
  const tableContent = data.map((order, index) => (
    <Order key={order.id} {...order} storeCurrency={storeCurrency} />
  ));
  const actionButtons = (
    <Grid container justifyContent="flex-end" spacing={1}>
      <Grid item>
        <Autocomplete
          disablePortal
          id='stores'
          size='small'
          options={stores}
          getOptionLabel={(store) => store.name}
          onChange={handleStoreChange}
          sx={styles.autocomplete}
          value={selectedStore}
          renderInput={(params) => (<TextField {...params} label='Search Store' />
          )}
        />
      </Grid>
      <Grid item>
        <Button variant="outlined" style={styles.actionBtn} onClick={fetchOrdersForExport}>{global.constants.labels.exportToCsv}</Button>
        <CSVLink
          data={ordersForExport}
          filename={`orders-${(new Date()).toLocaleDateString().replace(', ', '-')}.csv`}
          className='hidden'
          ref={csvLink}
          target='_blank' />
      </Grid>
    </Grid>
  )

  return (
    <ListingPage
      title="Orders"
      actions={actionButtons} 
      table={(
        <ListTable
          tableHeadings={tableHeading}
          tableContent={tableContent}
          tableActions={tableActions}
          count={count}
          page_no={page}
          rowsControl={true}
          onPageChange={(event, pageNo) => handlerForSearchingAndSortingChange('page', pageNo + 1)}
          handleChangeRowsPerPage={(event) => handlerForSearchingAndSortingChange('pageSize', event.target.value)}
          rowsPerPage={perPage || global.constants.perPage.orders} />
      )} />
  )
}

export default OrderContainer;
