import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import DeleteIcon from '@mui/icons-material/Delete'
import { Divider, Typography, Link } from '@mui/material'

import ImageThumbnail from '../Shared/ImageThumbnail'

const classes = {
  item: {
    padding: '2%',
  },
  imageIcon: {
    color: 'lightgrey',
    height: '100%',
    width: '100%'
  }
}

export default function EditOrderItem(props) {
  return (
    <React.Fragment>
      <Divider />
      <Grid container item xs={12} md={12} lg={12} spacing={3} sx={classes.item} alignItems="center">
        <Grid item xs={5} md={6}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={3} md={3}>
              <ImageThumbnail image={props.product.image} width='110px' height='110px' />
            </Grid>
            <Grid item xs={9} md={9}>
              <Link target='_blank' href={`/admin/products/edit/${props.product.id}`}>
                <Typography variant="h6">{props.product.name}</Typography>
              </Link>
              <p>{props.variant.name} {`${props.variant.article ? ' - ' + props.variant.article : ''}`}</p>
              <p>{props.quantity} x {props.price} {props.storeCurrency.symbol}</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} md={3}>
          <TextField
            label="Quantity"
            type="number"
            variant="outlined"
            min={1}
            value={props.quantity}
            size="small"
            onChange={e => props.onItemUpdate({id: props.id, quantity: parseInt(e.currentTarget.value)})} />
        </Grid>
        <Grid item xs={2} md={2}>
          {Math.round((props.quantity * props.price) * 100) / 100} {props.storeCurrency.symbol}
        </Grid>
        <Grid item xs={2} md={1}>
          <IconButton color="error" onClick={e => {window.confirm('Are you sure?'); props.onItemUpdate({id: props.id, _deleted: true}) }}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

EditOrderItem.propTypes = {
  id: PropTypes.number.isRequired,
  quantity: PropTypes.number,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  product: PropTypes.object,
  variant: PropTypes.object,
  onItemUpdate: PropTypes.func.isRequired,
  storeCurrency: PropTypes.object.isRequired
}
