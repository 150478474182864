import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
  Button,
  Box,
  Autocomplete,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { DataGrid } from '@mui/x-data-grid'
import { StoreApi } from '../../api/storeApi'
import DeleteIcon from '@mui/icons-material/Delete';


const styles = {
  header: {
    backgroundColor: '#F9F9F9',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem',
  },
  grid: {
    '& .MuiTablePagination-selectLabel': { margin: 0 },
    '& .MuiTablePagination-displayedRows': { margin: 0 },
    '& .MuiDataGrid-virtualScroller': { minHeight: '3rem' },
  },
  autocomplete: {
    width: 300,
    '& .MuiOutlinedInput-root': { paddingRight: '1rem !important' }
  }
}

const StorePermissionTable = ({ storePermissions, updatePermissions }) => {
  const [stores, setStores] = useState([])
  const [showAutocomplete, setShowAutocomplete] = useState(false)
  const existingStoreIds = storePermissions.map(storePermission => storePermission.store_id)

  const fetchAllStores = async () => {
    const response = await StoreApi.getStores({ page: -1 })
    setStores(
      response.data.data.map((store) => {
        return { id: store.id, name: store.name }
      })
    )
  }

  useEffect(() => {
    fetchAllStores()
  }, [])

  const columns = [
    { field: 'id', headerName: 'Store Id', flex: 1 },
    { field: 'name', headerName: 'Store name', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <IconButton
          edge="end"
          onClick={(e) => {
            if (window.confirm('Are you sure?')) updatePermissions(params.row.id, true)
          }}
        >
          <DeleteIcon edge="end" color="error" />
        </IconButton>
      ),
    },
  ]

  const DataGridTitle = () => (
    <Box sx={styles.header} alignItems="center">
      <Typography variant='h5' color='textPrimary'>
        Store Permissions
      </Typography>
      {showAutocomplete ? (
        <Autocomplete
          disablePortal
          id='stores'
          size='small'
          options={stores.filter(store => !existingStoreIds.includes(store.id))}
          getOptionLabel={(store) => store.name}
          onChange={(e, value) => updatePermissions(value)}
          sx={styles.autocomplete}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Search Store'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton edge='end' onClick={() => setShowAutocomplete(false)}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      ) : (
        <Button onClick={() => setShowAutocomplete(true)} variant='outlined'>Add</Button>
      )}
    </Box>
  )

  console.log(storePermissions.filter(permission => !permission._deleted).map(permission => ({ id: permission.store_id, name: permission.store.name })))
  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <DataGrid
          slots={{ toolbar: DataGridTitle }}
          rows={storePermissions.filter(permission => !permission._deleted).map(permission => ({ id: permission.store_id, name: permission.store.name }))}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20, 50]}
          sx={styles.grid}
        />
      </Grid>
    </Grid>
  )
}

StorePermissionTable.propTypes = {
  storePermissions: PropTypes.array.isRequired,
  updatePermissions: PropTypes.func.isRequired,
}

export default StorePermissionTable
