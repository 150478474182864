export function setUrlForGetRequest(url, params){
  url = `${url}?`
  Object.keys(params).forEach((key, index) => {
    if(params[key]){
      if(index === 0) {
        url += `${key}=${params[key]}`
      }
      else {
        url += `&${key}=${params[key]}`
      }
    }
  })
  return url
}
