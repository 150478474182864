import React, { useState } from 'react'
import { connect } from 'react-redux'
import ListingPage from '@core-ui-shared/ListingPage'
import { ListTable } from '@layout-shared/ListingTable'
import { StoreApi } from '../api/storeApi'
import Store from '../components/store'
import * as notificationActions from "../../../actions/notificationAction"
import ListTableActions from '@layout-shared/ListingTable/listTableActions'
import { bindActionCreators } from 'redux'
import { getSerialNumber } from '../../../utils/sharedFunctions'
import { storeSorting } from '../../../config/sortingLabels'
import useListingActions from '../../../hooks/useListingActions'
import { Button } from '@mui/material'
import CreateStoreModal from '../components/CreateStoreModal'
import { Tab, Tabs } from '@mui/material'
import useTranslation from '../../../hooks/useTranslation'

const StoreContainer = () => {
  const [open, setOpen] = useState(false)
  const [tabValue, setTabValue] = useState('live')
  const { t } = useTranslation()

  const handleExtraParameters = () => {
    return { filter: tabValue, includeDeleted: tabValue === 'deleted' }
  }

  const {
    data,
    setData,
    count,
    page,
    perPage,
    searchTerm,
    sortOptionSelected,
    handlerForSearchingAndSortingChange,
    fetchData
  } = useListingActions('stores', StoreApi.getStores, 'latest', handleExtraParameters)

  const setParamsAndFetchData = (extraParamObject) => {
    let params = { q: searchTerm, sort_by: sortOptionSelected, page: 1, per_page: perPage, filter: tabValue }
    params = { ...params, ...extraParamObject }
    fetchData(params, true)
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
    setParamsAndFetchData({ filter: newValue, includeDeleted: newValue === 'deleted' })
  }

  const handleReloadStores = (newStore) => {
    setData((prevData) => [...prevData, newStore]);
  }

  const handleDelete = async (storeId) => {
    const confirmed = window.confirm(t('labels.store.delete_confirmation'));
    if (confirmed) {
      try {
        await StoreApi.destroy(storeId);
        setData((prevData) => prevData.filter(store => store.id !== storeId));
      } catch (error) {
        console.error('Failed to delete store:', error);
      }
    }
  };

  const handleRestore = (storeId) => {
    const confirmed = window.confirm(t('labels.store.restore_confirmation'));
    if (confirmed) {
      setData((prevData) =>
        prevData.map((store) =>
          store.id === storeId ? { ...store, is_live: true, deletedAt: null } : store
        )
      );
    }
  };

  const buttons = (
    <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
      {t('labels.create_store')}
    </Button>
  )

  const tableContent = storeTableContent(data, page, perPage, handleDelete, handleRestore)
  const tableActions = (
    <ListTableActions
      searchText={t('labels.search_stores')}
      searchTerm={searchTerm}
      sortOptionSelected={sortOptionSelected}
      getData={handlerForSearchingAndSortingChange}
      sortOptions={storeSorting}
    />
  )
  return (
    <>
      {open && (
        <CreateStoreModal
          open={open}
          onClose={() => setOpen(false)}
          reloadStores={handleReloadStores}
          stores={data}
        />
      )}
      <ListingPage
        title={t('labels.menu.store_settings')}
        actions={buttons}
        table={
          <>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="store tabs">
              <Tab label={t('labels.live')} value="live" />
              <Tab label={t('labels.offline')} value="not_live" />
              <Tab label={t('labels.deleted')} value="deleted" />
            </Tabs>
            <ListTable
              tableHeadings={tableContent.heading}
              tableContent={tableContent.body}
              tableActions={tableActions}
              count={count}
              page_no={page}
              rowsPerPage={perPage}
              onPageChange={(event, pageNo) => fetchData({ page: pageNo + 1 })}
              handleChangeRowsPerPage={(event) => fetchData({ pageSize: event.target.value })}
              rowsControl={true}
            />
          </>
        }
      />
    </>
  )
}

const storeTableContent = (stores = [], page_no, per_page, handleDelete, handleRestore) => {
  const { t } = useTranslation()
  const body = stores.map((store, index) => (
    <Store key={store.id} {...store} index={getSerialNumber(page_no, per_page, index)} onDelete={handleDelete} onRestore={handleRestore} />
  ))
  return { body: body ?? [], heading: [t('labels.id'), '', t('labels.name'), t('labels.store_settings.company_name'), t('labels.live'), 'Action'] }
}

const mapDispatchToProps = dispatch => {
  return {
    notifyActions: bindActionCreators(notificationActions, dispatch)
  }
}

export default connect(mapDispatchToProps)(StoreContainer)