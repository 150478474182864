import { combineReducers } from 'redux'
import store from './store'
import errors from './errors'
import orders from './orders'
import notifications from './notification'
import products from './products'
import autocomplete from './autocomplete'
import categories from './categories'

const rootReducer = combineReducers({
  store,
  errors,
  orders,
  notifications,
  products,
  categories,
  autocomplete
})

export default rootReducer
