import React from "react";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

const BackButton = () => {

  const history = useHistory();

  return (
    <Button
      type="submit"
      color="primary"
      variant="outlined"
      onClick={history.goBack}>
       Back
    </Button>
  )

}

export default BackButton;