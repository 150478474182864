import React from 'react'
import PropTypes from 'prop-types'
import { Grid, MenuItem, TextField } from '@mui/material'

const ListTableActions = ({ searchText, searchTerm, getData, sortOptionSelected, sortOptions }) => {

  const handleChange = (event) => {
    getData(event.target.name, event.target.value)
  }

  return (
    <Grid container item xs={12} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
      <Grid item xs={8}>
        <TextField
          id="searchName"
          type="text"
          label={searchText}
          name="search"
          size="small"
          value={searchTerm}
          fullWidth
          onChange={(event) => handleChange(event)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="sort"
          label="Sort By"
          value={sortOptionSelected}
          placeholder="Sort By"
          variant='outlined'
          size='small'
          onChange={(event) => handleChange(event)}
          select
          fullWidth>
          {Object.keys(sortOptions).map(option => (
            <MenuItem key={option} value={sortOptions[option]}>{option}</MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )
}

ListTableActions.propTypes = {
  searchText: PropTypes.string,
  searchTerm: PropTypes.string,
  getSearchedItems: PropTypes.func,
  sortOptionSelected: PropTypes.string,
  getItems: PropTypes.func,
  sortOptions: PropTypes.object
}

export default ListTableActions
