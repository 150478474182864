import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { TableCell, TableRow } from '@mui/material'

const roleMapping = {
  superadmin: 'Super User',
  staff: 'Staff'
}

const User = props => {
  return (
    <TableRow key={props.id}>
      <TableCell component="th" scope="row"><Link to={`/admin/users/${props.id}/edit`}>{props.index}</Link></TableCell>
      <TableCell component="th" scope="row"><Link to={`/admin/users/${props.id}/edit`}>{props.name}</Link></TableCell>
      <TableCell component="th" scope="row">{props.email}</TableCell>
      <TableCell component="th" scope="row">{roleMapping[props.role]}</TableCell>
    </TableRow>
  )
}

User.propTypes = {
  index: PropTypes.number,
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.string,
}

export default User
