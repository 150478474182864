/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material';
import countryList from 'react-select-country-list'
import VariantsContainer from './Variant/variantsContainer'
import { CollectionApi } from '../../api/collectionApi'
import { ProductApi } from '../../api/productApi';
import { useDispatch } from 'react-redux';
import { getProduct } from '../../actions/productActions';
import { setSuccessNotification } from '../../actions/notificationAction';
// import '../Collection/Collection.css'
import EditPageLowerContainer from '../Shared/EditPageLowerContainer';
import { ProductInfo, ProductVisibility, ProductLogs, ProductCollections, ProductMedia, StockSyncModal, FulfillmentCenter } from '../../shared-components';
import useTranslation from '../../hooks/useTranslation'

const EditProduct = props => {
  const dispatch = useDispatch()
  const [term, setTerm] = useState('');
  const [collections, setCollections] = useState([])
  const [searchRequestStatus, setSearchRequestStatus] = useState('pending')
  const [selectedImages, setSelectedImages] = useState([])
  const [hasVariants, setHasVariants] = useState(props.product.has_variants);
  const [isBundle, setIsBundle] = useState(props.product.is_bundle ?? false);
  const [productUnitPrice, setProductUnitPrice] = useState(props.product.price ?? '');
  const options = useMemo(() => countryList().getData(), [])
  const [productCollections, setProductCollections] = useState([])
  const productCollectionsIds = productCollections?.map(collection => collection.id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stockData, setStockData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()
  
  const handleSelectedImagesUpdate = (event, image) => {
    let images = [...selectedImages]
    if (event.target.checked){
      images.push(image.id)
    } else {
      images = images.filter(img => img !== image.id)
    }
    setSelectedImages([...images])
  }

  useEffect(() => {
    setHasVariants(props.product.has_variants);
  }, [props.product.has_variants]);

  useEffect(() => {
    setIsBundle(props.product.is_bundle);
  }, [props.product.is_bundle]);

  useEffect(() => {
    setProductUnitPrice(props.product.price);
  }, [props.product.price]);
  

  useEffect(()=>{
    if (selectedImages) {
      setSelectedImages([])
    }  
  },[props.setSelectedImages])
  
  useEffect(() => {
    setProductCollections(props.product.collections)
  }, [props.product.collections])

  const fetchCollections = () => {
    CollectionApi.searchCollections(term, 0, props.product.store_id)
      .then(({status, data}) => {
        if( status === 200 ) {
          if ( data?.data?.length === 0 ){
            setSearchRequestStatus('No Collections Found')
          }
          let selectedProducts = data?.data.filter(collection => !productCollectionsIds?.includes(collection.id) )
          setCollections(selectedProducts)
        }
      })
  }

  const addProductToCollection = async (collectionId, actionType) => {
    let updatedProductCollectionsIds = [...productCollectionsIds];
    if (actionType === 'add') {
      updatedProductCollectionsIds.push(collectionId);
    } else {
      updatedProductCollectionsIds = updatedProductCollectionsIds.filter(id => id !== collectionId);
    }
    const response = await ProductApi.addToCollection({ collectionIds: updatedProductCollectionsIds, productId: props.product.id });
    if (response?.status === 200) {
      if (actionType === 'add') {
        const filteredCollections = collections.filter(collection => collection.id === collectionId);
        setProductCollections([...response.data, ...filteredCollections]);
        setCollections(collections.filter(collection => collection.id !== collectionId));
        dispatch(setSuccessNotification({ message: 'Product is added to the Collection' }));
      } else {
        setProductCollections(response.data.filter(collection => collection.id !== collectionId));
        dispatch(setSuccessNotification({ message: 'Product is removed from the Collection' }));
      }
      dispatch(getProduct(props.product.id));
    }
  }

  const leftFirstContainer = () => (
    <ProductInfo {...props} hasVariants={hasVariants} options={options} />
  )

  const rightFirstContainer = () => (
    <ProductVisibility {...props} />
  )

  const rightSecondContainer = () => (
    <ProductCollections
      productCollections={productCollections}
      productCollectionsIds={productCollectionsIds}
      addProductToCollection={addProductToCollection}
      fetchCollections={fetchCollections}
      collections={collections}
      term={term}
      setTerm={setTerm}
      searchRequestStatus={searchRequestStatus}
    />
  )

  const leftSecondContainer = () => (
    <ProductMedia
    product={props.product}
    updateImage={props.updateImage}
    sortImages={props.sortImages}
    newImage={props.newImage}
    removeImage={props.removeImag}
    handleSelectedImagesUpdate={handleSelectedImagesUpdate}
    selectedImages={selectedImages}
    searchRequestStatus={searchRequestStatus}
  />
  )

  const leftThirdContainer = () => (
    <Grid>
      <VariantsContainer
        productImages={props.product.images}
        onProductChange={props.onChange}
        hasVariants={hasVariants}
        setHasVariants={setHasVariants}
        isBundle={isBundle}
        setIsBundle={setIsBundle}
        unitPrice={productUnitPrice}
        variants={props.product.variants}
        onUpdate={props.updateVariant}
        sortArray={props.sortVariants}
        newVariant={props.newVariant}
        type={'variants'}
        pageType={props.product > 0 ? 'Edit' : 'New'}
      />
    </Grid>
  )

  const handleRemoveFulfillmentCenter = () => {
    props.onChange({ target: { name: "fulfillment_center_id", value: '0' } });
    ProductApi.updateProduct(props.product.id, props.product)
  };

  const handleSyncStock = async () => {
    setIsModalOpen(true);
    setLoading(true);
    try {
      const response = await ProductApi.fetchStock(props.product.id); 
      if (response.status === 200) {
        setStockData(response.data.products);
      }
    } catch (error) {} finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSyncStockInModal = async () => {
    try {
      const response = await ProductApi.syncStock(props.product.id);
      if (response.status === 200) {
        dispatch(setSuccessNotification({ message: t('response_messages.products.stock_synced') }));
      }
    } catch (error) {
      dispatch(setSuccessNotification({ message: t('response_messages.products.stock_synced_failed') }));
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleUpdateFulfillmentCenter = async (event) => {
    const newFulfillmentCenterId = event.target.value;
    props.onChange({ target: { name: "fulfillment_center_id", value: newFulfillmentCenterId } });
    try {
      const response = await ProductApi.updateProduct(props.product.id, props.product);
      if (response.status === 200) {
        dispatch(setSuccessNotification({ message: t('response_messages.products.success_fulfillment_update') }));
      }
    } catch (error) {
      dispatch(setSuccessNotification({ message: t('response_messages.products.failed_fulfillment_update') }));
    }
  };

  const rightFourthContainer = () => (
    <FulfillmentCenter
      fulfillmentCenterId={props.product.fulfillment_center_id}
      fulfillmentCenters={props.fulfillmentCenters}
      onRemove={handleRemoveFulfillmentCenter}
      onSyncStock={handleSyncStock}
      onChange={handleUpdateFulfillmentCenter}
    />
  )

  const rightThirdContainer = () => (
    <ProductLogs {...props} />
  )

  return(
    <form onSubmit={props.onSave}>
      <EditPageLowerContainer
        leftContent={ [leftFirstContainer, leftSecondContainer, leftThirdContainer] }
        rightContent={ props.product.id > 0 ? [rightFirstContainer, rightSecondContainer, rightThirdContainer, rightFourthContainer] : [rightFirstContainer, rightThirdContainer] }
        actionType={props.product.id > 0 ? 'Edit' : 'New'}
      />
      <StockSyncModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        stockData={stockData}
        loading={loading}
        onSyncStock={handleSyncStockInModal}
      />
    </form>
  )
}

EditProduct.propTypes = {
  product: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  validationErrors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  updateVariant: PropTypes.func.isRequired,
  updateImage: PropTypes.func.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  newImage: PropTypes.func,
  sortVariants: PropTypes.func,
  newVariant: PropTypes.func
}

export default EditProduct