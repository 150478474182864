import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useHistory } from 'react-router-dom'

const linkSx = {
  ':hover': {
    color: 'inherit'
  },
  ':focus': {
    outline: 'none',
    textDecoration: 'none',
    color: 'inherit'
  }
}

const ListItemBody = ({config}) => {
  const history = useHistory()
  // programmatically navigate to prevent ripple issues with <a> component
  const navigate = (e) => {
    e.preventDefault()
    if(config.to) {
      history.push(config.to)
    }
  }

  return (<ListItemButton component="a" href={config.to} onClick={navigate} selected={checkActiveLink(config)} sx={linkSx}>
    <ListItemIcon>{config.icon}</ListItemIcon>
    <ListItemText primary={config.title} />
  </ListItemButton>)
}

const checkActiveLink = (config) => {
  // Get pathname without last slash
  const pathName = window.location.pathname.replace(/\/+$/, '')
  return ((pathName === config.to || config.extraLinks?.some( link => pathName.includes(link) )))
}

const MenuItem = ({ config }) => (<ListItem><ListItemBody config={config}/></ListItem>)

const ExpandableMenuItem = ({ config, ability }) => {
  const [open, setOpen] = useState(config.open || false)

  const handleClick = () => {
    setOpen(!open)
  }

  useEffect(()=>{

    // Expand the menuitem if the path contains any of its child items.
    const expanded = config.items?.some( configItem => checkActiveLink(configItem))
    setOpen(expanded)
  },[config])

  // Check if any of the items in sub menu are accessible to user then render the parent and those items
  // If there are items without 'ability' defined they should be by default visible
  // Or any of the children items with 'ability' definition are accessible then render the parent
  return (
    (config.items &&  ([...config.items].filter(i => !Object(i).hasOwnProperty('ability')).length || [...config.items].filter(i => Object(i).hasOwnProperty('ability')).map(item => hasAccess(ability, item)).includes(true))) ? (
      <div component="nav">
      <ListItem onClick={handleClick}>
        <ListItemBody config={config}/>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Menu items={config.items} ability={ability}/>
      </Collapse>
    </div>
    )
    :
    null
  )
}

const hasAccess = (ability, item) => {
  return ability.can(item.ability?.action, item.ability?.controller)
}

export default function Menu({ items, ability }) {
  const createList = (items) => {
    let menu = []
    items.map((menuItem) => {
      if (Array.isArray(menuItem.items) && menuItem.items.length > 0) {
        menu.push(<ExpandableMenuItem
          config={menuItem}
          key={menuItem.title}
          ability={ability}
        />)
      } else {
        if(!menuItem.ability || hasAccess(ability, menuItem)) {
          menu.push(<MenuItem
            config={menuItem}
            key={menuItem.title}
          />)
        }
      }
    })
    return menu.concat()
  }

  return <List sx={{ maxHeight: '100vh', overflow: 'auto' }}>{createList(items)}</List>
}

Menu.propTypes = {
  items: PropTypes.array.isRequired,
  ability: PropTypes.object
}
