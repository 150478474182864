import * as orderActions from '../actions/orderActions'
import initialState from './initialState'

const orders = (state = initialState.orders, action) => {
  let newState, indexOfOrderToGet, newStateAfterGet, indexOfOrderToUpdate, indexOfOrderToDelete
  switch (action.type) {
    case orderActions.GET_ORDERS:
      return {
        ...state,
        loading: true
      }
    case orderActions.GET_ORDERS_SUCCESS:
      return {
        ...action.payload,
        loading: false
      }
    case orderActions.GET_ORDER:
      return {
        ...state,
        loading: true
      }
    case orderActions.GET_ORDER_SUCCESS:
      indexOfOrderToGet = state.data.findIndex(({ id }) => id === action.payload.id)
      newStateAfterGet = { ...state }
      if (indexOfOrderToGet === -1) {
        newStateAfterGet.data.push(action.payload)
      } else {
        newStateAfterGet.data[indexOfOrderToGet] = action.payload
      }
      return newStateAfterGet
    case orderActions.UPDATE_ORDER:
      return {
        ...state,
        updating: true
      }
    case orderActions.UPDATE_ORDER_SUCCESS:
      indexOfOrderToUpdate = state.data.findIndex(({ id }) => id === action.payload.id)
      newState = {...state}
      newState.data[indexOfOrderToUpdate] = action.payload
      return newState
    case orderActions.DELETE_ORDER:
      return {
        ...state
      }
    case orderActions.DELETE_ORDER_SUCCESS:
      indexOfOrderToDelete = state.data.findIndex(({ id }) => id === action.payload.id)
      newState = { ...state }
      newState.data.splice(indexOfOrderToDelete, 1)
      return newState
    default:
      return state
  }
}

export default orders
