import { HttpClient } from './httpClient'
import { API_URL } from './RestAPI'
import apiHeaders from './apiHeaders'
import {setUrlForGetRequest} from './sharedFunctions'

const ORDER_FULFILLMENT_API = `${API_URL}/fulfillment-center-orders`

const getOrders = (params, dependent = {}) => {
  let url = setUrlForGetRequest(ORDER_FULFILLMENT_API, params)
  return HttpClient.get(url, apiHeaders(dependent))
}

const getOrder = id => {
  return HttpClient.get(
    `${ORDER_FULFILLMENT_API}/${id}`,
    apiHeaders()
  )
}

const OrderFulfillmentCenterApi = {
  getOrders, getOrder
}

export { OrderFulfillmentCenterApi }