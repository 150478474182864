import {HttpClient} from './httpClient'
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'

//Setting the product inventory URI
const PRODUCT_INVENTORY_API = `${API_URL}/products`

// Create
const addProductStock = data => {
  return HttpClient.post(
    `${PRODUCT_INVENTORY_API}/${data.product_variant_id}/product-inventory/create`,
    data,
    apiHeaders()
  )
}

const ProductInventoryApi = { addProductStock }

export { ProductInventoryApi }
