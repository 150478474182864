import AuthStorage from '../services/authStorage'
const Auth = new AuthStorage()

const apiHeaders = (optionalHeaders = {}) => {
  let headers = {
    'accept': 'application/json',
    'Authorization': `Bearer ${Auth.getToken()}`
  }
  headers = { ...headers, ...optionalHeaders };
  return {
    headers
  }
}

export default apiHeaders