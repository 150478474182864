import {HttpClient} from './httpClient'
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'

const DASHBOARD_API = `${API_URL}/dashboard`

const tiles = () => {
  return HttpClient.get(
    `${DASHBOARD_API}/tiles`,
    apiHeaders()
  )
}

const ordersChart = (selectedOption = 0) => {
  return HttpClient.get(
    `${DASHBOARD_API}/ordersChart?selectedOption=${selectedOption}`,
    apiHeaders()
  )
}

const DashboardApi = { tiles, ordersChart }

export {DashboardApi}
