import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { tableCellClasses } from '@mui/material/TableCell';

const styles = {
  subContainer: {
    marginTop: 10,
  },
  styledTableCell: {
    fontWeight: 'bold',
    fontSize: 14,
  }
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 'bold',
    fontSize: 14,
    backgroundColor: theme.palette.action.hover
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const AddStores = ({ stores, selectedStore, setSelectedStore, onStoreSelected, onRemoveStoreClicked, fulfillmentCenter }) => {
  
  const formatStoreList = (stores) => {
    if (stores.length === 0) return [];
    
    let existingStoreIds = fulfillmentCenter?.stores?.map((store) => store.id) || [];
    return stores.filter((store) => !existingStoreIds.includes(store.id))
                 .map((store) => ({ label: store.name, id: store.id }));
  };

  return (
    <div>
      <Typography variant="h6">Add Stores</Typography>
      <Autocomplete
        disablePortal={true}
        inputValue={selectedStore}
        onInputChange={(event, newValue, reason) => {
          if (reason === 'reset') {
            setSelectedStore('');
          } else {
            setSelectedStore(newValue);
          }
        }}
        onChange={onStoreSelected}
        options={formatStoreList(stores)}
        getOptionLabel={(option) => option.label ? option.label : ''}
        renderInput={(params) => <TextField {...params} label="Stores" variant="outlined" />}
      />
      <TableContainer component={Paper} style={styles.subContainer}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align="right">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fulfillmentCenter?.stores?.map((store) => (
              <StyledTableRow key={store.id}>
                <StyledTableCell><Link to={`/admin/stores/${store.id}/edit`}>{store.id}</Link></StyledTableCell>
                <StyledTableCell>{store.name}</StyledTableCell>
                <StyledTableCell align="right">
                  <IconButton color="error" onClick={() => onRemoveStoreClicked(store.id)}>
                    <RemoveCircleIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {fulfillmentCenter?.stores?.length === 0 && (
          <Typography align="center">No Stores added!</Typography>
        )}
      </TableContainer>
    </div>
  );
};

AddStores.propTypes = {
  stores: PropTypes.array.isRequired,
  selectedStore: PropTypes.string.isRequired,
  setSelectedStore: PropTypes.func.isRequired,
  onStoreSelected: PropTypes.func.isRequired,
  onRemoveStoreClicked: PropTypes.func.isRequired,
  fulfillmentCenter: PropTypes.object.isRequired
};

export default AddStores;