import {HttpClient} from './httpClient' 
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'
import {setUrlForGetRequest} from './sharedFunctions'

//Setting the categories URI
const Collection_API = `${API_URL}/collections`
const PRODUCT_COLLECTION_API = `${API_URL}/collection`

// Create
const createCollection = collection => {
  return HttpClient.post(
    `${Collection_API}/create`,
    collection,
    apiHeaders()
  )
}

// Read
const getCollections = (params, dependent = {}) => {
  let url = setUrlForGetRequest(Collection_API, params)
  return HttpClient.get(url, apiHeaders(dependent))
}

// Get
const getCollection = id => {
  return HttpClient.get(
    `${Collection_API}/${id}`,
    apiHeaders()
  )
}

// Update
const updateCollection = (id, collection) => {
  console.log('check Collection Object', collection)
  return HttpClient.put(
    `${Collection_API}/${id}/update`,
    collection,
    apiHeaders()
  )
}

// Delete
const removeCollection = collection => {
  return HttpClient.delete(
    `${Collection_API}/${collection.id}`,
    apiHeaders()
  )
}

const searchCollections = (q, page=0, storeId) => {
  return HttpClient.get(
    `${Collection_API}?q=${q}&page=${page}&store_id=${storeId}`,
    apiHeaders()
  )
}

const addProductsToCollection = (body) => {
  return HttpClient.post(
    `${PRODUCT_COLLECTION_API}/addProducts`,
    body,
    apiHeaders()
  )
}

//Encapsulating in a JSON object
const CollectionApi = {getCollections, createCollection, getCollection, updateCollection, removeCollection, searchCollections,addProductsToCollection}

export {CollectionApi}