import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Grid, Button, TextField, IconButton, Typography, Chip, FormHelperText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { uniqBy } from 'lodash';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const VariantOption = (props) => {
  const { object, onUpdate, objects } = props;
  const [variantOption, setVariantOption] = useState({...object});
  const [variantAttributes, setVariantAttributes] = useState([...object.variantAttributeValues]);

  const [validateOption, setValidateOption] = useState(false);
  const [validateOptionError, setValidateOptionError] = useState('');
  const [validateAttribute, setValidateAttribute] = useState(false);
  const [validateAttributeError, setValidateAttributeError] = useState('');
  
  const [editState, setEditState] = useState(object.variantOption === '' ? true : false);

  useEffect(() => {
    setVariantOption({...object});
    setVariantAttributes([...object.variantAttributeValues])
  }, [object, object.variantAttributeValues])

  const handleOnOptionDelete = () => {
    let option = {...variantOption};
    option._delete = true;
    setVariantOption(option);
    setVariantAttributes([]);
    onUpdate(option);
  }

  const handleOnAttributeDelete = (index) => {
    let attributes = [...variantAttributes];
    let option = { ...variantOption };
    if (attributes[index]) {
      let attributesCount = attributes.filter((attr) => !attr._delete).length;
      if (attributesCount === 1) { 
        attributes[index].attributeName = '';
      } else {
        attributes[index]._delete = true;
        attributes = attributes.filter((attr) => attr.id !== (attributes[index]?.id || attributes[index]?._id) && attr._id !== (attributes[index]?.id || attributes[index]?._id));
      }
      option.variantAttributeValues = attributes;
      setVariantAttributes(attributes);
      setVariantOption(option);
      onUpdate(option);
    }
  }

  const onVariantOptionChanged = (event) => {
    let option = { ...variantOption };
    option.variantOption = event.target.value;
    if (option.variantOption !== '') {
      setValidateOptionError('');
      setValidateOption(false);
    } else {
      setValidateOptionError('Option name is required.');
      setValidateOption(true);
    }
    setVariantOption(option);
  }

  const onAttributeValueChanged = (event) => {
    if (event.target.value.includes('/')) return;
    let attributes = [...variantAttributes];
    let option = { ...variantOption };
    attributes[event.target.id].attributeName = event.target.value;
    let emptyAttributes = attributes.filter((attribute) => !attribute._delete && attribute.attributeName === '');
    if (emptyAttributes.length < 1) attributes.push({ _id: variantAttributes.length + 1, attributeName: '' });
    option.variantAttributeValues = attributes;
    setValidateAttribute(false);
    setValidateAttributeError('');
    setVariantAttributes(attributes);
    setVariantOption(option);
  }

  const handleOnDoneBtnClicked = () => {
    let option = { ...variantOption };
    let updatedOptions = [...objects];
    let attributes = option.variantAttributeValues.filter((attr) => !attr._delete);
    if (option.variantOption === '') {
      setValidateOptionError('Option name is required.');
      return setValidateOption(true);
    }
    if (attributes.length === 1 && attributes[0].attributeName === '') {
      setValidateAttributeError("Option value is required.");
      return setValidateAttribute(true);
    }
    // Check duplicate options
    let duplicateOption = updatedOptions.filter((opt) => !opt._delete && opt.id !== option.id && opt.variantOption === option.variantOption);
    if (duplicateOption.length > 0) {
      setValidateOptionError(`You've already used the option name "${option.variantOption}".`);
      return setValidateOption(true);
    }
    option.variantAttributeValues = option.variantAttributeValues.filter((attr) => attr.attributeName !== '');
    // Save only Unique attributes
    option.variantAttributeValues = uniqBy(option.variantAttributeValues , 'attributeName');
    setVariantAttributes(option.variantAttributeValues);
    setVariantOption(option);
    if (!validateAttribute && !validateOption) {
      setEditState(false);
      onUpdate(option);
    }
  }

  const handleOnDiscardBtnClicked = () => {
    let option = { ...variantOption };
    let attributes = option.variantAttributeValues.filter((attr) => !attr._delete);
    if (option.variantOption === '') {
      setValidateOptionError('Option name is required.');
      return setValidateOption(true);
    }
    if (attributes.length === 1 && attributes[0].attributeName === '') {
      setValidateAttributeError("Option value is required.");
      return setValidateAttribute(true);
    }
    setEditState(false);
  }

  const handleEditBtnClicked = () => {
    setEditState(true);
    let attributes = [...variantAttributes];
    let option = { ...variantOption };
    attributes.push({ _id: variantAttributes.length + 1, attributeName: '' });
    option.variantAttributeValues = attributes;
    setVariantAttributes(attributes);
    setVariantOption(option);
  }

  const renderEditVariantOption = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <FormGroup>
            <TextField
              type="text"
              label="Name"
              name="variant_option"
              variant="outlined"
              required={true}
              disabled={variantOption.variantOption === global.constants.labels.bundle || variantOption.variantOption === global.constants.labels.bundle.toLowerCase()}
              value={variantOption.variantOption}
              onChange={onVariantOptionChanged}
              helperText={validateOptionError}
              error={validateOption}
            /><br/>
            <Typography sx={{paddingBottom: 2}}  variant="h6">Option Values</Typography>
            {variantAttributes?.map((attribute, index) => { 
              return(
                <Grid container spacing={2} key={index} className={(attribute._delete ? 'hidden' : '')} >
                  <Grid item xs={10} sx={{paddingBottom: 2}}>
                    <FormGroup>
                      <TextField
                        id={`${index}`}
                        type="text"
                        label="Option Value"
                        name="variant_attribute"
                        variant="outlined"
                        value={attribute.attributeName}
                        onChange={onAttributeValueChanged}
                        helperText={validateAttributeError}
                        error={validateAttribute}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton color="error" size="medium" onClick={() => { if (window.confirm('Ar du säker?')) handleOnAttributeDelete(index) } }>
                      <DeleteIcon/>
                    </IconButton>       
                  </Grid>
                </Grid>
              )})}
          </FormGroup><br/>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Button variant='outlined' onClick={() => handleOnDoneBtnClicked() } >{global.constants.labels.done}</Button>
            </Grid>
            <Grid item xs={2}>
              <Button variant='outlined' color='error' onClick={() => handleOnDiscardBtnClicked() } >{global.constants.labels.discard}</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="error" size="medium" onClick={() => { if (window.confirm('Ar du säker?')) handleOnOptionDelete() } }>
            <DeleteIcon/>
          </IconButton>       
        </Grid>
        <Grid item xs={1}>
          <IconButton {...props.attributes} {...props.listeners} color="primary" size="medium">
            <DragIndicatorIcon/>
          </IconButton>
        </Grid>
      </Grid>);
  }

  const renderVariantOption = () => {
    return(
      <Grid container spacing={2} className={(variantOption._delete ? 'hidden' : '')}>
        <Grid item xs={11}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography sx={{ paddingBottom: 1 }} variant="inherit"><strong>{variantOption.variantOption}</strong></Typography>
              {(variantOption.variantOption === global.constants.labels.bundle || variantOption.variantOption === global.constants.labels.bundle.toLowerCase()) && <Grid container><Grid item xs={12}><FormHelperText>{global.constants.labels.bundle_option_headline}</FormHelperText></Grid></Grid>}
              {variantAttributes?.map((attribute, index) => (attribute.attributeName !== "" && !attribute._delete ? <Chip key={index} label={attribute.attributeName} variant="filled" sx={{ marginRight: 1, marginBottom: 1 }} /> : null))}
            </Grid>
            <Grid item xs={2}>
              <Button variant="outlined" size="small" onClick={handleEditBtnClicked}>
                {global.constants.labels.edit}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <IconButton {...props.attributes} {...props.listeners} color="primary" size="medium">
            <DragIndicatorIcon/>
          </IconButton>
        </Grid>
      </Grid>
    );
  }

  return (
    <FormGroup style={{ border: '1px solid #f1f1f1', borderRadius: '5px', padding: '20px', marginBottom: 20, boxShadow: '0px 3px 5px 0px #f1f1f1'}} className={(variantOption._delete ? 'hidden' : '')} >
      {editState ? renderEditVariantOption() : renderVariantOption()}
    </FormGroup>
  )
}

VariantOption.proptypes = {
  object: PropTypes.object.required,
  onUpdate: PropTypes.func.required
}

export default VariantOption
