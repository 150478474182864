import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import * as orderactions from '../../actions/orderActions'
import EditOrder from '../../components/Order/editOrder'
import { OrderApi } from '../../api/orderApi'
import { Grid, Alert, Stack } from '@mui/material'
import AddDiscount from '../../components/Order/addDiscount'
import * as notificationActions from '../../actions/notificationAction'
import countryList from 'react-select-country-list'

const EditOrderContainer = props => {
  const [couponCode, setCouponCode] = useState('')
  const [addDiscountErrors, setAddDiscountErrors] = useState({})
  const [addingDiscount, setAddingDiscount] = useState(false)
  const [freeDelivery, setFreeDelivery] = useState(false)
  const [orderDiscount, setOrderDiscount] = useState(props.order.order_discount || { custom: true, discount_type: 'fixed_amount' })
  const [productFreight, setProductFreight] = useState(0)
  const [order, setOrder] = useState(props.order)
  const [formErrors, setFormErrors] = useState({})
  const [subtotal, setSubtotal] = useState(0)
  const [saving, setSaving] = useState(false)
  const [showFormErrors, setShowFormErrors] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const storeCurrency =  { name: 'Swedish Krone', symbol: 'kr', isoCode: 'sek' } //attach store to order
  const countryOptions = useMemo(() => countryList().getData(), [])

  const fetchOrder = async (orderId) => {
    const response = await OrderApi.getOrder(orderId)
    if(response){
      setOrderValues(response.data)
      let _order = response.data.order
      if(_order.order_discount) {
        setOrderDiscount({..._order.order_discount})
        if(!_order.order_discount.custom) {
          setCouponCode(_order.order_discount.coupon?.code)
        }
      }
    }
  }

  useEffect(() => {
    if( props.order.id > 0 ) {
      fetchOrder(props.order.id)
    }
  }, [])

  // methods related to order object

  const setOrderValues = (data) => {
    setOrder(data.order)
    setSubtotal(data.subtotal)
    setProductFreight(data.productFreight)
    setFreeDelivery(data.freeDelivery)
  }

  const handleErrorAlertClose = (key) => {
    let _formErrors = {...formErrors}
    delete _formErrors[key]
    setFormErrors(_formErrors)
  }

  const formIsValid = () => {
    let _formErrors = formErrors
    let formValid = true
    if (order.email === '') {
      _formErrors.email = 'Email can\'t be blank'
      formValid = false
    }
    if (order.discount < 0 || order.discount > (Math.round(subtotal*100)/100 + parseInt(order.freight))) {
      _formErrors.discount = 'Invalid discount value'
      formValid = false
    }
    if(order.freight < 0) {
      _formErrors.freight = 'Invalid freight value'
      formValid = false
    }
    setFormErrors({..._formErrors})
    return formValid
  }

  const saveOrder = async event => {
    event.preventDefault()
    setFormErrors({})
    if (formIsValid()) {
      try {
        setSaving(true)
        let response
        if(order.id > 0) {
          response = await OrderApi.updateOrder(order.id, order)
          props.notifyActions.setSuccessNotification({ message: global.constants.response_messages.order_updated_successfully })
        }
        else{
          response = await OrderApi.createOrder(order)
          props.notifyActions.setSuccessNotification({ message: global.constants.response_messages.order_created_successfully })
        }
        setSaving(false)
        props.history.push(`/admin/orders/${response.data.id}`)
      }
      catch(error) {
        setSaving(false)
        props.notifyActions.setErrorNotification({ message: error.response.data })
      }
    }
    else {
      setShowFormErrors(true)
    }
  }

  const updateOrderState = event => {
    const field = event.target.name
    let _formErrors = formErrors
    let _order = order
    if (_formErrors.hasOwnProperty(field)) delete(_formErrors[field])
    switch (event.target.type) {
      case 'checkbox':
        if(_order[field] === false) {
          _order[field] = true
        } else if(_order[field] === true) {
          _order[field] = false
        } else {
          _order[field] = false
        }
        break
      case 'file':
        _order[field] = event.target.files[0]
        break
      default:
        _order[field] = event.target.value
    }
    setOrder({..._order})
    setFormErrors({..._formErrors})
    setIsEditing(true)
  }

  const updateShippingCountry = async (event) => {
    try {
      const response = await OrderApi.updateShippingCountry({ order: order, shipping_country: event.target.value })
      setOrderValues(response.data)
      setIsEditing(true)
    }
    catch(error) {
      props.notifyActions.setErrorNotification({ message: error.response.data })
    }
  }

  const handleItemUpdate = async (obj) => {
    if(!obj.quantity && !obj._deleted) return
    const items = order.order_items
    const itemIndex = items.findIndex(i => i.id === obj.id)
    if (itemIndex > -1) {
      try{
        let response
        if(obj.quantity) {
          response = await OrderApi.updateOrderItem({ order: order, item: {...items[itemIndex], ...obj} })
        }
        else { // deletion case
          response = await OrderApi.removeOrderItem({ order: order, itemIndex: itemIndex })
        }
        setOrderValues(response.data)
        setIsEditing(true)
      }
      catch(error) {
        props.notifyActions.setErrorNotification({ message: error.response.data })
      }
    }
  }

  // methods related to order_discount

  const isDiscountValueValid = () => {
    let discountMax = (orderDiscount.discount_type === 'fixed_amount' ? order.subtotal : 100)
    return (!parseFloat(orderDiscount.discount_value)
      || parseFloat(orderDiscount.discount_value) <= 0
      || parseFloat(orderDiscount.discount_value) > discountMax)
  }

  const customDiscountValidations = (_addDiscountErrors) => {
    if(!orderDiscount.discount_type) {
      _addDiscountErrors.discount_type = 'Discount type can\'t be blank'
    }
    if(orderDiscount.discount_type === 'free_delivery') return

    if(isDiscountValueValid(orderDiscount.discount_value)) {
      _addDiscountErrors.discount_value = 'Invalid discount value'
    }
  }

  const addDiscountformIsValid = () => {
    let _addDiscountErrors = {}
    if(orderDiscount.custom) {
      customDiscountValidations(_addDiscountErrors)
    }
    else if(couponCode === '') {
      _addDiscountErrors.coupon_code = 'Invalid discount code'
    }
    setAddDiscountErrors({..._addDiscountErrors})
    return Object.keys(_addDiscountErrors).length === 0
  }

  const handleApplyDiscount = async () => {
    if(!addDiscountformIsValid()) {
      return
    }
    try {
      const response = await OrderApi.updateOrderDiscount({ order: {...order, order_discount: {...orderDiscount} }, coupon_code: (orderDiscount.custom ? null : couponCode) })
      setOrderValues(response.data)
    }
    catch(error) {
      props.notifyActions.setErrorNotification({ message: error.response.data })
    }
    setAddingDiscount(false)
  }

  const updateOrderDiscount = event => {
    const field = event.target.name
    let _addDiscountErrors = addDiscountErrors
    let _orderDiscount = orderDiscount
    if (_addDiscountErrors.hasOwnProperty(field)) delete(_addDiscountErrors[field])
    switch (event.target.type) {
      case 'checkbox': // only custom
        if(_orderDiscount[field] === false) {
          _orderDiscount.discount_id = null
          _orderDiscount[field] = true
        } else if(_orderDiscount[field] === true) {
          _orderDiscount.discount_id = null
          _orderDiscount.coupon_id = null
          _orderDiscount.discount_reason = ''
          _orderDiscount[field] = false
        } else {
          _orderDiscount.discount_id = null
          _orderDiscount.coupon_id = null
          _orderDiscount.discount_reason = ''
          _orderDiscount[field] = false
        }
        break
      default:
        if(field === 'coupon_code') {
          setCouponCode(event.target.value)
          break
        }
        _orderDiscount[field] = event.target.value
    }
    setIsEditing(true)
    setAddDiscountErrors(_addDiscountErrors)
    setOrderDiscount({..._orderDiscount})
  }

  const handleRemoveDiscount = async () => {
    try {
      const response = await OrderApi.removeOrderDiscount({ order: order })
      setOrderValues(response.data)
      setCouponCode('')
      setOrderDiscount({ custom: true, discount_type: 'fixed_amount', value: 0, discount_reason: '' })
      setAddingDiscount(false)
      setIsEditing(true)
    }
    catch(error) {
      props.notifyActions.setErrorNotification({ message: error.response.data })
    }
  }

  return (
    <Grid container>
      {showFormErrors && (
        <Grid container item xs={12}>
          <Stack sx={{ width: '100%' }} spacing={1}>
            {Object.keys(formErrors).map((key, index) => (
              <Alert key={index} severity="error" sx={{width: '100%'}} onClose={() => (handleErrorAlertClose(key))}>
                {formErrors[key]}
              </Alert>
            ))}
          </Stack>
        </Grid>
      )}
      <EditOrder
        order={order}
        productFreight={productFreight}
        freeDelivery={freeDelivery}
        countryOptions={countryOptions}
        validationErrors={formErrors}
        onSave={saveOrder}
        updateShippingCountry={updateShippingCountry}
        onChange={updateOrderState}
        onItemUpdate={handleItemUpdate}
        handleAddDiscount={() => setAddingDiscount(true)}
        saving={saving}
        editing={isEditing}
        subtotal={subtotal}
        storeCurrency={storeCurrency}
        onContentChange={content => setOrder({...order, content: content})} />
      <AddDiscount
        orderDiscount={orderDiscount}
        couponCode={couponCode}
        edit={order.order_discount !== null}
        netTotal={parseFloat(order.net_total)}
        formErrors={addDiscountErrors}
        addingDiscount={addingDiscount}
        updateOrderDiscount={updateOrderDiscount}
        handleApplyDiscount={handleApplyDiscount}
        handleRemoveDiscount={handleRemoveDiscount}
        onClose={() => setAddingDiscount(false)} />
    </Grid>
  )
}

EditOrderContainer.propTypes = {
  order: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  notifyActions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

const emptyOrder = () => {
  return {
    id: 0,
    email: '',
    phone: '',
    order_items: [],
    freight: 0,
    net_total: 0,
    discount: 0,
    vat: 0,
    order_discount: null,
  }
}

const mapStateToProps = (state, ownProps) => {
  let order = emptyOrder()
  const orderId = Number(ownProps.match.params.id)
  if (orderId > 0) {
    order.id = orderId
    if (state.orders.data.length > 0) {
      order = { ...order, ...state.orders.data.find(order => order.id === orderId) }
    }
  }
  return {
    order: order
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(orderactions, dispatch),
    notifyActions: bindActionCreators(notificationActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditOrderContainer)
