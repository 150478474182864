import React, { useState, useEffect } from 'react'
import { Grid, Autocomplete, TextField } from '@mui/material'
import { useParams } from 'react-router-dom'
import ListingPage from '../../components/Shared/ListingPage'
import { ListTable } from '../../components/Shared/ListingTable'
import OrderFulfillmentCenter from '../../components/OrderFulfillmentCenter/orderFulfillmentCenter'
import ListTableActions from '../../components/Shared/ListingTable/listTableActions'
import { StoreApi } from '../../api/storeApi'
import { FulfillmentCenterAPI } from '../../api/fulfillmentCenterApi'
import { OrderFulfillmentCenterApi } from '../../api/orderFulfillmentCenterApi'
import useListingActions from '../../hooks/useListingActions'
import { fulfillmentOrderSorting } from '../../config/sortingLabels'

const styles = {
  actionBtn: {
    textTransform: 'none',
    marginTop: '2%',
    float: 'right'
  },
  autocomplete: {
    width: 200,
  }
}

const OrderFulfillmentCenterContainer = () => {
  const { id: fulfillmentCenterId } = useParams();
  const [stores, setStores] = useState([])
  const [fulfillmentCenters, setFulfillmentCenters] = useState([])
  const [selectedStore, setSelectedStore] = useState(null)
  const [selectedFulfillmentCenter, setSelectedFulfillmentCenter] = useState(null)


  const handleExtraParameters = () => {
    const params = {}
    if (selectedStore) {
      params.store_id = selectedStore.id
    }
    if (selectedFulfillmentCenter) {
      params.fulfillment_center_id = selectedFulfillmentCenter.id
    }
    return params
  }

  const {
    data,
    count,
    page,
    perPage,
    searchTerm,
    sortOptionSelected,
    handlerForSearchingAndSortingChange,
    fetchData,
  } = useListingActions('orders', OrderFulfillmentCenterApi.getOrders, 'latest', handleExtraParameters);

  const fetchAllStores = async () => {
    const response = await StoreApi.getStores({ page: -1 })
    setStores(
      response.data.data.map((store) => {
        return { id: store.id, name: store.name }
      })
    )
  }

  const fetchFulfillmentCentersForStore = async (storeId) => {
    const response = await FulfillmentCenterAPI.getFulfillmentCenters({ store_id: storeId });
    setFulfillmentCenters(
      response.data.data.map((center) => {
        return { id: center.id, name: center.name }
      })
    );
  }

  const fetchAllFulfillmentCenters = async () => {
    const response = await FulfillmentCenterAPI.getFulfillmentCenters({ page: -1 });
    setFulfillmentCenters(
      response.data.data.map((center) => {
        return { id: center.id, name: center.name }
      })
    );

    if (fulfillmentCenterId) {
      const selectedCenter = response.data.data.find(center => center.id === parseInt(fulfillmentCenterId));
      setSelectedFulfillmentCenter(selectedCenter);
      fetchData({
        page: 1,
        per_page: perPage,
        q: searchTerm,
        sort_by: sortOptionSelected,
        fulfillment_center_id: selectedCenter?.id,
      }, true);
    }
  }

  useEffect(() => {
    fetchAllStores();
    fetchAllFulfillmentCenters();
  }, [fulfillmentCenterId]);

  const handleStoreChange = async (e, value) => {
    setSelectedStore(value)
    setSelectedFulfillmentCenter(null)

    if (value) {
      await fetchFulfillmentCentersForStore(value.id)
    } else {
      fetchAllFulfillmentCenters();
    }

    fetchData({
      page: 1,
      per_page: perPage,
      q: searchTerm,
      sort_by: sortOptionSelected,
      ...value && { store_id: value.id }
    }, true)
  }

  const handleFulfillmentCenterChange = (e, value) => {
    setSelectedFulfillmentCenter(value)
    fetchData({
      page: 1,
      per_page: perPage,
      q: searchTerm,
      sort_by: sortOptionSelected,
      ...selectedStore && { store_id: selectedStore.id },
      ...value && { fulfillment_center_id: value.id }
    }, true)
  }

  const tableActions = (
    <ListTableActions
      searchText='Search orders by order no'
      searchTerm={searchTerm}
      sortOptionSelected={sortOptionSelected}
      getData={handlerForSearchingAndSortingChange}
      sortOptions={fulfillmentOrderSorting}
    />
  )

  const storeCurrency = { name: 'Swedish Krone', symbol: 'kr', isoCode: 'sek' }
  const tableHeading = ['Order', 'Fulfillment Center', 'Store', 'Date','Customer', 'Total', 'Payment', 'Status', ''];
  const tableContent = data?.map((order, index) => (
    <OrderFulfillmentCenter key={order.id} {...order} storeCurrency={storeCurrency} />
  ));
  const actionButtons = (
    <Grid container justifyContent="flex-end" spacing={1}>
      <Grid item>
        <Autocomplete
          disablePortal
          id='stores'
          size='small'
          options={stores}
          getOptionLabel={(store) => store.name}
          onChange={handleStoreChange}
          sx={styles.autocomplete}
          renderInput={(params) => (<TextField {...params} label='Search Store' />)}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          disablePortal
          id='fulfillmentCenters'
          size='small'
          options={fulfillmentCenters}
          getOptionLabel={(center) => center.name}
          value={selectedFulfillmentCenter}
          onChange={handleFulfillmentCenterChange}
          sx={styles.autocomplete}
          renderInput={(params) => (<TextField {...params} label='Search Fulfillment Center' />)}
        />
      </Grid>
    </Grid>
  )

  return (
    <ListingPage
      title="Fulfillment Orders"
      actions={actionButtons} 
      table={(
        <ListTable
          tableHeadings={tableHeading}
          tableContent={tableContent}
          tableActions={tableActions}
          count={count}
          page_no={page}
          rowsControl={true}
          onPageChange={(event, pageNo) => handlerForSearchingAndSortingChange('page', pageNo + 1)}
          handleChangeRowsPerPage={(event) => handlerForSearchingAndSortingChange('pageSize', event.target.value)}
          rowsPerPage={perPage || global.constants.perPage.orders} />
      )} />
  )
}

export default OrderFulfillmentCenterContainer;
