import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Divider } from '@mui/material';
import { Link } from 'react-router-dom'
import ArrowRight from '@mui/icons-material/ArrowRight'
import StatusChip from '../Helpers/StatusChip';

// TODO @ update the table to use reusable components for table layout
const OrdersTableWidget = ({ orders, storeCurrency, dashboard }) => {
  const classes = {
    viewAllBtn: {
      paddingRight: 0,
      textTransform: 'none',
      margin: '2%',
      float: 'right'
    },
  }

  return (
    <TableContainer >
      <Table sx={{ minWidth: 650 }} aria-label="simple table" >
        <TableHead>
          <TableRow style={{backgroundColor: '#F9F9F9'}}>
            <TableCell><h5>#</h5></TableCell>
            <TableCell align="left"><h5>Name</h5></TableCell>
            {!dashboard && (
              <>
                <TableCell align="left"><h5>City</h5></TableCell>
                <TableCell align="left"><h5>Country</h5></TableCell>
              </>
            )}
            <TableCell align="left"><h5>Total</h5></TableCell>
            <TableCell align="left"><h5>Payment</h5></TableCell>
            <TableCell align="left"><h5>Status</h5></TableCell>
            <TableCell align="left"><h5>Date</h5></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.length > 0 && orders.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
              <TableCell component="th" scope="row">
                <Link to={`/admin/orders/${row.id}`}>{row.id}</Link>
              </TableCell>
              <TableCell  align="left">
                <Link to={`/admin/orders/${row.id}`}>{row.shipping_name}</Link>
              </TableCell>{!dashboard && (
                <>
                  <TableCell align="left">{row.shipping_city}</TableCell>
                  <TableCell align="left">{row.shipping_country}</TableCell>
                </>
              )}
              <TableCell align="left">{`${row.net_total ? row.net_total : ''} ${storeCurrency.symbol}`}</TableCell>
              <TableCell align="left"><StatusChip status={row.payment_status} /></TableCell>
              <TableCell align="left"><StatusChip status={row.status} /></TableCell>
              <TableCell align="left">{(new Date(row.createdAt)).toLocaleDateString('sv')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Divider />
      {dashboard &&
        <Button size="large" sx={classes.viewAllBtn} variant="text" href="/admin/orders">View all<ArrowRight/></Button>
      }
    </TableContainer>
  )
}

OrdersTableWidget.propTypes = {
  orders: PropTypes.array.isRequired,
  storeCurrency: PropTypes.object.isRequired,
  dashboard: PropTypes.bool.isRequired
}

export default OrdersTableWidget
