import {HttpClient} from './httpClient' 
import {MEDTRYCK_API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'

//Setting the products URI
const MT_PRODUCTS_API = `${MEDTRYCK_API_URL}/products`

// Search collection
const searchProducts = (query) => {
  return HttpClient.get(
    `${MT_PRODUCTS_API}?q=${query}`,
    apiHeaders()
  )
}

// Get
const getProduct = id => {
  return HttpClient.get(
    `${MT_PRODUCTS_API}/${id}`,
    apiHeaders()
  )
}

//Encapsulating in a JSON object
const SearchApi = {searchProducts, getProduct}

export {SearchApi}