import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Divider, TextField, Backdrop, Modal, Box, MenuItem, FormControlLabel, Checkbox } from '@mui/material'
import { Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const styles = {
  discountModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: { xs: '95%', sm: '70%', md: '60%', lg: '45%' },
    boxShadow: 24
  },
  paddingLeft: { paddingLeft: '2%' },
  padding: { padding: '2%' }
}

const AddDiscount = props => (
  <Modal
    open={props.addingDiscount}
    onClose={props.onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }} >
    <Box sx={styles.discountModal}>
      <Grid container alignItems="center">
        <Grid item xs={11} sx={styles.paddingLeft}>
          <h3>Add Discount</h3>
        </Grid>
        <Grid item xs={1} textAlign="right">
          <IconButton onClick={props.onClose} ><CloseIcon /></IconButton>
        </Grid>
        <Divider width="100%" />
        <Grid container sx={styles.padding} spacing={2} alignItems="center">
          {props.orderDiscount.custom ? (
            <>
              <Grid item xs={props.orderDiscount.discount_type === 'free_delivery' ? 12 : 6}>
                <TextField
                  id="discountType"
                  name="discount_type"
                  label="Discount Type"
                  value={props.orderDiscount.discount_type || 'fixed_amount'}
                  onChange={props.updateOrderDiscount}
                  error={props.formErrors.hasOwnProperty('discount_type')}
                  helperText={props.formErrors.discount_type}
                  select
                  fullWidth >
                  <MenuItem value='percentage'>{global.constants.labels.percentage}</MenuItem>
                  <MenuItem value='fixed_amount'>{global.constants.labels.fixed_amount}</MenuItem>
                  <MenuItem value='free_delivery'>{global.constants.labels.free_delivery}</MenuItem>
                </TextField>
              </Grid>
              {props.orderDiscount.discount_type !== 'free_delivery' && (
                <Grid item xs={6}>
                  <TextField
                    id="discountValue"
                    name="discount_value"
                    label="Discount Value"
                    value={props.orderDiscount.discount_value || ''}
                    type="number"
                    step={0.5}
                    InputProps={{
                      inputProps: { min: 0, max: (props.orderDiscount.discount_type === 'fixed_amount' ? props.netTotal : 100) }
                    }}
                    onChange={props.updateOrderDiscount}
                    error={props.formErrors.hasOwnProperty('discount_value')}
                    helperText={props.formErrors.discount_value}
                    fullWidth />
                </Grid>
              )}
            </>
          ) : (
            <Grid item xs={12}>
              <TextField
                name="coupon_code"
                label="Discount code"
                value={props.couponCode || ''}
                onChange={props.updateOrderDiscount}
                error={props.formErrors.hasOwnProperty('coupon_code')}
                helperText={props.formErrors.coupon_code}
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              id="discountReason"
              name="discount_reason"
              label="Discount Reason"
              value={props.orderDiscount.discount_reason || ''}
              onChange={props.updateOrderDiscount}
              fullWidth />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={!props.orderDiscount.custom} onChange={props.updateOrderDiscount} name="custom" />}
              label="Use coupon code?" />
          </Grid>
        </Grid>
        <Divider width="100%" />
      
        <Grid container item xs={12} spacing={1} sx={{padding: '2%'}}>
          <Grid item xs={6}>
            {props.edit && (<Button color="error" variant='contained' onClick={props.handleRemoveDiscount}>Remove Discount</Button>)}
          </Grid>
          <Grid container item xs={6} spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button variant='outlined' onClick={props.onClose}>Close</Button>
            </Grid>
            <Grid item>
              <Button color="primary" variant='contained' onClick={props.handleApplyDiscount}>Apply</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </Modal>
)

AddDiscount.propTypes = {
  addingDiscount: PropTypes.bool.isRequired,
  updateOrderDiscount: PropTypes.func.isRequired,
  handleApplyDiscount: PropTypes.func.isRequired,
  handleRemoveDiscount: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  orderDiscount: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  netTotal: PropTypes.number.isRequired,
  couponCode: PropTypes.string
}

export default AddDiscount
