/* eslint-disable semi */
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types'
import { FormControlLabel, FormHelperText, Checkbox, FormControl, InputLabel, MenuItem, Select, FormGroup, Grid, Button, Typography, Divider, TextField } from '@mui/material'
import { cloneDeep, uniqBy } from 'lodash';
import InfoIcon from '@mui/icons-material/Info';
import Variant from './variant'
import VariantOption from './variantOption';

import SortingContext from '../../Helpers/sortingContext';
import { CustomModal } from '../../Shared/CustomModal';
import { processVariantOptionsAndAttributes, processVariantsCombinations, equateCollections } from '../../../utils/variantHelper/variantsHelper';
import AuthStorage from '../../../services/authStorage';
// import '../Product.css'
import { setSuccessNotification } from '../../../actions/notificationAction';
import FormFlashMessage from '../../../components/Helpers/formFlashMessage';

const Auth = new AuthStorage();

const VariantsContainer = props => {
  const { hasVariants, setHasVariants, isBundle, setIsBundle } = props;

  // States
  const [variants, setVariants] = useState([]);
  const [variantOptions, setVariantOptions] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [formModalErrors, setFormModalErrors] = useState({});

  // for keeping data consistent with refresh page
  let renderCount = useRef(0);

  // Form States
  const [formOptions,setFormOptions] = useState([]);
  const [formPrice, setFormPrice] = useState(0.0);
  const [formArticle, setFormArticle] = useState('');
  const [formManageStock, setFormManageStock] = useState(false);
  const [formQuantity, setFormQuantity] = useState(0);
  const [formSku, setFormSku] = useState('');
  const [formSkuMultiple, setFormSkuMultiple] = useState(1);
  const [formAllowBackOrders, setFormAllowBackOrders] = useState(false);
  const [isVariantOptionsEditing, setIsVariantOptionsEditing] = useState(false)

  const dispatch = useDispatch();

  useEffect(() => {
    let options = [...variantOptions];
    let option = options.find((opt) => (opt.variantOption === global.constants.labels.bundle || opt.variantOption === global.constants.labels.bundle.toLowerCase()));
    if (isBundle && !option) {
      let existedVariants = [...variants];
      option = { variantOption: global.constants.labels.bundle, variantAttributeValues: [{ _id: 0, attributeName: '5' }] };
      existedVariants.forEach((v) => v.name === '5' ? v._delete = false : v._delete);
      setVariants(existedVariants);
      handleAddNewVariantOption(option);
      renderCount.current += 1;
    } else if (!isBundle && option) {
      options = options.filter((opt) => (opt.variantOption !== global.constants.labels.bundle && opt.variantOption !== global.constants.labels.bundle.toLowerCase()));
      setVariantOptions(options);
    }
  }, [isBundle])

  useEffect(() => {
    let didCancel = false // to cancel side effects
    if(!didCancel) {
      if (variantOptions.length === 0 && props.variants.length > 0) {
        let options = processVariantOptionsAndAttributes(props.variants);
        setVariantOptions(options.sort((c,b) => c.position > b.position ? 1 : -1));
      }
      setVariants([...props.variants])
    }
    return () => { didCancel = true }
  }, [props.variants.length])

  useEffect(() => {
    // api data takes 2 re-renders, so keep it unprocessed till we have data.
    if (renderCount.current > 1) {
      let existedVariants = [...variants];
      let _variants = uniqBy(processVariantsCombinations(variants, variantOptions, handleAddNewVariant), 'name');
      let variantsIds = _variants.map((v) => v.id?? v._id);
      // Delete variants those are not required anymore
      existedVariants = existedVariants.filter((_eVariants) => !variantsIds.includes(_eVariants.id ?? _eVariants._id) ? _eVariants._delete = true : _eVariants._delete = false);
      _variants = _variants.concat(existedVariants);
      setVariants(_variants);
      // when all variants are in state deleted and no new variants existed
      if (!variantOptions.find((opt) => opt.variantOption === '') && !_variants.find((v) => !v._delete)) setHasVariants(false);
      props.sortArray(_variants);
    } else {
      renderCount.current += 1;
    }
  }, [variantOptions]);

  useEffect(() => {
    // Reset values
    if (!modalState) {
      setFormOptions([]);
      setFormPrice(0.0);
      setFormArticle('');
      setFormManageStock(false);
      setFormQuantity(0);
      setFormSku('');
      setFormAllowBackOrders(false);
    }
  }, [modalState]);

  const handleAddNewVariant = (option={}) => {
    let _variant = props.newVariant(option);
    let _variants = [...variants];
    _variants.push(_variant);
    setVariants(_variants);
    return _variant
  }

  const handleVariantUpdate = variant => {
    let _variants = [...variants]
    if (variant._delete) {
      _variants = _variants.filter((v) => v.id !== (variant?.id || variant?._id));
    } else {
      const indexOfVarToUpdate = _variants.findIndex(({ id, _id }) => id ? id === variant.id : _id === variant._id )    
      _variants[indexOfVarToUpdate] = variant
    }
    setVariants(_variants)
    if (_variants.length === 0) setHasVariants(false);
    props.onUpdate(variant)
  }

  const handleAddNewVariantOption = (option={}) => {
    setHasVariants(true);
    let variantOption = { id: (variantOptions.length + 1), _id: (variantOptions.length + 1), variantOption: (option['variantOption']?? ''), variantAttributeValues: (option['variantAttributeValues']?? [{ _id: 0, attributeName: '' }]) };
    variantOption.position = Math.max(...variantOptions.map(v => v.position), 0) + 1
    let updatedVariantOptions = cloneDeep(variantOptions);
    updatedVariantOptions.push(variantOption);
    setVariantOptions(updatedVariantOptions);
  }

  const handleVariantOptionUpdate = (variantOption) => {
    setIsVariantOptionsEditing(true)
    let options = [...variantOptions];
    if (variantOption._delete) {
      options = options.filter((option) => option.id !== (variantOption?.id || variantOption?._id));
      // uncheck when bundle option is deleted
      if (variantOption.variantOption === global.constants.labels.bundle || variantOption.variantOption === global.constants.labels.bundle.toLowerCase()) setIsBundle(false);
    } else {
      const index = options.findIndex(({ id, _id }) => id ? id === variantOption.id : _id === variantOption._id );
      options[index] = variantOption;
      // check when bundle option is created
      if (variantOption.variantOption === global.constants.labels.bundle || variantOption.variantOption === global.constants.labels.bundle.toLowerCase()) setIsBundle(true);
    }
    setVariantOptions(options);
  }

  const handleSortVariantOptions = (options) => {
    setVariantOptions(options)
  }

  const handleModalState = (state) => {
    setModalState(state)
    setFormModalErrors({});
  }

  const handleOnIsBundleChange = (event) => {
    if (event.target.checked !== isBundle) setHasVariants(true);
    props.onProductChange(event);
  }

  const handleSetFormOptions = (event, index) => {
    let _formOptions = [...formOptions];
    let option = variantOptions.find(opt => opt?.variantOption === event.target.name);
    _formOptions[index] = { variant_attribute: event.target.value, option_name: option.variantOption, position: option.position };
    setFormOptions(_formOptions);
  }

  const handleVariantFormModelSubmitted = (event) => {
    let storeId = Auth.getStore().id;
    let productDetails = [];
    let variantName = '';
    formOptions.forEach((attr, index) => {
      let productDetail = {};
      productDetail.store_id = storeId;
      variantName += (index === formOptions.length - 1 ? attr.variant_attribute : `${attr.variant_attribute}/`);
      productDetail.variant_attribute = { name: attr.variant_attribute, store_id: storeId, variant_option: { option: attr.option_name, position: attr.position, store_id: storeId } };
      productDetails.push(productDetail);
    });
    let existingVariant = false;
    let splittedVariantName = variantName.split('/').filter(n => n);
    for(let v in variants) {
      let splittedPersistedVariant = variants[v].name.split('/').filter(n => n);
      if (equateCollections(splittedVariantName, splittedPersistedVariant) || equateCollections(splittedPersistedVariant, splittedVariantName)) {
        existingVariant = true;
        break;
      }
    };
    if (!existingVariant) {
      let buildVariantObject = { name: variantName, product_details: productDetails };
      handleAddNewVariant(buildVariantObject);
      dispatch(setSuccessNotification({ message: global.constants.response_messages.variants.added }));
      setModalState(false);
      setFormModalErrors({});
    } else {
      setFormModalErrors({ error: global.constants.response_messages.variants.already_exists });
    }
  }

  const calculateVariantsCombinationsCount = () => {
    let count = 1;
    variantOptions.forEach((option) => count*=option.variantAttributeValues.length);
    return count;
  }

  const renderVariantModalBody = () => {
    { return (hasVariants ? <FormGroup style={{ padding: '20px', boxShadow: '0px 3px 5px 0px #f1f1f1', marginTop: '10px'}} >
      <FormFlashMessage formErrors={formModalErrors} />
      {variantOptions?.map((option, index) => {
        let renderingElement;
        {renderingElement = option.variantOption ?
          <FormControl key={index}>
            <InputLabel id="variantOptionId">{option.variantOption}</InputLabel>
            <Select
              labelId="variantOptionId"
              name={option.variantOption}
              value={formOptions[index]?.variant_attribute ?? ''}
              label={option.variantOption}
              onChange={(event) => handleSetFormOptions(event, index)}
              variant="outlined"
              sx={{ marginBottom: 4 }}
            >
              {option?.variantAttributeValues?.map((attribute, index) => (
                <MenuItem key={index} value={attribute.attributeName}>{attribute.attributeName}</MenuItem>
              ))}
            </Select>
          </FormControl> : <></>
        }
        return renderingElement;
      })}
      {variantOptions.length > 0 ? <><TextField
        type="number"
        label="Price"
        name="price"
        fullWidth
        variant="outlined"
        value={formPrice}
        inputProps={{ min: 0 }}
        sx={{ marginBottom: 4 }}
        onWheel={(e) => e.target.blur()}
        onChange={(event) => setFormPrice(event.target.value)}
      />
      <TextField
        type="text"
        label="Article"
        name="article"
        fullWidth
        variant="outlined"
        value={formArticle}
        sx={{ marginBottom: 4 }}
        onChange={(event) => setFormArticle(event.target.value)}
      />
      <FormControlLabel control={<Checkbox name="manageStock" color="primary" checked={!!formManageStock} onChange={() => setFormManageStock(!formManageStock)} />} label="Track Inventory" sx={{ marginBottom: 4 }} />
      { !formManageStock ? <></> : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                id="variantQuantity"
                type="number"
                min={0}
                label="Current Stock"
                name="quantity"
                variant="outlined"
                value={formQuantity?? 0}
                onChange={(event) => setFormQuantity(event.target.value)}/>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel control={<Checkbox name="allowBackOrders" color="primary" checked={!!formAllowBackOrders} onChange={() => setFormAllowBackOrders(!formAllowBackOrders)} />} label={global.constants.labels.allow_back_orders_label} sx={{ marginBottom: 4 }} />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ paddingTop: 2, paddingBottom: 4 }}>
            <Grid item xs={3}>
              <TextField
                type="text"
                label="SKU"
                name="sku"
                variant="outlined"
                value={formSku}
                onChange={(event) => setFormSku(event.target.value)}/>
            </Grid>
            <Grid item xs={3}>
              {isBundle && <TextField
                type="number"
                label="SKU Multiple"
                name="sku_multiple"
                variant="outlined"
                value={formSkuMultiple}
                onWheel={(e) => e.target.blur()}
                onChange={(event) => setFormSkuMultiple(event.target.value)}/>}
            </Grid>
            <Grid item xs={6}>
              {isBundle && <Grid container spacing={2} sx={{padding: 1}}><Grid item xs={1}><InfoIcon/></Grid><Grid item xs={11}><FormHelperText>{global.constants.labels.sku_multiple_help}</FormHelperText></Grid></Grid>}
            </Grid>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleVariantFormModelSubmitted}>{global.constants.labels.submit}</Button>
      </Grid></> : global.constants.response_messages.no_variant_options_label}
    </FormGroup> : <Typography sx={{padding: 2}}>{global.constants.labels.no_variant_options_available}</Typography>) }
  }

  return (
    <>
      <Grid container sx={{ paddingBottom: 4 }}>
        <Grid item xs={10}>
          <Typography gutterBottom variant="h5" component="h2">
            {global.constants.labels.options}
          </Typography>
        </Grid>
        <Grid container item xs={2} justifyContent={'end'}>
          <Button
            color="primary"
            fullWidth
            onClick={handleAddNewVariantOption}
            variant="outlined">
            {global.constants.labels.add}
          </Button>
        </Grid>
        <Divider width={'100%'} sx={{ marginBottom: '10px', marginTop: '10px' }}/>
        <Typography>{global.constants.labels.variant_option_headline}</Typography>
        <Grid container spacing={2} sx={{ paddingTop: 2 }}>
          <Grid container item xs={12} alignItems={'center'}>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel control={<Checkbox name="is_bundle" color="primary" checked={isBundle} onChange={handleOnIsBundleChange} />} label={global.constants.labels.is_bundle_product} />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <FormHelperText>{global.constants.labels.variants_as_bundle}</FormHelperText>
          </Grid>
        </Grid>
      </Grid>
      {hasVariants && <SortingContext data={variantOptions} setData={setVariantOptions} sortArray={handleSortVariantOptions} DataComponent={VariantOption} onUpdate={handleVariantOptionUpdate} />}
      
      {( hasVariants && variantOptions.length > 0) && <><Grid container justifyContent={'space-between'} sx={{ paddingTop: 4, paddingBottom: 4 }}>
        <Grid item xs={10}>
          <Typography gutterBottom variant="h5" component="h2">
            {global.constants.controllers.Variants}
          </Typography>
        </Grid>
        <Grid container item xs={2} justifyContent={'end'}>
          {calculateVariantsCombinationsCount() !== variants.length && <Button
            color="primary"
            fullWidth
            onClick={() => setModalState(true)}
            variant="outlined">
            {global.constants.labels.add}
          </Button>}
        </Grid>
        <Divider width={'100%'} sx={{ marginBottom: '10px', marginTop: '10px' }}/>
        <Typography>{global.constants.labels.variant_headline}</Typography>
      </Grid>
      <CustomModal modalState={modalState} title={`${global.constants.labels.add} ${global.constants.labels.variant}`} handleModalState={handleModalState} modalBody={renderVariantModalBody()} />
      <SortingContext optionalParams={{ images: props.productImages, isBundle: isBundle, isVariantOptionsEditing: isVariantOptionsEditing }} data={variants} setData={setVariants} sortArray={props.sortArray} DataComponent={Variant} onUpdate={handleVariantUpdate} /></>}
    </>
  )
}

VariantsContainer.propTypes = {
  variants: PropTypes.array,
  sortArray: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  newVariant: PropTypes.func
}
export default VariantsContainer
