import React, {useState} from 'react'
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  TextField,
  FormHelperText
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SharedModal from '../Shared/modal'
import {ProductInventoryApi} from '../../api/productInventoryApi'
import {setSuccessNotification} from '../../actions/notificationAction'
import {useDispatch} from 'react-redux'
import PropTypes from 'prop-types'

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: { xs: '95%', sm: '70%', md: '60%', lg: '45%' },
    boxShadow: 24
  },
  container: {
    padding: '2%'
  },
  headerContainer: { paddingLeft: '2%', paddingRight: '1%' }
}

const AddStockModal = ( { variantId, handleVariantQuantityAdded, closeModal, productVariant, store_id } ) => {

  const dispatch = useDispatch()

  const [variantStock, setVariantStock] = useState({ quantity: '', incoming_inventory: false, incoming_inventory_date: '', batch_number: '', batch_name: ''})
  const [variantStockErrors, setVariantStockErrors] = useState({})

  const [sendWaitlistEmail, setSendWaitlistEmail] = useState(false)
  const showNotifyEmail = variantStock.quantity && ((parseFloat(variantStock.quantity) || 0) + (parseFloat(productVariant.quantity) || 0)) > 0

  const handleVariantStockOnChange = (event) => {
    if (event.target.type === 'checkbox'){
      setVariantStock({...variantStock, [event.target.name]: event.target.checked})
    } else{
      let errors = {...variantStockErrors}
      delete errors[event.target.name]
      setVariantStock({...variantStock, [event.target.name]: event.target.value})
      setVariantStockErrors({...errors})
    }
  }

  const isVariantStockFormValid = () => {
    let formValid = true
    let errors = {...variantStockErrors}
    if (variantStock.quantity === ''){
      errors.quantity = global.constants.error_messages.variant_stock.quantity
      formValid = false
    }
    if (variantStock.incoming_inventory && variantStock.incoming_inventory_date === ''){
      errors.incoming_inventory_date = global.constants.error_messages.variant_stock.incoming_inventory_date
      formValid = false
    }
    setVariantStockErrors({...errors})
    return formValid
  }

  const handleAddStock = async () => {
    if (!isVariantStockFormValid()) return;
    setVariantStockErrors({})
    let _variantStock = {...variantStock}
    if (_variantStock.incoming_inventory_date === ''){
      delete _variantStock.incoming_inventory_date
    }
    const data = {..._variantStock, product_variant_id: variantId, send_waitlist_email: sendWaitlistEmail, store_id: store_id}
    const result = await ProductInventoryApi.addProductStock(data)
    if (result.status === 200) {
      handleVariantQuantityAdded(variantStock.quantity, variantStock.incoming_inventory)
      setVariantStock({ quantity: '', incoming_inventory: false, incoming_inventory_date: '', batch_number: '', batch_name: ''})
      closeModal()
      dispatch(setSuccessNotification({message: 'Stock is added successfully'}))
    }
  }

  return (
    <SharedModal onCloseModal={closeModal}>
      <Paper sx={styles.modal}>
        <Grid container>
          <Grid container item alignItems="center" sx={styles.headerContainer}>
            <Grid item xs={11}>
              <h3>Add Stock</h3>
            </Grid>
            <Grid item xs={1}>
              <IconButton sx={{ float: 'right' }} onClick={closeModal}><CloseIcon /></IconButton>
            </Grid>
          </Grid>
          <Divider width='100%' />
          <Grid container sx={styles.container} alignItems='center' spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Quantity"
                name="quantity"
                type='number'
                error={variantStockErrors.hasOwnProperty('quantity')}
                helperText={variantStockErrors.quantity}
                value={variantStock.quantity}
                placeholder="Enter Stock Quantity"
                onChange={handleVariantStockOnChange}
                fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Batch Number"
                name="batch_number"
                type='text'
                value={variantStock.batch_number}
                placeholder="Enter Batch Number"
                onChange={handleVariantStockOnChange}
                fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Batch Name"
                name="batch_name"
                type='text'
                value={variantStock.batch_name}
                placeholder="Enter Batch Name"
                onChange={handleVariantStockOnChange}
                fullWidth />
            </Grid>
            {variantStock.incoming_inventory &&
              <Grid item xs={12} sm={6}>
                <TextField
                  id="discountExpiresAt"
                  type="date"
                  label="Incoming Stock Date"
                  name="incoming_inventory_date"
                  error={variantStockErrors.hasOwnProperty('incoming_inventory_date')}
                  helperText={variantStockErrors.incoming_inventory_date}
                  value={variantStock.incoming_stock_date}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleVariantStockOnChange}
                  variant='outlined'
                  fullWidth />
              </Grid>
            }
            <Grid item xs={12} sm={12}>
              <FormGroup>
                <FormControlLabel control={<Checkbox name="incoming_inventory" color="primary" checked={variantStock.incoming_inventory} onChange={handleVariantStockOnChange} />} label="Incoming Stock" />
                {showNotifyEmail &&
                  <FormControlLabel control={<Checkbox name="send_waitlist_email" color="primary" checked={sendWaitlistEmail} onChange={
                    () => setSendWaitlistEmail(!sendWaitlistEmail)
                  } />} label={<div>{global.constants.labels.notify_waitlist}<FormHelperText>{global.constants.labels.notify_waitlist_help}</FormHelperText></div>} />
                }
              </FormGroup>
            </Grid>
          </Grid>
          <Divider width="100%" />
          <Grid container justifyContent="flex-end" sx={{margin: '15px'}}>
            <Button variant="contained" color="primary" onClick={handleAddStock}>Add Stock</Button>
          </Grid>
        </Grid>
      </Paper>
    </SharedModal>
  )
}


AddStockModal.propTypes = {
  variantId: PropTypes.number.isRequired,
  productVariant: PropTypes.object.isRequired,
  handleVariantQuantityAdded: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
}

export default AddStockModal