import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ProductContainer from '../containers/Product/productContainer'
import EditProductContainer from '../containers/Product/editProductContainer'
import ProductInventoryContainer from "../containers/Product/productInventoryContainer"
import ProductPositioningContainer from '../containers/Product/ProductPositioningContainer'

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={ProductContainer} />
    <Route path={`${match.path}/inventory`} component={ProductInventoryContainer} />
    <Route path={`${match.path}/new`} component={EditProductContainer} />
    <Route path={`${match.path}/edit/:id`} component={EditProductContainer} />
    <Route path={`${match.path}/reorder`} component={ProductPositioningContainer} />
  </Switch>
)
