import React from 'react'
import PropTypes from 'prop-types'
import { TextField, InputAdornment } from '@mui/material'

const IconTextField = ({ id, name, value, label, onChange, min, max, fullWidth, variant, icon }) => (
  <TextField
    id={id}
    type="number"
    name={name}
    min="0"
    step={0.5}
    label={label}
    value={value}
    onChange={onChange}
    variant={variant || 'outlined'}
    fullWidth={fullWidth || false}
    sx={{
      '& .MuiOutlinedInput-root': {
        paddingRight: 0
      },
      '& .MuiInputAdornment-root': {
        marginRight: 0
      }
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment
          sx={{
            backgroundColor: 'lightgrey',
            padding: '27.5px 14px'
          }}
          position="start">
          {icon}
        </InputAdornment>
      ),
      inputProps: { min: (min || 0), max: max }
    }} />
)

IconTextField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string
}

export default IconTextField