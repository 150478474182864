import 'core-js/es/map'
import 'core-js/es/set'
import './config/constants'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
// import { createStore } from 'redux'
import { BrowserRouter } from 'react-router-dom'
import { configureStore } from './store/configureStore'
import App from './components/App'
import initialState from './reducers/initialState'
import registerServiceWorker from './registerServiceWorker'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

let store = configureStore(initialState)

render(<Provider store={store}>
  <BrowserRouter>
    <App />
  </BrowserRouter>
</Provider>,
document.getElementById('root'))
registerServiceWorker()
