import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import EditFulfillmentCenter from "../../components/FulfillmentCenter/editFulfillmentCenter";
import { FulfillmentCenterAPI } from "../../api/fulfillmentCenterApi";
import { StoreApi } from "../../api/storeApi";
import { setSuccessNotification, setErrorNotification } from "../../actions/notificationAction";

const EditFulfillmentCenterContainer = () => {
  const [saving, setSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [stores, setStores] = useState([]);
  const [fulfillmentCenter, setFulfillmentCenter] = useState({
    id: 0,
    name: '',
    email: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    provider_type: 'custom',
    status: 'inactive'
  });

  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchFulfillmentCenter(id);
    fetchAllStores();
  }, [id]);

  const fetchAllStores = async () => {
    try {
      const response = await StoreApi.getStores({ page: -1 });
      setStores(response.data.data);
    } catch (error) {
      console.error('Error fetching stores:', error);
    }
  };

  const fetchFulfillmentCenter = async (centerId) => {
    if (centerId > 0) {
      try {
        const response = await FulfillmentCenterAPI.getFulfillmentCenter(centerId);
        setFulfillmentCenter(response.data);
      } catch (error) {
        console.error('Error fetching fulfillment center:', error);
      }
    }
  };

  const saveFulfillmentCenter = async (event) => {
    event.preventDefault();
    if (formIsValid()) {
      setSaving(true);
      try {
        const response = fulfillmentCenter.id > 0 ?
          await FulfillmentCenterAPI.updateFulfillmentCenter(fulfillmentCenter.id, fulfillmentCenter) :
          await FulfillmentCenterAPI.createFulfillmentCenter(fulfillmentCenter);
        dispatch(setSuccessNotification({ message: `Fulfillment center ${fulfillmentCenter.id > 0 ? 'updated' : 'created'} successfully.` }));
        history.push(`/admin/fulfillment-centers`);
      } catch (error) {
        dispatch(setErrorNotification({ message: error.response?.data?.error || error.message }));
      } finally {
        setSaving(false);
      }
    }
  };

  const formIsValid = () => {
    const errors = {};
    let isValid = true;

    if (!fulfillmentCenter.name) {
      errors.name = "Name can't be blank";
      isValid = false;
    }
    if (!fulfillmentCenter.email) {
      errors.email = "Email can't be blank";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const updateFulfillmentCenterState = (event) => {
    const { name, value } = event.target;
    setFulfillmentCenter({ ...fulfillmentCenter, [name]: value });
  };

  const updateStore = async (storeId, fulfillmentCenterId, action = null) => {
    try {
      let apiParams = { id: fulfillmentCenterId, store_id: storeId };
      if (action === 'remove') {
        apiParams['remove'] = true;
      }
      const apiResponse = await FulfillmentCenterAPI.updateStore(apiParams);
      const isRemovingStore = action === 'remove';  
      let updatedFulfillmentCenterStores = isRemovingStore ?
        fulfillmentCenter.stores.filter(store => store.id !== storeId) :
        [...fulfillmentCenter.stores, stores.find(store => store.id === storeId)];
      let updatedStores = isRemovingStore ?
        stores :
        stores.filter(store => store.id !== storeId);
      setFulfillmentCenter(prevState => ({
        ...prevState,
        stores: updatedFulfillmentCenterStores,
      }));
      setStores(updatedStores);  
      dispatch(setSuccessNotification({ message: apiResponse.data.message }));
    } catch (error) {
      dispatch(setErrorNotification({ message: error.response?.data?.error ?? error.message }));
    }
  };  

  return (
    <EditFulfillmentCenter 
      fulfillmentCenter={fulfillmentCenter}
      validationErrors={formErrors}
      onSave={saveFulfillmentCenter}
      onChange={updateFulfillmentCenterState}
      stores={stores}
      updateStore={updateStore}
    />
  )
}

export default EditFulfillmentCenterContainer;