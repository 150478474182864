import * as categoryActions from '../actions/categoryActions'
import initialState from './initialState'

const categories = (state = initialState.categories, action) => {
  let newState
  switch (action.type) {
    case categoryActions.GET_CATEGORIES:
      return {
        ...state,
        loading: true
      }
    case categoryActions.GET_CATEGORIES_SUCCESS:
      return {
        ...action.payload,
        loading: false,
        created: state.created,
        updated: state.updated
      }
    case categoryActions.GET_CATEGORY:
      return {
        ...state,
        loading: true
      }
    case categoryActions.GET_CATEGORY_SUCCESS:
      let indexOfCategoryToGet = state.data.findIndex(({ id }) => id === action.payload.id)
      let newStateAfterGet = {...state}
      if( indexOfCategoryToGet === -1) {
        newStateAfterGet.data.push(action.payload)
      } else {
        newStateAfterGet.data[indexOfCategoryToGet] = action.payload
      }
      return newStateAfterGet
    case categoryActions.CREATE_CATEGORY:
      return {
        ...state,
        updating: true
      }
    case categoryActions.CREATE_CATEGORY_SUCCESS:
      let newCatsState = {...state}
      newCatsState.data.push(action.payload)
      newCatsState.created = true
      return newCatsState
    case categoryActions.UPDATE_CATEGORY:
      return {
        ...state,
        updating: true
      }
    case categoryActions.UPDATE_CATEGORY_SUCCESS:
      let indexOfCatToUpdate = state.data.findIndex(({ id }) => id === action.payload.id)
      newState = {...state}
      newState.data[indexOfCatToUpdate] = action.payload
      newState.updated = true
      return newState
    case categoryActions.DELETE_CATEGORY:
      return {
        ...state
      }
    case categoryActions.DELETE_CATEGORY_SUCCESS:
      let indexOfCatToDelete = state.data.findIndex(({ id }) => id === action.payload.id)
      newState = {...state}
      newState.data.splice(indexOfCatToDelete, 1)
      return newState
    case categoryActions.RESET_CATEGORY_CREATED:
      return {
        ...state,
        created: false
      }
    case categoryActions.RESET_CATEGORY_UPDATED:
      return {
        ...state,
        updated: false
      }
    default:
      return state
  }
}

export default categories
