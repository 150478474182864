import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Divider, Typography, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { StoreApi } from '../api/storeApi';
import { FulfillmentCenterAPI } from '../api/fulfillmentCenterApi';
import * as notificationActions from '../../../actions/notificationAction';
import BackButton from '@core-ui-shared/BackButton';
import FulfillmentCenterList from '../components/FulfillmentCenterList';
import AuthenticationForm from '../components/AuthenticationForm';
import { LogisticsProvider, useLogistics } from '../contexts/logisticsContext';
import { Tile } from '../../../shared-components';
import useTranslation from '../../../hooks/useTranslation'

const styles = {
  paper: {
    paddingRight: 0,
    width: '100%',
    border: '1px solid lightgrey'
  },
  actionBtn: {
    marginLeft: '0.75rem',
    borderColor: 'lightgrey'
  }
}

const StoreShowContainer = (props) => {
  const {
    selectedCenter, 
    setSelectedCenter, 
    applyOnOrderStatus, 
    setApplyOnOrderStatus, 
    customOrderStatus, 
    isShelfless, 
    setIsShelfless, 
    dreamUsername, 
    setDreamUsername, 
    dreamPassword, 
    setDreamPassword, 
    showCredentials, 
    setShowCredentials,
    setShowAuthenticationForm,
    setAuthenticating,
    setFulfillmentCenters,
    store,
    setStore
  } = useLogistics();

  const { t } = useTranslation()
  const [stats, setStats] = useState({
    products: { displayValue: 0, difference: 0 },
    users: { displayValue: 0, difference: 0 },
    orders: { latestOrders: [], displayValue: 0, difference: 0 },
    sales: { displayValue: 0 }
  });
  const storeCurrency = useMemo(() => ({ name: 'Swedish Krone', symbol: 'kr', isoCode: 'sek' }), []);

  const fetchStore = async () => {
    try {
      const response = await StoreApi.getStore(props.storeId);
      setStore(response.data);
      setFulfillmentCenters(response.data.fulfillmentCenters || []);
    } catch (error) {
      props.notifyActions.setErrorNotification({ message: error.message });
    }
  };

  const fetchAllFulfillmentCenters = async () => {
    try {
      const response = await FulfillmentCenterAPI.getFulfillmentCenters({ page: -1 });
      setFulfillmentCenters(response.data.data);
    } catch (error) {
      props.notifyActions.setErrorNotification({ message: error.message });
    }
  };

  const calculateDifference = (object) => {
    if(object.newCount === object.lastMonthCount) {
      return 0
    }
    else if(object.newCount === 0 && object.lastMonthCount > 0) {
      return -100
    }
    else if(object.newCount > 0 && object.lastMonthCount === 0) {
      return 100
    }
    else {
      return parseInt(((object.newCount - object.lastMonthCount) / object.lastMonthCount) * 100);
    }
  }

  const fetchStats = async () => {
    try {
      const result = await StoreApi.getStats(props.storeId);
      const newStats = {
        products: {
          displayValue: result.data.products.totalCount,
          difference: calculateDifference(result.data.products),
        },
        users: {
          displayValue: result.data.users.newCount,
          difference: calculateDifference(result.data.users),
        },
        orders: {
          displayValue: result.data.orders.newCount,
          difference: calculateDifference(result.data.orders),
          latestOrders: result.data.orders.latestOrders,
        },
        sales: {
          displayValue: result.data.totalSales,
        }
      };
      setStats(newStats);
    } catch (error) {}
  };

  useEffect(() => {
    fetchStore();
    fetchAllFulfillmentCenters();
    fetchStats()
  }, [props.storeId]);

  const handleAddFulfillmentCenter = async (centerId) => {
    try {
      await StoreApi.addFulfillmentCenterToStore(props.storeId, centerId);
      fetchStore();
      fetchAllFulfillmentCenters();
      props.notifyActions.setSuccessNotification({ message: t('response_messages.fulfillment_center_added') });
    } catch (error) {
      props.notifyActions.setErrorNotification({ message: t('response_messages.error_failed_to_add_fulfillment_center') });
    }
  };

  const handleRemoveFulfillmentCenter = async (centerId) => {
    try {
      await StoreApi.removeFulfillmentCenterFromStore(props.storeId, centerId);
      fetchStore();
      fetchAllFulfillmentCenters();
      props.notifyActions.setSuccessNotification({ message: t('response_messages.fulfillment_center_removed') });
    } catch (error) {
      props.notifyActions.setErrorNotification({ message: t('response_messages.error_failed_to_remove_fulfillment_center') });
    }
  };

  const handleActivate = (center) => {
    setSelectedCenter(center);
    setIsShelfless(center.system_name === 'shelfless');
    setApplyOnOrderStatus(center.StoreFulfillmentCenters?.applyOnOrderStatus || 'default');
    setShowAuthenticationForm(true);

    if (center.system_name === 'shelfless' && center.StoreFulfillmentCenters.username) {
      setShowCredentials(false);
    } else {
      setShowCredentials(true);
    }
  };

  const handleDeactivate = async (center) => {
    try {
      await StoreApi.authenticateLogistics(props.storeId, center.id, applyOnOrderStatus === 'customized' ? customOrderStatus : applyOnOrderStatus, 'inactive');
      fetchStore();
      fetchAllFulfillmentCenters();
      props.notifyActions.setSuccessNotification({ message: t('response_messages.fulfillment_center_deactivated') });
    } catch (error) {
      props.notifyActions.setErrorNotification({ message: t('response_messages.fulfillment_center_activation_failed') });
    }
  };

  const handleEdit = (center) => {
    setSelectedCenter(center);
    setIsShelfless(center.system_name === 'shelfless');
    setApplyOnOrderStatus(center.StoreFulfillmentCenters?.applyOnOrderStatus || 'default');
    setShowAuthenticationForm(true);

    if (center.system_name === 'shelfless' && center.StoreFulfillmentCenters.username) {
      setShowCredentials(false);
    } else {
      setShowCredentials(true);
    }
  };

  const handleAuthenticate = async () => {
    if (isShelfless && showCredentials && (!dreamUsername || !dreamPassword)) {
      props.notifyActions.setErrorNotification({ message: t('response_messages.username_password_required') });
      return;
    }

    setAuthenticating(true);
    try {
      if (isShelfless) {
        await StoreApi.authenticateLogistics(
          props.storeId, 
          selectedCenter.id, 
          applyOnOrderStatus === 'customized' ? customOrderStatus : applyOnOrderStatus, 
          'active',
          showCredentials ? dreamUsername : undefined, 
          showCredentials ? dreamPassword : undefined
        );
      } else {
        await StoreApi.authenticateLogistics(
          props.storeId,
          selectedCenter.id,
          applyOnOrderStatus === 'customized' ? customOrderStatus : applyOnOrderStatus
        );
      }
      props.notifyActions.setSuccessNotification({ message: t('response_messages.fulfillment_center_activation_success') });
      setShowAuthenticationForm(false);
      fetchStore();
      fetchAllFulfillmentCenters();
    } catch (error) {
      props.notifyActions.setErrorNotification({ message: t('response_messages.fulfillment_center_activation_failed') });
    }
    setAuthenticating(false);
  };

  const handleRevokeCredentials = () => {
    setShowCredentials(true);
    setDreamUsername('');
    setDreamPassword('');
  };

  return (
    <Grid container spacing={3}>
      <Grid container item={true} xs={12} md={12} lg={12} xl={12} alignItems="center">
        <Grid item xs={12} md={6} lg={6} xl={6}>
          <Typography variant="h3">{store.name}</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={6} xl={6} textAlign="end">
          <BackButton />
          <Button sx={styles.actionBtn} variant="outlined" href={`/admin/stores/${store.id}/edit`}>
            <EditIcon />&nbsp;{t('response_messages.edit')}
          </Button>
        </Grid>
      </Grid>
      <Tile title={t('labels.sales_last_month')} object={stats.sales} module={t('labels.profit')} storeCurrency={storeCurrency} />
      <Tile title={t('labels.orders_last_month')} object={stats.orders} module={t('labels.order')} />
      <Tile title={t('labels.new_users')} object={stats.users} module={t('labels.user')} />
      <Tile title={t('labels.active_products')} object={stats.products} module={t('labels.product')} />
      <Divider width="100%" sx={{ paddingTop: '1%' }} />
      <FulfillmentCenterList
        onAddFulfillmentCenter={handleAddFulfillmentCenter}
        onRemoveFulfillmentCenter={handleRemoveFulfillmentCenter}
        onActivate={handleActivate}
        onDeactivate={handleDeactivate}
        onEdit={handleEdit}
      />
      <AuthenticationForm onAuthenticate={handleAuthenticate} onRevokeCredentials={handleRevokeCredentials} />
      <Divider width="100%" sx={{ marginTop: '2%', marginBottom: '2%' }} />
    </Grid>
  );
};

const StoreShowContainerWithProvider = (props) => (
  <LogisticsProvider>
    <StoreShowContainer {...props} />
  </LogisticsProvider>
);

const mapStateToProps = (state, ownProps) => {
  const storeId = Number(ownProps.match.params.id);
  return { storeId };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notifyActions: bindActionCreators(notificationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreShowContainerWithProvider);
