import axios from 'axios' 
import AuthStorage from '../services/authStorage'

const Auth = new AuthStorage()

const processErrors = err => {
  if (err?.response?.status === 403) {
    if(window.confirm('Your session seems to be expired, please login again.')) {
      Auth.setToken('')
      Auth.setStore({})
      Auth.setUser({})
      window.location.href='/'
    }
  } else if (err.response.data?.error_code === 'permission_failure') { // forbidden ability
    window.alert(err.response.data?.message);
    window.history.back();
  }
  throw err
}  

const post = (url = '', data = '', config = {}) => {
  if(data instanceof FormData) {
    delete config.headers.accept
    delete axios.defaults.headers.common['Accept']
  }
  return axios.post(url, data, config).catch(processErrors)
}

const get = (url, config = {}) => {
  return axios(url, config).catch(processErrors)
}

const put = (url = '', data = '', config = {}) => {
  if(data instanceof FormData) {
    delete config.headers.accept
    delete axios.defaults.headers.common['Accept']
  }
  return axios.put(url, data, config).catch(processErrors)
}

//Cannot contain a delete method - Cause delete is a keyword.
const del = (url = '', config = {}) => {
  return axios.delete(url, config).catch(processErrors)
}

//Encapsulating in a JSON object

const HttpClient = {
  post,
  get,
  put,
  delete: del
}

export {HttpClient}
