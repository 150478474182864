import { useState, useEffect } from 'react'
import enTranslations from '../locales/en.json'
import svTranslations from '../locales/sv.json'

const translations = {
  en: enTranslations,
  sv: svTranslations,
}

const getStoreLanguage = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  return user.language || 'en'
}

const useTranslation = () => {
  const [locale, setLocale] = useState(getStoreLanguage())
  const [translation, setTranslation] = useState(translations[locale])

  useEffect(() => {
    const language = getStoreLanguage()
    setLocale(language)
    setTranslation(translations[language])
  }, [])

  const t = (key) => {
    const keys = key.split('.')
    let value = translation
    keys.forEach(k => {
      value = value ? value[k] : key
    })
    return value || key
  }

  return { t, setLocale }
}

export default useTranslation