import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { TableCell, TableRow, IconButton } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import StatusChip from '../Helpers/StatusChip'

const OrderFulfillmentCenter = (props) => (
  <TableRow
    key={props.id}
    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
    <TableCell component="th" scope="row">
      <Link to={`/admin/orders/${props.order.id}`}>#{props.order.id}</Link>
    </TableCell>
    <TableCell component="th" scope="row">
      <Link to={`/admin/fulfillment-centers/${props.fulfillmentCenter.id}/edit`}>{props.fulfillmentCenter.name}</Link>
    </TableCell>
    <TableCell align="left">
      <Link to={`/admin/stores/${props.order.store?.id}/edit`}>{props.order.store?.name}</Link>
    </TableCell>
    <TableCell align="left">{(new Date(props.createdAt)).toDateString('sv')}</TableCell>
    <TableCell align="left">{props.order.name || props.order.shipping_name}</TableCell>
    <TableCell align="left">{`${props.order.net_total ? (Math.round(parseFloat(props.order.net_total) * 100) / 100) : '0'}`}</TableCell>
    <TableCell align="left"><StatusChip status={props.order.payment_status} /></TableCell>
    <TableCell align="left"><StatusChip status={props.order.status} /></TableCell>
    <TableCell>
      <Link to={`/admin/fulfillment-center-orders/${props.id}`}>
        <IconButton color="primary" size="small"><VisibilityIcon /></IconButton>
      </Link>
    </TableCell>
  </TableRow>
)

OrderFulfillmentCenter.propTypes = {
  id: PropTypes.number.isRequired,
}

export default OrderFulfillmentCenter
