import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import RichTextEditor from 'react-rte'


export const getTextAlignClassName = (contentBlock) => {

  switch (contentBlock.getData().get('textAlign')) {
    case 'ALIGN_LEFT':
      return 'text-align--left'

    case 'ALIGN_CENTER':
      return 'text-align--center'

    case 'ALIGN_RIGHT':
      return 'text-align--right'

    case 'ALIGN_JUSTIFY':
      return 'text-align--justify'

    default:
      return ''
  }
}

export const getTextAlignStyles = (contentBlock) => {
  switch (contentBlock.getData().get('textAlign')) {
    case 'ALIGN_LEFT':
      return {
        style: {
          textAlign: 'left',
        },
      }

    case 'ALIGN_CENTER':
      return {
        style: {
          textAlign: 'center',
        },
      }

    case 'ALIGN_RIGHT':
      return {
        style: {
          textAlign: 'right',
        },
      }

    case 'ALIGN_JUSTIFY':
      return {
        style: {
          textAlign: 'justify',
        },
      }

    default:
      return {}
  }
}

export const getTextAlignBlockMetadata = (element) => {
  switch (element.style.textAlign) {
    case 'right':
      return {
        data: {
          textAlign: 'ALIGN_RIGHT',
        },
      }

    case 'center':
      return {
        data: {
          textAlign: 'ALIGN_CENTER',
        },
      }

    case 'justify':
      return {
        data: {
          textAlign: 'ALIGN_JUSTIFY',
        },
      }

    case 'left':
      return {
        data: {
          textAlign: 'ALIGN_LEFT',
        },
      }

    default:
      return {}
  }
}

const TextEditor = props => {
  const emptyValue = RichTextEditor.createEmptyValue()
  const [value, setValue] = useState(emptyValue)

  const editroHeight = props.rows ? props.rows * 20 : 100
  const customStyle = {
    height: editroHeight,
  }

  useEffect(() => {
    if (props.value &&  value.toString('html') !== props.value) {
      setValue(RichTextEditor.createValueFromString(props.value, 'html', {customBlockFn: getTextAlignBlockMetadata}))
    }
  }, [props.value])

  const onChange = value => {
    setValue(value)
    if(props.onChange) {
      props.onChange(value.toString('html', {blockStyleFn: getTextAlignStyles}))
    }
  }
  return (
    <RichTextEditor
      toolbarConfig={
        {
          display: ['INLINE_STYLE_BUTTONS', 'BLOCK_ALIGNMENT_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
          INLINE_STYLE_BUTTONS: [
            {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
            {label: 'Italic', style: 'ITALIC'},
            {label: 'Underline', style: 'UNDERLINE'}
          ],
          BLOCK_ALIGNMENT_BUTTONS: [
            {label: 'Align Left', style: 'ALIGN_LEFT'},
            {label: 'Align Center', style: 'ALIGN_CENTER'},
            {label: 'Align Right', style: 'ALIGN_RIGHT'},
            {label: 'Align Justify', style: 'ALIGN_JUSTIFY'},
          ],
          BLOCK_TYPE_DROPDOWN: [
            {label: 'Normal', style: 'unstyled'},
            {label: 'Heading Large', style: 'header-one'},
            {label: 'Heading Medium', style: 'header-two'},
            {label: 'Heading Small', style: 'header-three'}
          ],
          BLOCK_TYPE_BUTTONS: [
            {label: 'UL', style: 'unordered-list-item'},
            {label: 'OL', style: 'ordered-list-item'}
          ]
        }
      }
      blockStyleFn={getTextAlignClassName}
      value={value}
      editorStyle={ customStyle }
      onChange={onChange}
    />
  )
}
TextEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.number,
}
export default TextEditor
