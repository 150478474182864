import React from 'react'
import PropTypes from 'prop-types'
import { SketchPicker } from 'react-color'

export default function ColorPicker(props) {

  return (
    <div style={
      {
        position: 'absolute',
        zIndex: '5',
        right: props.right ? props.right : '0'
      }
    }>
      <div
        style={ 
          {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          }
        }
        onClick={props.onClose}
      >

      </div>
      <SketchPicker
        name="footer_bg_color"
        color={props.color}
        onChangeComplete={props.onChangeComplete}
      />
    </div>
  )
}

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  onChangeComplete: PropTypes.func.isRequired,
  onClose: PropTypes.func
}
