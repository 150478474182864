import  React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LanguageIcon from '@mui/icons-material/Language'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import grey from '@mui/material/colors/grey'
import AuthStorage from '../../services/authStorage'
import { useAbility } from '../../hooks/useAbility' // remove
import { defaultMenuConfig } from './menuConfig'
import SideMenu from './CustomMenu'
import useTranslation from '../../hooks/useTranslation'

const Auth = new AuthStorage()
const { fetchRulesForUser } = useAbility()

const drawerWidth = 300

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
)

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

const getStoreLanguage = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  return user.language || 'en'
}

export default function SideBar(props) {
  const ability = fetchRulesForUser(Auth.getPermissions(), Auth.getUser())
  const history = useHistory()
  const theme = useTheme()
  const [menuConfig, setMenuConfig] = React.useState(defaultMenuConfig)
  const [open, setOpen] = React.useState(window.outerWidth < 900 ? false : true)
  const [anchorEl, setAnchorEl] = React.useState(null)
   const [language, setLanguage] = useState(getStoreLanguage())
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null)
  const { t } = useTranslation()

  const setResponsiveness = () => {
    return window.outerWidth < 900
      ? setOpen(false)
      : setOpen(true)
  }

  React.useEffect(() => {
    setResponsiveness()
    window.addEventListener('resize', () => setResponsiveness())
    setMenuConfig(defaultMenuConfig)
    return () => {
      window.removeEventListener('resize', () => setResponsiveness())
    }
  }, [])

  // On mobile view close sidebar on navigating
  React.useEffect(() => {
    const unregisterCallback = history.listen(() => setResponsiveness())

    // remove listner on unmount 
    return () => unregisterCallback()
  }, [history])

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleLanguageMenuOpen = (event) => {
    setLanguageAnchorEl(event.currentTarget)
  }

  const handleLanguageMenuClose = () => {
    setLanguageAnchorEl(null)
  }

  const handleLanguageChange = (lang) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    user.language = lang
    localStorage.setItem('user', JSON.stringify(user))
    setLanguage(lang)
    window.location.reload()
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" color='primary' open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>MetaStore</Typography>
          <Button
            startIcon={<LanguageIcon />}
            endIcon={<ArrowDropDownIcon />}
            sx={{
              color: 'white',
              borderColor: 'white',
              textTransform: 'none',
              mx: 2,
              height: '36px',
              border: '1px solid',
            }}
            onClick={handleLanguageMenuOpen}
          >
            {language === 'en' ? t('labels.english') : t('labels.swedish')}
          </Button>
          <Menu
            id="menu-language"
            anchorEl={languageAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(languageAnchorEl)}
            onClose={handleLanguageMenuClose}
          >
            <MenuItem onClick={() => handleLanguageChange('en')}>{t('labels.english')}</MenuItem>
            <MenuItem onClick={() => handleLanguageChange('sv')}>{t('labels.swedish')}</MenuItem>
          </Menu>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={(e) => {
                e.preventDefault()
                if (window.confirm('Logout?')) {
                  Auth.setToken('')
                  Auth.setUser({})
                  history.push('/')
                }
              }}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader sx={{backgroundColor: grey[900]}}>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, paddingLeft: theme.spacing(3), color: grey[50] }}>
            Admin
          </Typography>
          <IconButton onClick={handleDrawerClose} sx={{color: grey[50]}}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <SideMenu items={menuConfig} ability={ability} />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Container>
          {props.children}
        </Container>
      </Main>
    </Box>
  )
}

SideBar.propTypes = {
}
