import {HttpClient} from './httpClient' 
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'
import {setUrlForGetRequest} from './sharedFunctions'

//Setting the store API URI
const STORE_API = `${API_URL}/stores`
const CURRENCY_API = `${API_URL}/currencies`

const getCurrencies = () => {
  return HttpClient.get(
    `${CURRENCY_API}/`,
    apiHeaders()
  )
}

// Get
const getStore = (id) => {
  return HttpClient.get(`${STORE_API}/${id}`, apiHeaders())
}

// Read
const getStores = (params) => {
  let url = setUrlForGetRequest(STORE_API, params)
  return HttpClient.get(url, apiHeaders())
}

// Update
const updateStore = (id, store) => {
  return HttpClient.put(
    `${STORE_API}/${id}/update`,
    store,
    apiHeaders()
  )
}

const validateStoreUrl = (payload) => {
  return HttpClient.post(
    `${STORE_API}/store-url`,
    payload,
    apiHeaders()
  )
}

// Authenticate Shelfless
const authenticateLogistics = (storeId, fulfillmentCenterId, applyOnOrderStatus, status='active', username = null, password = null) => {
  return HttpClient.post(
    `${STORE_API}/${storeId}/authenticate-logistics`,
    { username, password, fulfillmentCenterId, applyOnOrderStatus, status },
    apiHeaders()
  );
};

const addFulfillmentCenterToStore = (storeId, fulfillmentCenterId) => {
  return HttpClient.post(
    `${API_URL}/stores/${storeId}/add-fulfillment-center`,
    { fulfillment_center_id: fulfillmentCenterId },
    apiHeaders()
  );
};

// Remove fulfillment center from store
const removeFulfillmentCenterFromStore = (storeId, fulfillmentCenterId) => {
  return HttpClient.post(
    `${API_URL}/stores/${storeId}/add-fulfillment-center`,
    { fulfillment_center_id: fulfillmentCenterId, remove: true },
    apiHeaders()
  );
};

const createStoreWithDemo = (payload) => {
  return HttpClient.post(
    `${STORE_API}/create`,
    payload,
    apiHeaders()
  )
}

const duplicateStore = (payload) => {
  return HttpClient.post(
    `${STORE_API}/duplicate`,
    payload,
    apiHeaders()
  )
}

const getStats = (id) => {
  return HttpClient.get(`${STORE_API}/${id}/stats`, apiHeaders())
}

const destroy = (id) => {
  return HttpClient.delete(`${STORE_API}/${id}`, apiHeaders())
}

const restore = (id) => {
  return HttpClient.get(`${STORE_API}/${id}/restore`, apiHeaders())
}


//Encapsulating in a JSON object
const StoreApi = {getStore, getStores, updateStore, validateStoreUrl, getCurrencies, authenticateLogistics, addFulfillmentCenterToStore, removeFulfillmentCenterFromStore, createStoreWithDemo, duplicateStore, getStats, destroy, restore}

export {StoreApi}
