import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'react-bootstrap'

const ErrorNotification = (props) => {
  
  return props.isOpen && props.errors.length ? (
    <Alert style={{marginTop: '1em'}} bsStyle="danger" onDismiss={props.handleClose} >
      {props.errors.map(err => <p key={err.name | err.message}>{err.message}</p>)}
    </Alert>
  ) : null

}

ErrorNotification.propTypes = {  
  errors: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}
export default ErrorNotification
