import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import EditUserContainer from '../containers/User/editUserContainer'
import UserContainer from '../containers/User/userContainer'

const Users = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={UserContainer} />
    <Route path={`${match.path}/:id/edit`} component={EditUserContainer} />
    <Route path={`${match.path}/new`} component={EditUserContainer} />
  </Switch>
)

Users.propTypes = {  
  match: PropTypes.object,
}

export default Users