import React from 'react'
import PropTypes from 'prop-types'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'

const styles = {
  imageContainer: {
    border: 'solid 0.06rem #ddd',
    borderRadius: '0.25rem',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  image:{
    display: 'block',
    width: 'auto',
    height: 'auto'
  }
}

const ImageThumbnail = ({ image, width, height }) => {
  return (
    <div style={{...styles.imageContainer, width: width ?? styles.imageContainer.width, height: height ?? styles.imageContainer.height }}>
      {image ? <img style={styles.image} src={`${image}?d=40x40`}/> : <ImageOutlinedIcon style={styles.image}/> }
    </div>
  )
}

ImageThumbnail.propTypes = {
  image: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
}

export default ImageThumbnail
