import {HttpClient} from './httpClient' 
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'
import {setUrlForGetRequest} from './sharedFunctions'

//Setting the users URI
const USER_API = `${API_URL}/users`

// Create
const createUser = user => {
  return HttpClient.post(
    `${USER_API}/create`,
    user,
    apiHeaders()
  )
}

// Get
const getUsers = (params) => {
  let url = setUrlForGetRequest(USER_API, params)
  return HttpClient.get(url, apiHeaders())
}

const getUser = id => {
  return HttpClient.get(
    `${USER_API}/${id}`,
    apiHeaders()
  )
}

const getCurrentUser = () => {
  return HttpClient.get(
    `${USER_API}/current`,
    apiHeaders()
  )
}

// Update
const updateUser = (id, user) => {
  return HttpClient.put(
    `${USER_API}/${id}/update`,
    user,
    apiHeaders()
  )
}

// Delete
const removeUser = user => {
  return HttpClient.delete(
    `${USER_API}/${user.id}`,
    apiHeaders()
  )
}

//Encapsulating in a JSON object
const UserApi = { getUsers, createUser, getUser, getCurrentUser, updateUser, removeUser }

export {UserApi}
