import React from 'react'
import { Switch } from 'react-router-dom'
import Home from '../components/Home'
import HomeRoute from './Home'
import PrivateRoute from './PrivateRoute'
import Admin from './Admin'

export default () => (
  <Switch>
    <HomeRoute exact path='/' component={Home} />
    <PrivateRoute path='/admin' component={Admin} />
  </Switch>
)
