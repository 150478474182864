import React from 'react'
import { TextField, FormGroup, InputAdornment, FormHelperText } from '@mui/material';

const FieldGroup = ({ id, validationState=null, label, help, append, prepend, ...props }) => (
  <FormGroup style={{paddingTop: 20}}>
    <TextField {...props} value={`${props.value}`} error={validationState === 'error'} label={label} InputProps={{
      startAdornment: (
        prepend && <InputAdornment position="start">
          {prepend}
        </InputAdornment>
      ),
      endAdornment:(
        append && <InputAdornment position="end">
          {append}
        </InputAdornment>
      )
    }} variant="outlined" />
    {help && <FormHelperText>{help}</FormHelperText>}
  </FormGroup>
)

export default FieldGroup
