import * as productActions from '../actions/productActions'
import initialState from './initialState'

const products = (state = initialState.products, action) => {
  switch (action.type) {
    case productActions.GET_PRODUCTS:
      return {
        ...state,
        loading: true
      }
    case productActions.GET_PRODUCTS_SUCCESS:
      return {
        ...action.payload,
        loading: false
      }
    case productActions.GET_PRODUCT:
      return {
        ...state,
        loading: true
      }
    case productActions.GET_PRODUCT_SUCCESS:
      let indexOfProductToGet = state.data.findIndex(({ id }) => id === action.payload.id)
      let newStateAfterGet = {...state}
      if( indexOfProductToGet === -1) {
        newStateAfterGet.data.push(action.payload)
      } else {
        newStateAfterGet.data[indexOfProductToGet] = action.payload
      }
      return newStateAfterGet
    case productActions.SEARCH_PRODUCT:
      return {
        ...state,
        loading: true
      }
    case productActions.SEARCH_PRODUCT_SUCCESS:
      let newStateAfterSearch = {...state}
      newStateAfterSearch.searched_product = action.payload
      return newStateAfterSearch
    case productActions.CREATE_PRODUCT:
      return {
        ...state,
        updating: true
      }
    case productActions.CREATE_PRODUCT_SUCCESS:
      let newProdsState = {...state, created: true}
      newProdsState.data.push(action.payload)
      return newProdsState
    case productActions.UPDATE_PRODUCT:
      return {
        ...state,
        updating: true
      }
    case productActions.UPDATE_PRODUCT_SUCCESS:
      let indexOfProductToUpdate = state.data.findIndex(({ id }) => id === action.payload.id)
      let newState = {...state, updating: false, updated: true}
      newState.data[indexOfProductToUpdate] = action.payload
      return newState
    case productActions.DELETE_PRODUCT:
      return {
        ...state
      }
    case productActions.DELETE_PRODUCT_SUCCESS:
      let indexOfProductToDelete = state.data.findIndex(({ id }) => id === action.payload.id)
      let updatedState = {...state}
      updatedState.data.splice(indexOfProductToDelete, 1)
      return updatedState
    case productActions.RESET_PRODUCT_CREATED:
      return {...state, created: false}
    case productActions.RESET_PRODUCT_UPDATED:
      return {...state, updated: false}
    default:
      return state
  }
}

export default products
