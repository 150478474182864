import React from 'react'
// import logo from './logo.svg'
import './App.css'
import Routes from '../../routes'
// import Grid from 'react-bootstrap/'
import NotifyInfo from '../../utils/notificationMessagingUtils'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { grey, green } from '@mui/material/colors'

const theme = createTheme({
  palette: {
    primary: {
      main: grey[900],
    },
    secondary: {
      main: green[500],
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          // backgroundColor: grey['A400'],
          // color: grey[50],
        }
      }
    }
  }
})

export default () => (
  <div className='main'>
    <ThemeProvider theme={theme}>
      <NotifyInfo/>
      <Routes />
    </ThemeProvider>
  </div>
)
