export const SET_ERROR_NOTIFICATION = '[Mstore] SET_ERROR_NOTIFICATION'
export const CLOSE_NOTIFICATION = '[Mstore] CLOSE_NOTIFICATION'
export const SET_INFO_NOTIFICATION = '[Mstore] SET_INFO_NOTIFICATION'
export const SET_SUCCESS_NOTIFICATION = '[Mstore] SET_SUCCESS_NOTIFICATION'

export const setErrorNotification = (data) => {
  data.open = true
  data.notificationType = 'error'
  return {
    type: SET_ERROR_NOTIFICATION,
    payload: data
  }
}

export const setSuccessNotification = (data) => {
  data.open = true
  data.notificationType = 'success'
  return {
    type: SET_SUCCESS_NOTIFICATION,
    payload: data
  }
}

export const setInfoNotification = (data) => {
  data.open = true
  data.notificationType = 'info'
  return {
    type: SET_INFO_NOTIFICATION,
    payload: data
  }
}

export const closeNotification = () => {
  const data = {open: false}
  return {
    type: CLOSE_NOTIFICATION,
    payload: data
  }
}
