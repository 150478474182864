import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import DashboardContainer from '../containers/dashboardContainer'

const Dashboard = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={DashboardContainer} />
  </Switch>
)

Dashboard.propTypes = {  
  match: PropTypes.object.isRequired
}

export default Dashboard
