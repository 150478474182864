import React from 'react'
import PropTypes from 'prop-types'
import { TableCell } from '@mui/material'
import ImageThumbnail from './ImageThumbnail'

const ListingThumbnail = ({ image, width, height }) => {

  // TODO: this should be more dynamic than fixed with, height
  // hardcoded height doesn't seem like a good idea, it should be based on the content of the column
  const styles = {
    imageColumn: {
      padding: '0px',
      width: '50px',
      height: '50px',
    },
  }

  return (
    <TableCell component="th" scope="row" sx={styles.imageColumn}>
      <ImageThumbnail image={image} width={width} height={height}/>
    </TableCell>
  )
}

ListingThumbnail.propTypes = {
  image: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
}

export default ListingThumbnail;
