import {HttpClient} from './httpClient' 
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'
import {setUrlForGetRequest} from './sharedFunctions'

//Setting the categories URI
const CATEGORY_API = `${API_URL}/categories`

// Create
const createCategory = category => {
  return HttpClient.post(
    `${CATEGORY_API}/create`,
    category,
    apiHeaders()
  )
}

// Read
const getCategories = (params, dependent = {}) => {
  let url = setUrlForGetRequest(CATEGORY_API, params)
  return HttpClient.get(url, apiHeaders(dependent) )
}

// Get
const getCategory = id => {
  return HttpClient.get(
    `${CATEGORY_API}/${id}`,
    apiHeaders()
  )
}

// Update
const updateCategory = (id, category) => {
  return HttpClient.put(
    `${CATEGORY_API}/${id}/update`,
    category,
    apiHeaders()
  )
}

// Delete
const removeCategory = category => {
  return HttpClient.delete(
    `${CATEGORY_API}/${category.id}`,
    apiHeaders()
  )
}

//Encapsulating in a JSON object
const CategoryApi = {getCategories, createCategory, getCategory, updateCategory, removeCategory}

export {CategoryApi}