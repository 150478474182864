import {HttpClient} from '../../../api/httpClient' 
import {API_URL} from '../../../api/RestAPI'
import apiHeaders from '../../../api/apiHeaders'
import {setUrlForGetRequest} from '../../../api/sharedFunctions'

const FulfillmentCenter_API = `${API_URL}/fulfillment-centers`

const createFulfillmentCenter = fulfillmentCenter => {
  return HttpClient.post(
    `${FulfillmentCenter_API}/create`,
    fulfillmentCenter,
    apiHeaders()
  )
}

const getFulfillmentCenters = (params, dependent = {}) => {
  let url = setUrlForGetRequest(FulfillmentCenter_API, params)
  return HttpClient.get(url, apiHeaders(dependent))
}

const getFulfillmentCenter = id => {
  return HttpClient.get(
    `${FulfillmentCenter_API}/${id}`,
    apiHeaders()
  )
}

const updateFulfillmentCenter = (id, fulfillmentCenter) => {
  return HttpClient.put(
    `${FulfillmentCenter_API}/${id}/update`,
    fulfillmentCenter,
    apiHeaders()
  )
}

const removeFulfillmentCenter = fulfillmentCenter => {
  return HttpClient.delete(
    `${FulfillmentCenter_API}/${fulfillmentCenter.id}`,
    apiHeaders()
  )
}

const searchFulfillmentCenters = (q, page=0, storeId) => {
  return HttpClient.get(
    `${FulfillmentCenter_API}?q=${q}&page=${page}&store_id=${storeId}`,
    apiHeaders()
  )
}

const updateStore = fulfillmentCenter => {
    return HttpClient.post(
      `${FulfillmentCenter_API}/update-store`,
      fulfillmentCenter,
      apiHeaders()
    )
}

const FulfillmentCenterAPI = {getFulfillmentCenters, createFulfillmentCenter, getFulfillmentCenter, updateFulfillmentCenter, removeFulfillmentCenter, searchFulfillmentCenters, updateStore}

export {FulfillmentCenterAPI}