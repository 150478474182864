export default {
  store: {},
  orders: {
    data: [],
    count: 0,
    page_no: 1,
    loading: false
  },
  errors: {
    errors: [],
    isOpen: false
  },
  notifications: {
    message: '',
    open: false,
    notificationType: ''
  },
  products: {
    data: [],
    count: 0,
    page_no: 1,
    loading: false,
    created: false,
    updated: false
  },
  categories: {
    data: [],
    count: 0,
    page_no: 1,
    created: false,
    loading: false,
    updated: false
  },
  autocomplete: {
    query: '',
    suggestions: []
  },
}
