import React, { createContext, useContext, useState } from 'react';

const LogisticsContext = createContext();

export const LogisticsProvider = ({ children }) => {
  const [selectedFulfillmentCenter, setSelectedFulfillmentCenter] = useState('');
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [applyOnOrderStatus, setApplyOnOrderStatus] = useState('default');
  const [customOrderStatus, setCustomOrderStatus] = useState('processing');
  const [isShelfless, setIsShelfless] = useState(false);
  const [dreamUsername, setDreamUsername] = useState('');
  const [dreamPassword, setDreamPassword] = useState('');
  const [showCredentials, setShowCredentials] = useState(false);
  const [showAuthenticationForm, setShowAuthenticationForm] = useState(false);
  const [authenticating, setAuthenticating] = useState(false);
  const [fulfillmentCenters, setFulfillmentCenters] = useState([]);
  const [store, setStore] = useState({});

  return (
    <LogisticsContext.Provider
      value={{
        selectedFulfillmentCenter,
        setSelectedFulfillmentCenter,
        selectedCenter,
        setSelectedCenter,
        applyOnOrderStatus,
        setApplyOnOrderStatus,
        customOrderStatus,
        setCustomOrderStatus,
        isShelfless,
        setIsShelfless,
        dreamUsername,
        setDreamUsername,
        dreamPassword,
        setDreamPassword,
        showCredentials,
        setShowCredentials,
        showAuthenticationForm,
        setShowAuthenticationForm,
        authenticating,
        setAuthenticating,
        fulfillmentCenters,
        setFulfillmentCenters,
        store,
        setStore
      }}
    >
      {children}
    </LogisticsContext.Provider>
  );
};

export const useLogistics = () => {
  const context = useContext(LogisticsContext);
  if (!context) {
    throw new Error("useLogistics must be used within a LogisticsProvider");
  }
  return context;
};