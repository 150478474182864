import {HttpClient} from './httpClient' 
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'
import { setUrlForGetRequest } from './sharedFunctions'

//Setting the products URI
const PRODUCT_API = `${API_URL}/products`
const PRODUCT_COLLECTION_API = `${API_URL}/product`
const PRODUCT_VARIANT_URL = `${API_URL}/product-variants`

const getProductVariants = (params) => {
  let url = setUrlForGetRequest(PRODUCT_VARIANT_URL, params)
  return HttpClient.get(url, apiHeaders())
}

// Create
const createProduct = product => {
  return HttpClient.post(
    `${PRODUCT_API}/create`,
    product,
    apiHeaders()
  )
}

// Read
const getProducts = (params) => {
  let url = setUrlForGetRequest(PRODUCT_API, params)
  return HttpClient.get(url, apiHeaders())
}

// Read
const searchProducts = (q, page=0, all=false, dependent = {}) => {
  return HttpClient.get(
    `${PRODUCT_API}?q=${q}&page=${page}&all=${all}`,
    apiHeaders(dependent)
  )
}


const productsForOrders = (q, dependent = {}) => {
  return HttpClient.get(
    `${PRODUCT_API}/list-for-orders?q=${q}`,
    apiHeaders(dependent)
  )
}

// Get
const getProduct = id => {
  return HttpClient.get(
    `${PRODUCT_API}/${id}`,
    apiHeaders()
  )
}

// Update
const updateProduct = (id, product) => {
  return HttpClient.put(
    `${PRODUCT_API}/${id}/update`,
    product,
    apiHeaders()
  )
}

// Delete
const removeProduct = product => {
  return HttpClient.delete(
    `${PRODUCT_API}/${product.id}`,
    apiHeaders()
  )
}
// Create Product image
const createProductImage = (id, image, dependent = {}) => {
  return HttpClient.post(
    `${PRODUCT_API}/${id}/productimages/create`,
    image,
    apiHeaders(dependent)
  )
}

// Delete Product image
const removeProductImage = (id, imageId) => {
  return HttpClient.delete(
    `${PRODUCT_API}/${id}/productimages/${imageId}`,
    apiHeaders()
  )
}

const updateProductImage = (id, image) => {
  return HttpClient.put(
    `${PRODUCT_API}/${id}/productimages/${image.id}`,
    image,
    apiHeaders()
  )
}

const addToCollection = (body) => {
  return HttpClient.post(
    `${PRODUCT_COLLECTION_API}/addCollections`,
    body,
    apiHeaders()
  )
}

// Update product positions
const updateProductPositions = (positions) => {
  return HttpClient.put(
    `${PRODUCT_API}/positions`,
    { products: positions },
    apiHeaders()
  );
}

const fetchStock = id => {
  return HttpClient.get(
    `${PRODUCT_API}/${id}/fetch_stock`,
    apiHeaders()
  )
}

const syncStock = (id) => {
  return HttpClient.get(
    `${PRODUCT_API}/${id}/sync_stock`,
    apiHeaders()
  );
};

//Encapsulating in a JSON object
const ProductApi = {getProducts, productsForOrders, searchProducts, createProduct, getProduct, updateProduct, removeProduct, createProductImage, removeProductImage, addToCollection, updateProductImage, getProductVariants, updateProductPositions, fetchStock, syncStock}

export {ProductApi}