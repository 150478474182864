import React from 'react'
import { Switch, Route } from 'react-router-dom'
import EditFulfillmentCenterContainer from '../containers/FulfillmentCenter/editFulfillmentCenterContainer'
import FulfillmentCenterContainer from '../containers/FulfillmentCenter/fulfillmentCenterContainer'
import OrderFulfillmentCenterContainer from '../containers/OrderFulfillmentCenter/orderFulfillmentCenterContainer'

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={FulfillmentCenterContainer} />
    <Route path={`${match.path}/new`} component={EditFulfillmentCenterContainer} />
    <Route path={`${match.path}/:id/edit`} component={EditFulfillmentCenterContainer} />
    <Route path={`${match.path}/:id/fulfillment-center-orders`} component={OrderFulfillmentCenterContainer} />
  </Switch>
)