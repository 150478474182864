import * as autocompleteActions from '../actions/autocompleteActions'
import initialState from './initialState'

const autocomplete = (state = initialState.autocomplete, action) => {
  switch (action.type) {
    case autocompleteActions.SEARCH_PRODUCTS:
      return {
        ...state,
        searching: true
      }
    case autocompleteActions.SEARCH_PRODUCTS_SUCCESS:
      let newStateAfterSearch = {...state}
      newStateAfterSearch.suggestions = action.payload
      newStateAfterSearch.searching = false
      return newStateAfterSearch
    case autocompleteActions.GET_MT_PRODUCT:
      return {
        ...state,
        loading: true
      }
    case autocompleteActions.GET_MT_PRODUCT_SUCCESS:
      let newStateAfterGet = {...state}
      newStateAfterGet.product = action.payload
      return newStateAfterGet
    case autocompleteActions.RESET_MT_PRODUCT:
      return initialState.autocomplete
    default:
      return state
  }
}

export default autocomplete