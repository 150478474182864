import * as React from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard'
import InboxIcon from '@mui/icons-material/Inbox'
import StoreIcon from '@mui/icons-material/Store'
import UserIcon from '@mui/icons-material/People'
import CategoryIcon from '@mui/icons-material/Category'
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AuthStorage from '../../services/authStorage'
const Auth = new AuthStorage

const defaultMenuConfig = [
  {
    icon: <DashboardIcon />,
    title: 'Dashboard',
    to: '/admin',
    extraLinks: ['/admin/dashboard'],
    items: []
  },
  {
    icon: <InboxIcon />,
    title: 'Orders',
    to: '/admin/orders',
    active: false
  },
  {
    icon: <StoreIcon />,
    title: 'Stores',
    to: '/admin/stores',
    active: false
  },
    {
    icon: <CategoryIcon />,
    title: 'Products',
    to: '/admin/products',
    active: false
  },
  {
    icon: <LocalShippingIcon />,
    title: 'Fulfillment',
    open: false,
    items: [
      {
        title: 'Fulfillment Centers',
        to: '/admin/fulfillment-centers',
        active: false
      },
      {
        title: 'Fulfillment Orders',
        to: '/admin/fulfillment-center-orders',
        active: false
      }
    ]
  },
]

if (Auth.getUser()?.role === global.constants.userRoles.superAdmin) {
  defaultMenuConfig.push({
    icon: <UserIcon />,
    title: 'Users',
    to: '/admin/users',
    active: false
  })
}

export { defaultMenuConfig }