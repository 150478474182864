import React from 'react'
import PropTypes from 'prop-types'
import ReactApexChart from "react-apexcharts"

const LineChart = ({ data, seriesName, height, title }) => {
  let apex = {
    series: [{
        name: seriesName,
        data: data
    }],
    options: {
      chart: {
        height: height,
        type: 'line',
        zoom: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: title,
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        },
      },
      yaxis: [
        {
          labels: {
            formatter: function(val) { return val.toFixed(0) }
          }
        }
      ]
    },
  };

  return (
    <div id="chart">
      <ReactApexChart options={apex.options} series={apex.series} type="line" />
    </div>
  )
}

LineChart.propTypes = {
  data: PropTypes.array.isRequired,
  seriesName: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default LineChart