import * as storeActions from '../actions/storeActions'
import initialState from './initialState'

const store = (state = initialState.store, action) => {
  switch (action.type) {
    case storeActions.GET_STORE:
      return {
        ...state,
        loading: true
      }
    case storeActions.GET_STORE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    case storeActions.UPDATE_STORE:
      return {
        ...state,
        updating: true
      }
    case storeActions.UPDATE_STORE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        updating: false
      }
    case storeActions.VALIDATE_STORE_URL:
      return {
        ...state,
        ...action.payload,
        validating: true
      }
    case storeActions.VALIDATE_STORE_URL_SUCCESS:
      return {
        ...state,
        ...action.payload,
        validating: false
      }
    default:
      return state
  }
}

export default store