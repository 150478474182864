import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, Button } from '@mui/material';
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux'
import * as notificationActions from "../../actions/notificationAction"; 
import EditProduct from '../../components/Product/editProduct'
import { ProductApi } from '../../api/productApi'
import { CategoryApi } from '../../api/categoryApi'
import { isEmpty } from 'lodash';
import EditPageUpperContainer from '../../components/Shared/EditPageHeader';
import {useHistory, useParams} from "react-router-dom";
import * as productActions from '../../actions/productActions'
import * as categoryActions from '../../actions/categoryActions';
import * as autocompleteActions from '../../actions/autocompleteActions';

const emptyProduct = () => {
  return {
    id: 0, name: '', category_id: 0, description: '', article_no: '', is_bundle: false, visible: false, query: '', has_variants: true, freight: 0, price: 0, fulfillment_center_id: '',
    category: { id: 0, name: '' },
    variants:[],
    images: [],
    collections: []
  }
}

const EditProductContainer = (props) => {
  const [product, setProduct] = useState(emptyProduct())
  const [categories, setCategories] = useState([])
  const [formErrors, setFormErrors] = useState({})
  const [selectedImages, setSelectedImages] = useState(false)
  const [fulfillmentCenters, setFulfillmentCenters] = useState([]);
  const { id } = useParams();
  const history = useHistory();
  const [actionType, setActionType] = useState(id > 0 ? 'Edit' : 'New')

  useEffect( () => {
    const fetchData = async () => {
      try {
        if (id > 0) {
          const productResponse = await ProductApi.getProduct(id)
          setProduct(productResponse.data)
          setFulfillmentCenters(productResponse.data.Store.fulfillmentCenters);
          const categoriesResponse = await CategoryApi.getCategories({ limit: 'all', "store_id": productResponse.data.store_id }, {"dependent-source": global.constants.dependentSource.product})
          setCategories(categoriesResponse.data.data)
        } else {
          setProduct(emptyProduct())
        }
      } catch (e) {
        history.push('/admin/products')
      }
    }

    fetchData()

    if (props.created){
      props.actions.resetCreated()
      props.notifyActions.setSuccessNotification({ message: global?.constants?.response_messages?.products?.duplicate_created })
    }
  }, [])

  const handleBulkImageDeletion = async (_selectedImages) => {
    if (product.id > 0){
      _selectedImages.map((id)=>{
        removeImageFromServer(id)
      })
    } else {
      const prod = product
      prod.images = prod.images.filter( image => !_selectedImages.includes(image.position))
      setProduct({...prod})
    }
    setSelectedImages(true)
  }

  const removeImageFromServer = async (imageId) => {
    const response = await ProductApi.removeProductImage(product?.id, imageId)
    if (response.status === 200){
      const prod = product
      prod.images = prod.images.filter(image => image.id !== imageId )
      setProduct({...prod})
    }
  }

  const addVariant = (options={}) => {
    const prod = product
    let newVariant = emptyVariant()
    newVariant.id = Math.max(...prod.variants.map(v => v._id | v.id), 0) + 1
    newVariant.position = Math.max(...prod.variants.map(v => v.position), 0) + 1
    newVariant._id = newVariant.id
    if (prod.is_bundle) newVariant.price = (prod.price === '' ? 0.0 : prod.price);
    if (!isEmpty(options)) newVariant = { ...newVariant, ...options };
    prod.variants.push(newVariant)
    setProduct({...prod})
    return newVariant
  }

  const updateVariant = variant => {
    const prod = product
    let indexOfVarToUpdate = prod.variants.findIndex(({ id, _id }) => id ? id === variant.id : _id === variant._id )
    prod.variants[indexOfVarToUpdate] = variant
    setProduct({...prod})
  }
  
  const sortVariants = variants => {
    const prod = product   
    prod.variants = variants
    setProduct({...prod})
  }

  const sortImages = (images, draggedImages) => {
    const prod = product
    if (product.id > 0){
      draggedImages.forEach((image) => {
        updateDraggedImages(image)
      })
    }
    prod.images = images
    setProduct({...prod})
  }

  const updateDraggedImages = async (image) => {
    await ProductApi.updateProductImage( product.id, image)
  }

  const addImage = (image) => {
    if (product.id === 0){
      handleNewProductImage(image)
    } else {
      handleExistingProductImage(image)
    }
  }

  const handleExistingProductImage = async (image) => {
    let imageData;
    imageData = new FormData()
    imageData.append('image', image.image_file)
    imageData.append('position', product.images?.reduce((a, b) => a.position > b.position ? a.position : b.position, 0 ) + 1)
    const response = await ProductApi.createProductImage(product?.id, imageData)
    if (response.status === 201){
       const {data} = await ProductApi.getProduct(product.id)
       if (data.images.length > 0){
        data.images.at(-1).image_preview_url = URL.createObjectURL(image.image_file)
       }
       setProduct({...data})
    }
  }

  const handleNewProductImage = (image) => {
    let _image = {}
    _image.id = Math.max(...product.images?.map(v => v.id | v.id), 0) + 1
    _image._id = _image.id
    _image.image_file = image.image_file
    image.image_file && (_image.image_preview_url = URL.createObjectURL(image.image_file))
    _image.position = product.images?.reduce((a, b) => a.position > b.position ? a.position : b.position, 0 ) + 1
    const prod = product
    prod.images.push(_image)
    setProduct({...prod})
  }

  const updateImage = images => {
    setProduct({...product, images: images})
  }

  const updateProductState = event => {
    const field = event.target.name
    let prod = product
    let _formErrors = formErrors
    if (_formErrors.hasOwnProperty(field)) delete(_formErrors[field])
    switch (event.target.type) {
      case 'checkbox':
        if(prod[field] === false) {
          prod[field] = true
        } else if(prod[field] === true) {
          prod[field] = false
        } else {
          prod[field] = false
        }
        break
      case 'file':
        prod[field] = event.target.files[0]
        break
      case 'number':
        prod[field] = parseFloat(event.target.value)
      default:
        prod[field] = event.target.value
    }
    setProduct({...prod})
    setFormErrors(_formErrors)
  }
  
  const formIsValid = () => {
    let _formErrors = formErrors
    let formValid = true
    if (product.name === '') {
      _formErrors.name = `Name can't be blank`
      formValid = false
    }
    if (product.category_id <= 0) {
      _formErrors.category_id = `Category can't be blank`
      formValid = false
    }
    setFormErrors({..._formErrors})
    return formValid
  }
  
  const resetValidationState = () => {
    setFormErrors({})
  }


  const saveProduct = async event => {
    event.preventDefault()
    resetValidationState()
    if (formIsValid()) {
      let images = []
      let formData = new FormData();
      for (const [field, value] of Object.entries(product)) {
        if( field !== 'category') {
          if( field === 'images') {
            value.forEach(image => {
              if ( (image.image_file || image.image_url) && !image._delete ) {
                let imageData;
                if( image.image_file ) {
                  imageData = new FormData()
                  imageData.id = image.id
                  imageData.append('position', image.position)
                  imageData.append('image', image.image_file)
                } else {
                  imageData = image
                }
                images.push(imageData)
              } else {
                images.push(image)
              }
            })
          } else if (field === 'variants'){
            let updatedVariants = value.filter(variant => variant.name !== '');
            for (let i = 0; i < updatedVariants.length; i++) {
              for(let [key, value] of Object.entries(updatedVariants[i])){
                if (!Array.isArray(value)) {
                  formData.append(`${field}[${i}][${key}]`, value);
                } else {
                  formData.append(`${field}[${i}][${key}]`, JSON.stringify(value));
                }
              }
            }
          } else if (value) {
            formData.append(field, value);
          } else if (value === false) {
            // In case of checkbox which is unchecked
            formData.append(field, value);
          } else if (field == 'price') {
            formData.append(field, '');
          } else if (field == 'article_no') {
            formData.append(field, '');
          }
        }
      }
      if(product.id > 0) {
        props.actions.updateProduct(product.id, formData, images)
        props.notifyActions.setSuccessNotification({ message: global.constants.response_messages.products.updated })
      } else {
        createProduct(formData, images)
      }
    }
  }

  const createProduct = async (formData, images) => {
    try {
      const response = await ProductApi.createProduct(formData)
      for (let image of images) {
        if (image._delete) {
          if (image.id > 0) {
            await ProductApi.removeProductImage(response.data.id, image, { "dependent-source": global.constants.dependentSource.product })
          }
        } else {
          await ProductApi.createProductImage(response.data.id, image, { "dependent-source": global.constants.dependentSource.product })
        }
      }
      let _product = response.data
      if (!_product.images) {
        _product.images = []
      }
      setProduct(_product)
      setActionType('Edit')
      props.notifyActions.setSuccessNotification({ message: global.constants.response_messages.products.created })
      props.autocompleteActions.resetProductSearch()
      props.history.push(`/admin/products/edit/${_product.id}`)
    }
    catch(error) {
      props.notifyActions.setErrorNotification({ message: error.response.data })
    }
  }

  const extraActions = () => (
    <Button color="primary" variant="outlined" onClick={ () => { if (window.confirm('It will copy all the data to a new product. Do you want to continue?')) createDuplicate() }}>Duplicate</Button>
  )

  const createDuplicate = () => {
    const { id, collections, images, ...duplicateProductObject } = product;
    duplicateProductObject.name = `Copy of ${duplicateProductObject.name}`
    duplicateProductObject.price == '' && delete duplicateProductObject.price
    duplicateProductObject.freight == '' && delete duplicateProductObject.freight
    props.actions.createProduct(duplicateProductObject, images, true)
  }

  return (
    <div>
      <Grid container>
        <EditPageUpperContainer actionType={actionType} page={'Product'} onSave={saveProduct} />
        <Grid item xs={12} md={12}>
          <EditProduct
            actionType={actionType}
            product={product}
            categories={categories}
            fulfillmentCenters={fulfillmentCenters}
            validationErrors={formErrors}
            newVariant={addVariant}
            newImage={addImage}
            updateVariant={updateVariant}
            sortVariants={sortVariants}
            sortImages={sortImages}
            removeImage={removeImageFromServer}
            handleBulkImageDeletion={handleBulkImageDeletion}
            setSelectedImages={selectedImages}
            updateImage={updateImage}
            onSave={saveProduct}
            onChange={updateProductState}
            onDescriptionChange={desc => setProduct({...product, description: desc})} />
        </Grid>
      </Grid>
    </div>
  )

}

const emptyVariant = () => {
  return { _id: 0, name: '', price: '0.0', article: '', image_path: '', document_path: '', sku_multiple: 0, product_details: [] }
}

EditProductContainer.propTypes = {
  page: PropTypes.number,
}

const mapStateToProps = (state, ownProps) => {
  return {
    created: state.products.created,
    updating: state.products && state.products.updating,
    page: state.products.page_no,
    autocomplete: state.autcomplete,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(productActions, dispatch),
    categoryActions: bindActionCreators(categoryActions, dispatch),
    autocompleteActions: bindActionCreators(autocompleteActions, dispatch),
    notifyActions: bindActionCreators(notificationActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProductContainer)
