import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux'
import { PropTypes } from 'prop-types'
import { Grid, Divider, Button, Typography } from '@mui/material'
import EditStore from '../components/editStore'
import FormFlashMessage from '@core-ui-shared/formFlashMessage'
import { StoreApi } from '../api/storeApi'
import * as notificationActions from '../../../actions/notificationAction'
import BackButton from '@core-ui-shared/BackButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const EditStoreContainer = (props) => {
  const [store, setStore] = useState(emptyStore())
  const [currencies, setCurrencies] = useState([])
  const [urlValid, setUrlValid] = useState(true)
  const [saving, setSaving] = useState(false)
  const [formErrors, setFormErrors] = useState({})

  const fetchStoreAndCurrencies = async () => {
    let storeResponse = await StoreApi.getStore(props.storeId)
    let currenciesResponse = await StoreApi.getCurrencies()
    if(!storeResponse.data?.currencyCode) {
      storeResponse.data.currencyCode = currenciesResponse.data.length > 0 ? currenciesResponse.data[0].isoCode : ''
    }
    setStore({...store, ...storeResponse.data})
    setCurrencies(currenciesResponse.data.data)
  }

  useEffect(() => {
    fetchStoreAndCurrencies()
  }, [props.storeId])

  const updateStoreState = (event) => {
    const field = event.target.name
    let _store = {...store}
    let _formErrors = formErrors
    if (_formErrors.hasOwnProperty(field)) delete(_formErrors[field])
    switch (event.target.type) {
      case 'checkbox':
        if(_store[field] === false) {
          _store[field] = true
        } else if(_store[field] === true) {
          _store[field] = false
        } else {
          _store[field] = false
        }
        break
      case 'file':
        _store[field] = event.target.files[0]
        _store[`new_${field}`] = true
        event.target.files[0] && (_store[`${field}_preview_url`] = URL.createObjectURL(event.target.files[0]))
        break
      default:
        _store[field] = ((field === global.constants.labels.password_protection || field === global.constants.labels.authentication) ? parseInt(event.target.value) : event.target.value)
    }

    if (_store[global.constants.labels.authentication] === parseInt(global.constants.labels.no_authentication_code)) {
      _store[global.constants.labels.enable_account_registration] = false;
    }
    setFormErrors({..._formErrors})
    setStore({..._store})
  }
  
  const validateStoreUrl = async (event) => {
    const store_url = event.target.value
    setStore({...store, store_url: store_url })
    if (store_url.length) {
      this.props.actions.validateStoreUrl({ store_url })
    } else {
      setUrlValid(false)
    }
  }

  const formIsValid = () => {
    let _formErrors = formErrors
    let _formValid = true
    if (store.store_url === '') {
      _formErrors.store_url = `Store url can't be blank`
      _formValid = false
    } else if (!urlValid) {
      _formErrors.store_url = `Store url is already taken`
      _formValid = false
    }
    setFormErrors(_formErrors)
    return _formValid
  }

  const saveStore = async (event) => {
    event.preventDefault()
    setSaving(true)
    if (formIsValid()) {
      let formData = new FormData()
      for (const [field, value] of Object.entries(store)) {
        if( value !== null ) {
          formData.append(field, value)
        }
      }
      try {
        await StoreApi.updateStore(store.id, formData)
        props.notifyActions.setSuccessNotification({ message: global.constants.response_messages.store_updated_successfully })
      }
      catch(error) {
        props.notifyActions.setErrorNotification({ message: error.response.data })
      }
      setSaving(false)
    }
  }

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <Typography variant="h3">Store Settings</Typography>
      </Grid>
      <Grid container item xs={12} md={6} lg={6} xl={6} justifyContent="end" spacing={1}>
        <Grid item>
          <Button
            variant='outlined'
            href={`${process.env.REACT_APP_STORE_ADMIN_LOGIN_URL}/store-auth/${localStorage.getItem("auth_token")}/true/${store.id}`}
            target="blank"
          >
            Admin Portal
            <OpenInNewIcon sx={{marginLeft: '3px', fontSize: '14px'}} />
          </Button>
        </Grid>
        <Grid item><BackButton /></Grid>
        <Grid item>
          <Button
            disabled={saving}
            onClick={saveStore}
            variant="contained"
            color="primary">
            {saving ? 'Saving...' : 'Save'}
          </Button>
        </Grid>
      </Grid>
      <Divider width="100%" sx={{paddingTop: '1%'}} />
      {Object.keys(formErrors).length > 0 && (
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <FormFlashMessage formErrors={formErrors} />
        </Grid>
      )}
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <EditStore
          onFooterChange={val => setStore({...store, footer_text: val})}
          store={store}
          currencies={currencies}
          urlValid={urlValid}
          validateStoreUrl={validateStoreUrl}
          validationErrors={formErrors}
          onSave={saveStore}
          removeLogo={() => setStore({...store, _delete_logo: true})}
          removeFavicon={() => setStore({...store, _delete_favicon: true})}
          onChange={updateStoreState}/>
      </Grid>
      <Divider width="100%" sx={{marginTop: '2%', marginBottom: '2%'}} />
      <Button
        disabled={saving}
        onClick={saveStore}
        variant="contained"
        color="primary">
        {saving ? 'Saving...' : 'Save'}
      </Button>
    </Grid>
  )
}

const emptyStore = () => {
  return {
    name: '', password_protection: 0, authentication: 0, enable_account_registration: false, access_key: '', google_index: false,
    title_tag: '', credit_card: false, invoice: false, free: false, order_email: '',
    logo: '', footer_text: '', primary_color: '', secondary_color: '', store_url: '',
    primary_domain: '', unique: true, is_live: false, same_invoice_address: true
  }
}

EditStoreContainer.propTypes = {  
  storeId: PropTypes.number.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const storeId = Number(ownProps.match.params.id)
  
  return { storeId }
}

const mapDispatchToProps = dispatch => {
  return {
    notifyActions: bindActionCreators(notificationActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditStoreContainer)
