import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { StoreContainer, EditStoreContainer, ShowStoreContainer } from '../modules/store';

const Stores = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={StoreContainer} />
    <Route path={`${match.path}/:id/edit`} component={EditStoreContainer} />
    <Route path={`${match.path}/:id`} component={ShowStoreContainer} />
  </Switch>
)

Stores.propTypes = {  
  match: PropTypes.object,
}

export default Stores