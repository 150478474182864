import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux'
import { PropTypes } from 'prop-types'
import { Grid, Divider, Button, Typography } from '@mui/material'
import FormFlashMessage from '../../components/Helpers/formFlashMessage'
import * as notificationActions from '../../actions/notificationAction'
import BackButton from '../../components/Shared/BackButton'
import EditUser from '../../components/User/editUser'
import { UserApi } from '../../api/UserApi'

const EditUserContainer = (props) => {
  const [user, setUser] = useState(emptyUser())
  const [saving, setSaving] = useState(false)
  const [formErrors, setFormErrors] = useState({})

  const fetchUser = async () => {
    const response = await UserApi.getUser(props.userId)
    setUser(response.data)
  }

  useEffect(() => {
    if (props.userId) {
      fetchUser()
    }
  }, [props.userId])

  const updateUserState = (event) => {
    const field = event.target.name
    let _user = {...user}
    let _formErrors = formErrors
    if (_formErrors.hasOwnProperty(field)) delete(_formErrors[field])

    switch (event.target.type) {
      default:
        _user[field] =  event.target.value
    }
    setFormErrors({..._formErrors})
    setUser({..._user})
  }

  const formIsValid = () => {
    let _formErrors = formErrors
    let _formValid = true
    if (!user.name) {
      _formErrors.name = 'Name cannot be blank'
      _formValid = false
    } else if (!user.email) {
      _formErrors.email = 'Email cannot be blank'
      _formValid = false
    }
    setFormErrors(_formErrors)
    return _formValid
  }

  const saveUser = async (event) => {
    event.preventDefault()
    setSaving(true)
    if (formIsValid()) {
      try {
        if (user.id) {
          await UserApi.updateUser(user.id, user)
          // redirect
          // props.notifyActions.setSuccessNotification({ message: global.constants.response_messages.user_updated_successfully })
        }
        else {
          await UserApi.createUser(user)
          props.notifyActions.setSuccessNotification({ message: global.constants.response_messages.user_created_successfully })
        }
        
      }
      catch(error) {
        props.notifyActions.setErrorNotification({ message: error.response.data })
      }
      setSaving(false)
    }
  }

  console.log(user)



  const updatePermissions = (store, isDeleted) => {
    if (isDeleted) {
      setUser({
        ...user,
        meta_store_permissions: user.meta_store_permissions.map(permission => (permission.store_id === store ? { ...permission, _deleted: true } : permission))
      })
    }
    else {
      setUser({ ...user, meta_store_permissions: [ ...user.meta_store_permissions, { store_id: store.id, store: { name: store.name } } ] })
    }
  }


  return (
    <Grid container>
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <Typography variant="h3">{user.id ? 'Edit' : 'New'} User</Typography>
      </Grid>
      <Grid container item xs={12} md={6} lg={6} xl={6} justifyContent="end" spacing={1}>
        <Grid item><BackButton /></Grid>
        <Grid item>
          <Button
            disabled={saving}
            onClick={saveUser}
            variant="contained"
            color="primary">
            {saving ? 'Saving...' : 'Save'}
          </Button>
        </Grid>
      </Grid>
      <Divider width={'100%'} sx={{ marginBottom: '1rem', marginTop: '1rem' }} />
      {Object.keys(formErrors).length > 0 && (
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <FormFlashMessage formErrors={formErrors} />
        </Grid>
      )}
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <EditUser
          user={user}
          validationErrors={formErrors}
          onChange={updateUserState}
          updatePermissions={updatePermissions}
        />
      </Grid>
      <Divider width="100%" sx={{marginTop: '2%', marginBottom: '2%'}} />
      <Button
        disabled={saving}
        onClick={saveUser}
        variant="contained"
        color="primary">
        {saving ? 'Saving...' : 'Save'}
      </Button>
    </Grid>
  )
}

const emptyUser = () => {
  return {
    name: '', email: '', role: '', password: '', meta_store_permissions: []
  }
}


EditUserContainer.propTypes = {  
  userId: PropTypes.number.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const userId = Number(ownProps.match.params.id)
  
  return { userId }
}

const mapDispatchToProps = dispatch => {
  return {
    notifyActions: bindActionCreators(notificationActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserContainer)
