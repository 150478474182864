import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setSuccessNotification, setErrorNotification } from '../../actions/notificationAction';
import PropTypes from 'prop-types'
import OrderItems from './orderItems'
import { OrderApi } from '../../api/orderApi'
import Summary from './summary'
import { Grid, Button, Divider, Paper, TextField, MenuItem } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import { OrderNotes, AddOrderNote } from '../../shared-components';
import { OrderTrackingDetails } from '../../shared-components';
import useTranslation from '../../hooks/useTranslation'

const fetchDownloadUrl = id => {
  OrderApi.getOrderDeliveryNote(id)
    .then(res => {
      if (res.status===200) {
        let a = document.createElement('a')
        a.href = res.data.url
        a.download = 'employees.json'
        a.click()
      }
    })
}

const fetchDownloadInvoiceUrl = id => {
  OrderApi.getOrderInvoice(id)
    .then(res => {
      if (res.status===200) {
        let invoice = document.createElement('a')
        invoice.href = res.data.url
        invoice.download = 'employees.json'
        invoice.click()
      }
    })
}

const ShowOrder = props => {
  const [notes, setNotes] = useState([]);
  const [user, setUser] = useState(null);
  const [totalNotesCount, setTotalNotesCount] = useState(0);
  const [trackingData, setTrackingData] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation()

  useEffect(() => {
    loadNotes(props.order.id, 1);
    if (props.order.order_trackings && props.order.order_trackings.length > 0) {
      setTrackingData(props.order.order_trackings);
    }
  }, [props.order.id, props.order.order_trackings]);

  const loadNotes = (orderId, pageNumber) => {
    return OrderApi.getOrderNotes(orderId, pageNumber).then(response => {
      setUser(response.data.user_id)
      if (pageNumber === 1) {
        setNotes(response.data.notes);
        setTotalNotesCount(response.data.totalNotesCount);
      } else {
        setNotes(prevNotes => [...prevNotes, ...response.data.notes]);
      }
      return response.data;
    });
  };

  const addNote = (orderId, note) => {
    return OrderApi.addOrderNote(orderId, { note });
  };

  const handleNoteAdded = newNote => {
    setNotes(prevNotes => [newNote, ...prevNotes]);
  };

  const editNote = (orderId, noteId, note) => {
    return OrderApi.editOrderNote(orderId, noteId, note);
  };

  const deleteNote = (orderId, noteId) => {
    return OrderApi.deleteOrderNote(orderId, noteId);
  };


  const handleSaveTracking = async (updatedTrackingData, sendEmail = false) => {
    if (updatedTrackingData.trackingUrl === '') {
      dispatch(setErrorNotification({ message: 'Please add tracking URL to save information' }));
      return;
    }
  
    const bodyData = { orderTrackingData: updatedTrackingData, sendEmail: sendEmail };
    const response = await OrderApi.updateOrderTracking(props.order.id, bodyData);
  
    if (response.status === 200) {
      setTrackingData(response.data.order_trackings);
      if (sendEmail) {
        dispatch(setSuccessNotification({ message: global.constants.response_messages.order_tracking_email_success }));
      } else {
        dispatch(setSuccessNotification({ message: global.constants.response_messages.order_tracking_success }));
      }
    } else {
      dispatch(setErrorNotification({ message: 'Failed to update tracking information. Please try again.' }));
      throw new Error('Failed to update tracking information');
    }
  };

  const handleDeleteTracking = async (trackingId) => {
    try {
      const response = await OrderApi.deleteOrderTracking(trackingId);
      if (response.status === 200) {
        setTrackingData((prevTrackingData) =>
          prevTrackingData.filter((tracking) => tracking.id !== trackingId)
        );
        dispatch(setSuccessNotification({ message: 'Tracking deleted successfully.' }));
      } else {
        throw new Error('Failed to delete tracking.');
      }
    } catch (error) {
      dispatch(setErrorNotification({ message: 'Failed to delete tracking. Please try again.' }));
      console.error(error);
    }
  };

  let customerName
  if(props.order.user) {
    customerName = <a target="_blank" href={`/admin/users/edit/${props.order.user_id}`} rel="noreferrer">{props.order.user.name}</a>
  }
  else if(props.order.guest_checkout) {
    customerName = <a target="_blank" href={`/admin/users/edit/${props.order.guest_checkout.user_id}`} rel="noreferrer">{`${props.order.guest_checkout.name} (guest checkout)`}</a>
  }
  else {
    customerName = 'Guest Checkout'
  }

  const styles = {
    paper: {
      paddingRight: 0,
      width: '100%',
      border: '1px solid lightgrey'
    },
    actionBtn: {
      marginLeft: '0.75rem',
      borderColor: 'lightgrey'
    }
  }
  return (
    <Grid container>
      <Grid container item={true} xs={12} md={12} lg={12} xl={12} alignItems="center">
        <Grid item xs={12} md={6} lg={6} xl={6}>
          <h1>Order - #{props.order.id}</h1>
        </Grid>
        <Grid item xs={12} md={6} lg={6} xl={6} textAlign="end">
          <Button sx={styles.actionBtn} variant="outlined" href={`/admin/orders/${props.order.id}/edit`}>
            <EditIcon />&nbsp;Edit Order
          </Button>
          <Button sx={styles.actionBtn} variant="outlined" onClick={() =>fetchDownloadUrl(props.order.id)}>
            <DownloadIcon />&nbsp;Delivery Note
          </Button>
          <Button sx={styles.actionBtn} variant="outlined" onClick={() => fetchDownloadInvoiceUrl(props.order.id)}>
            <DownloadIcon />&nbsp;PDF
          </Button>
        </Grid>
      </Grid>
      <Divider width="100%" />
      <br/>
      <Grid container spacing={3}>
        <Grid container item xs={12} md={8} lg={8} xl={8} direction="column">
          <Paper sx={styles.paper}>
            <OrderItems orderFulfillments={props.order.orderFulfillments} order_items={props.order.order_items} storeCurrency={props.storeCurrency} order={{...props.order}} />
          </Paper>
          <br/>
          <Summary
            subtotal={props.subtotal}
            order={props.order}
            freeDelivery={props.freeDelivery}
            storeCurrency={props.storeCurrency}
            pricesIncludeVat={props.order.store && props.order.store.prices_include_vat} />
          <Paper sx={{ padding: '1rem', marginTop: '1rem' }}>
            <Grid item xs={12} md={12} lg={12} xl={12} sx={styles.paddingLeft}>
              <h3>Notes</h3>
            </Grid>
            <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <AddOrderNote
                  orderId={props.order.id}
                  addNote={addNote}
                  onNoteAdded={handleNoteAdded}
                  label='Quick Note'
                  button_text='Save'
                  helperText="Only you and other staff can see comments."
                />
              </Grid>
              <Grid item xs={12}>
                <OrderNotes
                  orderId={props.order.id}
                  initialNotes={notes}
                  loadNotes={loadNotes}
                  editNote={editNote}
                  deleteNote={deleteNote}
                  userId={user}
                  button_text='Save'
                  totalNotesCount={totalNotesCount}
                />
              </Grid>
          </Paper>
        </Grid>
        <Grid container item xs={12} md={4} lg={4} xl={4} direction="column">
          <Paper sx={styles.paper}>
            <Grid container item={true} spacing={3} sx={{padding: "3%"}}>
              <Grid item xs={12} md={12} lg={12} xl={12} sx={{paddingLeft: "3%"}}>
                <h4>Status</h4>
              </Grid>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <TextField
                  name="payment_status"
                  label="Payment"
                  value={props.order.payment_status || ''}
                  disabled={props.order.payment_status === 'paid'}
                  onChange={props.onChange}
                  placeholder="Select"
                  variant='outlined'
                  select
                  fullWidth>
                  <MenuItem value=''>Select</MenuItem>
                  <MenuItem value='unpaid'>Unpaid</MenuItem>
                  <MenuItem value='paid'>Paid</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <TextField
                  name="payment_method"
                  label="Method"
                  value={props.order.payment_method_value?.toLowerCase() || ''}
                  onChange={props.onChange}
                  placeholder="Select"
                  variant='outlined'
                  select
                  fullWidth>
                  <MenuItem value=''>Select</MenuItem>
                  <MenuItem value='credit_card'>Credit Card</MenuItem>
                  <MenuItem value='discount_payment'>Discount Voucher</MenuItem>
                  <MenuItem value='free'>Free</MenuItem>
                  <MenuItem value='invoice'>Invoice</MenuItem>
                  <MenuItem value='user_credit'>Account Balance</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <TextField
                  name="status"
                  label="Order Status"
                  value={props.order.status || ''}
                  onChange={props.onChange}
                  placeholder="Select"
                  variant='outlined'
                  select
                  fullWidth>
                  <MenuItem value=''>Select</MenuItem>
                  <MenuItem value="processing">Processing</MenuItem>
                  <MenuItem value="ready">Ready To Ship</MenuItem>
                  <MenuItem value="shipped">Shipped</MenuItem>
                  <MenuItem value="complete">Complete</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Paper>
          <br />
          {props.paymentDetails && (
            <>
              <Paper sx={styles.paper}>
                <Grid container spacing={3} sx={{padding: "3%"}}>
                  <Grid item xs={12} sx={{paddingLeft: "3%"}}>
                    <h4>Payment Details</h4>
                  </Grid>
                  { props.paymentDetails.last_payment_error && (
                    <Grid item xs={12}>
                      Payment failed!
                      <div>Reason: <code>{props.paymentDetails.last_payment_error.message}</code></div>
                      <div>Code: <code>{props.paymentDetails.last_payment_error.code}</code></div>
                    </Grid>
                  )}
                  { props.paymentDetails.paymentMethods.map(paymentMethod => (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={8}>
                          Paid with { paymentMethod.type=='card' ? `${paymentMethod.brand} card` : paymentMethod.name}
                          { paymentMethod.last4 && <div>{`**** **** **** ${paymentMethod.last4}`}</div>}
                        </Grid>
                        <Grid item xs={4} textAlign="right">{`${paymentMethod.amount_captured} ${props.paymentDetails.currency}`}</Grid>
                        </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
              <br />
            </>
          )}
          {props.order.fulfillment_message && (
            <Paper sx={styles.paper} style={{ marginBottom: '1rem' }}>
              <Grid item sx={{ padding: '3%' }}>
                <h4>{t('labels.fulfillment_message')}</h4>
                <code>{props.order.fulfillment_message}</code>
              </Grid>
            </Paper>
          )}
          <Paper sx={styles.paper}>
            <Grid item sx={{padding: '3%'}}>
              <h4>Customer</h4>
              <p>{customerName}</p>
              <label>Contact</label>
              <p>{props.order.name}</p>
              <p>{props.order.phone}</p>
              <label>Shipping Address</label>
              <p>{props.order.shipping_name}</p>
              {props.order.shipping_company_name && <p>{props.order.shipping_company_name}</p>}
              {props.order.shipping_company_name && <p>{props.order.shipping_organisation_number}</p>}
              <p>{props.order.email}</p>
              <p>{props.order.shipping_address_one}, {props.order.shipping_address_two}</p>
              <p>{props.order.shipping_postcode}, {props.order.shipping_city}, {props.order.shipping_country}</p>
              { props.order.delivery_instructions ? <p><b>Delivery Instructions:</b> {props.order.delivery_instructions}</p> : null }
              <label>Invoice Address</label>
              <p>{props.order.invoice_name}</p>
              {props.order.invoice_company_name && <p>{props.order.invoice_company_name}</p>}
              {props.order.invoice_company_name && <p>{props.order.invoice_organisation_number}</p>}
              <p>{props.order.invoice_email}</p>
              <p>{props.order.invoice_address_one}, {props.order.invoice_address_two}</p>
              <p>{props.order.invoice_postcode}, {props.order.invoice_city}, {props.order.invoice_country}</p>
              { props.order.invoice_reference ? <p><b>Reference:</b> {props.order.invoice_reference}</p> : null }
              { props.order.invoice_instructions ? <p><b>Billing Instructions:</b> {props.order.invoice_instructions}</p> : null }
            </Grid>
          </Paper>
          <br />
          <Paper sx={styles.paper}>
            <Grid item sx={{padding: '3%'}}>
              <OrderTrackingDetails
                  trackingDataList={trackingData || []}
                  onSave={(updatedTrackingData, emailSent) => handleSaveTracking(updatedTrackingData, emailSent)}
                  onDelete={handleDeleteTracking}
                />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

ShowOrder.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    invoice_number: PropTypes.number,
    shipping_name: PropTypes.string,
    shipping_company_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    shipping_address_one: PropTypes.string,
    shipping_address_two: PropTypes.string,
    shipping_postcode: PropTypes.string,
    shipping_city: PropTypes.string,
    shipping_country: PropTypes.string,
    invoice_name: PropTypes.string,
    invoice_company_name: PropTypes.string,
    invoice_address_one: PropTypes.string,
    invoice_address_two: PropTypes.string,
    invoice_postcode: PropTypes.string,
    invoice_city: PropTypes.string,
    invoice_country: PropTypes.string,
    invoice_instructions: PropTypes.string,
    invoice_reference: PropTypes.string,
    payment_status: PropTypes.string,
    payment_method: PropTypes.string,
    payment_method_value: PropTypes.string,
    paid_at: PropTypes.string,
    status: PropTypes.string,
    delivery_instructions: PropTypes.string,
    order_items: PropTypes.array.isRequired,
    discount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    freight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    net_total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    store: PropTypes.object,
    coupon: PropTypes.object,
    order_discount: PropTypes.object,
    use_account_balance: PropTypes.bool,
    balance_used: PropTypes.string,
    user: PropTypes.object,
    user_id: PropTypes.number,
    shipping_organisation_number: PropTypes.string,
    name: PropTypes.string,
    invoice_email: PropTypes.string,
    invoice_organisation_number: PropTypes.string,
    guest_checkout: PropTypes.object
  }),
  paymentDetails: PropTypes.object,
  subtotal: PropTypes.number.isRequired,
  freeDelivery: PropTypes.bool,
  onChange: PropTypes.func,
  storeCurrency: PropTypes.object.isRequired
}

export default ShowOrder
