import { StoreApi } from '../api/storeApi'

export const GET_CURRENCIES = '[Mstore] GET_CURRENCIES'
export const GET_CURRENCIES_SUCCESS = '[Mstore] GET_CURRENCIES_SUCCESS'
export const GET_CURRENCIES_ERROR = '[Mstore] GET_CURRENCIES_ERROR'

export const GET_STORE = '[Mstore] GET_STORE'
export const GET_STORE_SUCCESS = '[Mstore] GET_STORE_SUCCESS'
export const GET_STORE_ERROR = '[Mstore] GET_STORE_ERROR'

// Update
export const UPDATE_STORE = '[Mstore] UPDATE_STORE'
export const UPDATE_STORE_SUCCESS = '[Mstore] UPDATE_STORE_SUCCESS'
export const UPDATE_STORE_ERROR = '[Mstore] UPDATE_STORE_ERROR'

// Store URL
export const VALIDATE_STORE_URL = '[Mstore] VALIDATE_STORE_URL'
export const VALIDATE_STORE_URL_SUCCESS = '[Mstore] VALIDATE_STORE_URL_SUCCESS'
export const VALIDATE_STORE_URL_ERROR = '[Mstore] VALIDATE_STORE_URL_ERROR'

// Get currecies list
export const getCurrencies = () => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_CURRENCIES
    })

    StoreApi.getCurrencies().then(res => {
      dispatch(getCurrenciesSuccess(res.data))
    })
  }
}

export const getCurrenciesSuccess = store => {
  return {
    type: GET_CURRENCIES_SUCCESS,
    payload: store
  }
}

export const getCurrenciesFailure = error => {
  return {
    type: GET_CURRENCIES_ERROR,
    payload: error
  }
}

export const getStore = () => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_STORE
    })

    StoreApi.getStore().then(res => {
      dispatch(getStoreSuccess(res.data))
    })
  }
}

export const getStoreSuccess = store => {
  return {
    type: GET_STORE_SUCCESS,
    payload: store
  }
}

export const getStoreFailure = error => {
  return {
    type: GET_STORE_ERROR,
    payload: error
  }
}

//Update
export const updateStore = store => {
  return (dispatch, getState) => {
    //Multiple actions can be sent using the Redux-Thunk middleware
    dispatch({
      type: UPDATE_STORE,
      payload: store
    })
    StoreApi.updateStore(store).then(res => {
      dispatch(updateStoreSuccess(res.data))
    })
  }
}

export const updateStoreSuccess = store => {
  return {
    type: UPDATE_STORE_SUCCESS,
    payload: store
  }
}

//Store URL
export const validateStoreUrl = payload => {
  return (dispatch, getState) => {
    //Multiple actions can be sent using the Redux-Thunk middleware
    dispatch({
      type: VALIDATE_STORE_URL,
      payload: payload
    })
    StoreApi.validateStoreUrl(payload).then(res => {
      dispatch(validateStoreUrlSuccess(res.data))
    })
  }
}

export const validateStoreUrlSuccess = payload => {
  return {
    type: VALIDATE_STORE_URL_SUCCESS,
    payload: payload
  }
}
