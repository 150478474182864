import { ProductApi } from "../api/productApi"

// Create
export const CREATE_PRODUCT = '[Mstore] CREATE_PRODUCT'
export const CREATE_PRODUCT_SUCCESS = '[Mstore] CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_ERROR = '[Mstore] CREATE_PRODUCT_ERROR'


// Read
export const GET_PRODUCTS = '[Mstore] GET_PRODUCTS'
export const GET_PRODUCTS_SUCCESS = '[Mstore] GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_ERROR = '[Mstore] GET_PRODUCTS_ERROR'

// Get
export const GET_PRODUCT = '[Mstore] GET_PRODUCT'
export const GET_PRODUCT_SUCCESS = '[Mstore] GET_PRODUCT_SUCCESS'
export const GET_PRODUCT_ERROR = '[Mstore] GET_PRODUCT_ERROR'

// Search
export const SEARCH_PRODUCT = '[Mstore] SEARCH_PRODUCT'
export const SEARCH_PRODUCT_SUCCESS = '[Mstore] SEARCH_PRODUCT_SUCCESS'
export const SEARCH_PRODUCT_ERROR = '[Mstore] SEARCH_PRODUCT_ERROR'

// Update
export const UPDATE_PRODUCT = '[Mstore] UPDATE_PRODUCT'
export const UPDATE_PRODUCT_SUCCESS = '[Mstore] UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_ERROR = '[Mstore] UPDATE_PRODUCT_ERROR'

// Delete
export const DELETE_PRODUCT = '[Mstore] DELETE_PRODUCT'
export const DELETE_PRODUCT_SUCCESS = '[Mstore] DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_ERROR = '[Mstore] DELETE_PRODUCT_ERROR'

export const RESET_PRODUCT_UPDATED = '[Mstore] RESET_PRODUCT_UPDATED'
export const RESET_PRODUCT_CREATED = '[Mstore] RESET_PRODUCT_CREATED'

// Create
export const createProduct = (product, productImages, duplicateProduct = false) => {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_PRODUCT
    })

    ProductApi.createProduct(product).then( async (res) => {
      dispatch(createProductSuccess(res.data))
      for (let image of productImages) {
        if (image._delete) {
          if (image.id > 0) {
            ProductApi.removeProductImage(res.data.id, image, { "dependent-source": global.constants.dependentSource.product })
          }
        } else {
          duplicateProduct && (image.image_url = image.image)
          await ProductApi.createProductImage(res.data.id, image, { "dependent-source": global.constants.dependentSource.product })
        }
      }
      duplicateProduct && ( window.location.href = `/admin/products/edit/${res.data.id}`)
    })
    .catch(error => {
      dispatch(createProductFailure(error))
    })
  }
}

export const createProductSuccess = product => {
  return {
    type: CREATE_PRODUCT_SUCCESS,
    payload: product
  }
}

export const createProductFailure = error => {
  return {
    type: CREATE_PRODUCT_ERROR,
    payload: error
  }
}

//Read
export const getProducts = (params) => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_PRODUCTS
    })

    ProductApi.getProducts(params).then(res => {
      dispatch(getProductsSuccess(res.data))
    })
    .catch(error => {
      dispatch(getProductsFailure(error))
    })
  }
}

export const getProductsSuccess = data => {
  return {
    type: GET_PRODUCTS_SUCCESS,
    payload: data
  }
}

export const getProductsFailure = error => {
  return {
    type: GET_PRODUCTS_ERROR,
    payload: error
  }
}

//Get
export const getProduct = id => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_PRODUCT
    })

    ProductApi.getProduct(id).then(res => {
      dispatch(getProductSuccess(res.data))
    })
  }
}

export const getProductSuccess = product => {
  return {
    type: GET_PRODUCT_SUCCESS,
    payload: product
  }
}

export const getProductFailure = error => {
  return {
    type: GET_PRODUCT_ERROR,
    payload: error
  }
}

//Update
export const updateProduct = (id, product, productImages) => {
  return (dispatch, getState) => {
    //Multiple actions can be sent usign the Redux-Thunk middleware
    dispatch({
      type: UPDATE_PRODUCT,
      payload: product
    })
    ProductApi.updateProduct(id, product).then(res => {
      dispatch(updateProductSuccess(res.data))
      productImages.forEach(image => {
        if (image._delete) {
          if (!image._id) {
            ProductApi.removeProductImage(res.data.id, image)
          }
        } else {
          if (image._id) {
            // TODO: Find a way to update existing image
            ProductApi.createProductImage(res.data.id, image) 
          } else {
            ProductApi.updateProductImage(res.data.id, image)
          }
        }
      })
    })
  }
}

export const updateProductSuccess = product => {
  return {
    type: UPDATE_PRODUCT_SUCCESS,
    payload: product
  }
}


//Delete
export const deleteProduct = product => {
  return (dispatch, getState) => {
    dispatch({
      type: DELETE_PRODUCT,
      payload: product
    })

    ProductApi.removeProduct(product).then(res => {
      if (res.status === 204) {
        dispatch(deleteProductSuccess(product))
      }
    })
  }
}

export const deleteProductSuccess = product => {
  return {
    type: DELETE_PRODUCT_SUCCESS,
    payload: product
  }
}

export const resetCreated = () => {
  return {
    type: RESET_PRODUCT_CREATED
  }
}

export const resetUpdated= () => {
  return {
    type: RESET_PRODUCT_UPDATED,
  }
}
