import {setUserPreference} from "./localStorageHelper/localStorageMethods";

export const getSerialNumber = (pageNo, perPage, index) => {
  !pageNo && (pageNo = 1)
  return (((pageNo - 1) * perPage) + index) + 1
}

export const changePage = (event, page_no, setPage) => {
  setPage(page_no + 1)
}

export const handleChangeRowsPerPage = (event, setPage, setPerPage, preferenceType, pageType) => {
  setUserPreference(preferenceType, pageType, event.target.value)
  setPage(1)
  setPerPage(event.target.value)
}
