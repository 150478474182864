import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TableCell, TableRow, IconButton } from '@mui/material';
import ListingThumbnail from '@core-ui-shared/ListingThumbnail'
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';

const Store = (props) => {
  return (
    <TableRow key={props.id}>
      <TableCell component="th" scope="row">
        <Link to={`/admin/stores/${props.id}`}>{props.index}</Link>
      </TableCell>
      <ListingThumbnail image={props.logo} />
      <TableCell component="th" scope="row">
        <Link to={`/admin/stores/${props.id}`}>{props.name}</Link>
      </TableCell>
      <TableCell component="th" scope="row">{props.company_name}</TableCell>
      <TableCell>{props.is_live ? '✔' : ''}</TableCell>
      <TableCell>
        {props.deletedAt ? (
          <IconButton color="primary" size="small" onClick={() => props.onRestore(props.id)}>
            <RestoreIcon />
          </IconButton>
        ) : (
          <IconButton color="error" size="small" onClick={() => props.onDelete(props.id)}>
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

Store.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  company_name: PropTypes.string,
  is_live: PropTypes.bool,
  logo: PropTypes.string,
  index: PropTypes.number,
  onDelete: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired
};

export default Store;
