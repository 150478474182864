import React from 'react'
import TablePagination from '@mui/material/TablePagination'
import PropTypes from 'prop-types'

const perPageOptions = [10, 20, 50, 100]

const styles = {
  display: 'inline-block',
  '& 	.MuiTablePagination-root': {
    display: 'inline-block'
  },
  '& 	.MuiTablePagination-displayedRows': {
    fontSize: '1.0rem',
    marginBottom: '0'
  },
  '& .MuiTablePagination-selectLabel': {
    fontSize: '1.0rem',
    marginBottom: '0'
  },
  '& .MuiTablePagination-select': {
    fontSize: '1.0rem',
    borderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderStyle: 'solid',
    borderRadius: '4px',
    paddingBottom: '0'
  }
}

const calcRowsPerPage = (rowsPerPage, rowsControl) => {
  if(!rowsControl || perPageOptions.includes(rowsPerPage)){
    return rowsPerPage
  }
  return perPageOptions[0]
}

const CustomTablePagination = ({ count, page_no, onPageChange, rowsPerPage, handleChangeRowsPerPage, rowsControl }) => (
  <TablePagination
    component="div"
    count={count}
    page={page_no - 1}
    onPageChange={onPageChange}
    rowsPerPage={calcRowsPerPage(rowsPerPage, rowsControl)}
    showFirstButton
    showLastButton
    onRowsPerPageChange={handleChangeRowsPerPage}
    rowsPerPageOptions={rowsControl ? perPageOptions : []}
    sx={styles} />
)

CustomTablePagination.propTypes = {
  count: PropTypes.number,
  page_no: PropTypes.number,
  onPageChange: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  rowsControl: PropTypes.bool
}

export default CustomTablePagination