import * as errorActions from '../actions/errorActions'
import initialState from './initialState'

const errorReducer = (state = initialState.errors, action) => {
  let errors = []
  // Redux initialize actions type: "@@redux/INIT" doesn't have payload so it will give error when payload is undefined
  if(action.payload) {
    errors = action.payload.errors
  }
  if(action.type === errorActions.HIDE_ERROR) {
    return {
      errors: [],
      isOpen: false,
    }
  } else if (errors && errors.length) {
    return {
      errors: errors,
      isOpen: true,
    }
  }

  return state
}
export default errorReducer
