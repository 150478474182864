import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ColorPicker from '@core-ui-shared/ColorPicker'
import { Grid, Paper, Divider, TextField, InputAdornment } from '@mui/material'
import useTranslation from '../../../hooks/useTranslation'

const initialColors = {
  text: '#fff',
  bg: '#000'
}
export default function FooterSettings (props) {

  const [bgColorPicker, setBgColorPicker] = useState(false)
  const [textColorPicker, setTextColorPicker] = useState(false)
  const { t } = useTranslation()

  return (
    <Grid container item xs={12} sx={{paddingTop: '2%'}}>
      <Paper sx={{width: '100%'}}>
        <Grid container>
          <Grid item xs={12} sx={{padding: '1%', backgroundColor: '#f5f5f5'}}>
            <h4>Footer Settings</h4>
          </Grid>
          <Divider width="100%" />
          <Grid container spacing={2} sx={{padding: '2%'}}>
            <Grid container direction="column" spacing={2} item xs={12} md={6}>
              <Grid item>
                <TextField
                  label="Text Color"
                  name="footer_text_color"
                  value={props.store.footer_text_color || initialColors.text}
                  placeholder="#fff"
                  onChange={props.onChange}
                  onFocus={() => setTextColorPicker(true)}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      paddingLeft: 0
                    },
                    '& label': {
                      marginLeft: '22px',
                    },
                    '& fieldset': {
                      paddingLeft: '32px',
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment  
                        sx={{
                          backgroundColor: props.store.footer_text_color || initialColors.text,
                          padding: '27.5px 14px',
                          borderTopLeftRadius: (theme) =>
                            theme.shape.borderRadius + 'px',
                          borderBottomLeftRadius: (theme) =>
                            theme.shape.borderRadius + 'px'
                        }}
                        position="start">
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        {textColorPicker ? (
                          <ColorPicker right="50%" color={props.store.footer_text_color} onChangeComplete={color => props.onChange({target: {name: 'footer_text_color', value: color.hex}})} onClose={() => setTextColorPicker(false)} />
                        ) : (null)
                        }
                      </InputAdornment>
                    ),
                  }} />
              </Grid>
              <Grid item>
                <TextField
                  label="Background Color"
                  name="footer_bg_color"
                  value={props.store.footer_bg_color || initialColors.bg}
                  placeholder="#000"
                  onChange={props.onChange}
                  onFocus={() => setBgColorPicker(true)}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      paddingLeft: 0
                    },
                    '& label': {
                      marginLeft: '22px',
                    },
                    '& fieldset': {
                      paddingLeft: '32px',
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment  
                        sx={{
                          backgroundColor: props.store.footer_bg_color || initialColors.bg,
                          padding: '27.5px 14px',
                          borderTopLeftRadius: (theme) =>
                            theme.shape.borderRadius + 'px',
                          borderBottomLeftRadius: (theme) =>
                            theme.shape.borderRadius + 'px'
                        }}
                        position="start">
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        {bgColorPicker ? (
                          <ColorPicker right="50%" color={props.store.footer_bg_color} onChangeComplete={color => props.onChange({target: {name: 'footer_bg_color', value: color.hex}})} onClose={() => setBgColorPicker(false)} />
                        ) : (null)
                        }
                      </InputAdornment>
                    ),
                  }} />
              </Grid>
            </Grid>
            <Grid container direction="column" item xs={12} md={6}>
              <Grid item>
                <label>{t('labels.footer_settings.footer_colors')}</label>
              </Grid>
              <Grid container sx={{padding: '15px',backgroundColor: props.store.footer_bg_color, color: props.store.footer_text_color}}>
                <Grid item>
                  {t('labels.footer_settings.footer_preview_text')}
                </Grid>
                <Grid container sx={{paddingTop: '5%'}}>
                  <Grid item container direction="column" spacing={2} xs={12} md={6}>
                    <Grid item>
                      <div>{t('labels.footer_settings.link1')}</div>
                      <div>{t('labels.footer_settings.link2')}</div>
                    </Grid>
                  </Grid>
                  <Grid item container direction="column" spacing={2} xs={12} md={6}>
                    <Grid item>
                      <div>{t('labels.footer_settings.link3')}</div>
                      <div>{t('labels.footer_settings.link4')}</div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

FooterSettings.propTypes = {
  store: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}
