const quantitySorting = {
  'Quantity: Low-High': 'Quantity Ascending',
  'Quantity: High-Low': 'Quantity Descending',
}

const nameSorting = {
  'Name A--Z': 'Name Ascending',
  'Name Z--A': 'Name Descending'
}

const createdAtSorting = {
  'Latest': 'latest',
  'Oldest': 'oldest'
}

const totalSorting = {
  'Total: Low-High': 'Total Ascending',
  'Total: High-Low': 'Total Descending',
}

const expireSorting = {
  'Expire: Low-High': 'Expires Ascending',
  'Expire: High-Low': 'Expires Descending',
}

export const collectionSorting = Object.assign({}, createdAtSorting, nameSorting);

export const userSorting = Object.assign({}, createdAtSorting, nameSorting);

export const categorySorting = Object.assign({}, createdAtSorting, nameSorting);

export const productSorting = Object.assign({}, createdAtSorting, nameSorting);

export const discountSorting = Object.assign({}, createdAtSorting, expireSorting);

export const orderSorting = Object.assign({}, createdAtSorting, totalSorting);

export const productVariantSorting = Object.assign({}, createdAtSorting, nameSorting, quantitySorting);

export const storeSorting = Object.assign({}, createdAtSorting, nameSorting)

export const fulfillmentOrderSorting = Object.assign({}, createdAtSorting);
