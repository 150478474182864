import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Paper, Typography } from '@mui/material'
import EditOrderItem from './editOrderItem'

const classes = {
  root: {
    flexGrow: 1,
    border: '1px solid lightgrey'
  }
}

export default function EditOrderItems(props) {
  return (
    <Paper sx={classes.root}>
      <Grid container spacing={2} sx={{padding: '1%'}}>
        <Grid item xs={4} md={6} lg={6} xl={6}><Typography variant="h5" >Item</Typography></Grid>
        <Grid item xs={5} md={3} lg={3} xl={3}><Typography variant="h5" >Quantity</Typography></Grid>
        <Grid item xs={2} md={2} lg={2} xl={2}><Typography variant="h5" >Total</Typography></Grid>
        <Grid item xs={0} md={1} lg={1} xl={1}></Grid>
      </Grid>
      {props.orderItems.map(item => !item._deleted && <EditOrderItem key={item.id} {...item} storeCurrency={props.storeCurrency} onItemUpdate={props.onItemUpdate}/>) }
    </Paper>
  )
}

EditOrderItems.propTypes = {
  orderItems: PropTypes.array.isRequired,
  onItemUpdate: PropTypes.func.isRequired,
  storeCurrency: PropTypes.object.isRequired
}
