import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Card, Typography, MenuItem, TextField } from '@mui/material'
import LineChart from '../Helpers/LineChart'

const OrdersChart = ({ ordersData, ordersChartOption, classes, handleOrderChartChange }) => {
  let totalOrders = 0
  ordersData.forEach((orderData) => (totalOrders += parseInt(orderData.y)))
  return (
    <Card elevation={3} sx={classes.ordersChartCard}>
      <Grid container>
        <Grid container sx={classes.ordersChartContainer} item={true} xs={12} md={12} lg={12} xl={12} alignItems="center">
          <Grid item xs={9}>
            <Typography variant='h5' color="textPrimary">Total Orders</Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              value={ordersChartOption}
              variant='outlined'
              label="Duration"
              sx={{float: 'right'}}
              onChange={handleOrderChartChange}
              inputProps={{
                sx: classes.ordersChartSelect
              }}
              select >
              <MenuItem value='7days'>Last 7 days</MenuItem>
              <MenuItem value='last-month'>Last Month</MenuItem>
              <MenuItem value='last-year'>Last Year</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={9}>
            <Typography variant='h5' color="textSecondary">{totalOrders}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <LineChart data={ordersData} seriesName="Orders" height="100%" title="ORDERS OVER TIME" />
        </Grid>
      </Grid>
    </Card>
  )
}

OrdersChart.propTypes = {
  ordersChartOption: PropTypes.string.isRequired,
  handleOrderChartChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  ordersData: PropTypes.array.isRequired
}
  
export default OrdersChart