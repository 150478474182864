export const SET_ERROR = 'SET_ERROR'
export const HIDE_ERROR = 'HIDE_ERROR'

// errorActions.js
export function setError(errors){
  return {
    type: SET_ERROR,
    errors: errors
  }
}

export function hideError(){
  return {
    type: HIDE_ERROR
  }
}
