import React from 'react'

const showErrorMessage = (formErrors) => {
  return (
    <div className={Object.keys(formErrors).length ? 'alert alert-danger' : ''}>
    {Object.keys(formErrors).map((fieldName, i) => {
      if (formErrors[fieldName].length > 0) {
        return (
          <p key={i}>{formErrors[fieldName]}</p>
        )        
      } else {
        return '';
      }
    })}
  </div>
  )
}

const successMessage = (formErrors) => {
  return(
    <div className='alert alert-success'>
      {Object.keys(formErrors).map((fieldName, i) => {
        if (formErrors[fieldName].length > 0) {
          return (
            <p key={i}>{formErrors[fieldName]}</p>
          )        
        } else {
          return '';
        }
      })}
    </div>
  )
}

const FormFlashMessage = ({formErrors}) =>
  { return formErrors.hasOwnProperty('success') ? successMessage(formErrors) : showErrorMessage(formErrors) }
export default FormFlashMessage