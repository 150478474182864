import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Paper,
  TextField,
  MenuItem,
} from '@mui/material'
import StorePermissionTable from './storePermissionsTable'
const styles = {
  headingContainer: {
    padding: '1.5rem',
  },
}

const EditUser = ({ user, validationErrors, onChange, updatePermissions }) => (
  <Paper sx={styles.headingContainer}>
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          label="Name"
          name="name"
          value={`${user.name}`}
          placeholder="Name"
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          select
          name="role"
          label="Role"
          value={user.role}
          placeholder="select"
          onChange={onChange}
          fullWidth
        >
          <MenuItem value="staff">Staff</MenuItem>
          <MenuItem value="superadmin">Super User</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Email"
          name="email"
          value={user.email}
          placeholder="E-post"
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          type="password"
          label="Password"
          name="password"
          autoComplete="off"
          onFocus={(e) => {
            if (e.target.autocomplete) e.target.setAttribute('autocomplete', 'off')
          }}
          value={user.password}
          onChange={onChange}
          fullWidth
        />
      </Grid>
      {user.role === global.constants.userRoles.staff && <StorePermissionTable updatePermissions={updatePermissions} storePermissions={user.meta_store_permissions} />}
    </Grid>
  </Paper>
)

EditUser.propTypes = {
  user: PropTypes.object.isRequired,
  validationErrors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default EditUser
