import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ListingPage from '../../components/Shared/ListingPage'
import { ListTable } from '../../components/Shared/ListingTable'
import * as notificationActions from "../../actions/notificationAction"
import ListTableActions from '../../components/Shared/ListingTable/listTableActions'
import { bindActionCreators } from 'redux'
import { getSerialNumber } from '../../utils/sharedFunctions'
import { userSorting } from '../../config/sortingLabels'
import { UserApi } from '../../api/UserApi'
import User from '../../components/User/user'
import { Button } from '@mui/material'
import useListingActions from '../../hooks/useListingActions'

const UserContainer = () => {
  const {
    data,
    count,
    page,
    perPage,
    searchTerm,
    sortOptionSelected,
    handlerForSearchingAndSortingChange,
  } = useListingActions('users', UserApi.getUsers);

  const history = useHistory()

  const tableActions = (
    <ListTableActions
      searchText='Search Users'
      searchTerm={searchTerm}
      getData={handlerForSearchingAndSortingChange}
      sortOptionSelected={sortOptionSelected}
      sortOptions={userSorting}
    />
  )

  const buttons = (
    <Button variant="contained" onClick={() => history.push('/admin/users/new')}>
      Create User
    </Button>
  )

  const tableContent = userTableContent(data, page, perPage)

  return (
    <ListingPage
      title="Users"
      actions={buttons}
      table={
        <ListTable
          tableHeadings={tableContent.heading}
          tableContent={tableContent.body}
          tableActions={tableActions}
          count={count}
          page_no={page}
          rowsPerPage={perPage}
          onPageChange={(event, pageNo) => handlerForSearchingAndSortingChange('page', pageNo + 1)}
          handleChangeRowsPerPage={(event) => handlerForSearchingAndSortingChange('pageSize', event.target.value)}
          rowsControl={true} />
      } />
  )
}

const userTableContent = (users=[], page_no, per_page) => {
  const body = users.map((user, index) => (
    <User key={user.id} {...user} index={getSerialNumber(page_no, per_page, index)} />
  ))
  return { body: body ?? [], heading: ['Id', 'Name', 'Email', 'Role'] }
}


const mapDispatchToProps = dispatch => {
  return {
    notifyActions: bindActionCreators(notificationActions, dispatch)
  }
}

export default connect(mapDispatchToProps)(UserContainer)
