import { SearchApi } from '../api/searchApi'

// Search
export const SEARCH_PRODUCTS = '[Mstore] SEARCH_PRODUCTS'
export const SEARCH_PRODUCTS_SUCCESS = '[Mstore] SEARCH_PRODUCTS_SUCCESS'
export const SEARCH_PRODUCTS_ERROR = '[Mstore] SEARCH_PRODUCTS_ERROR'

// Get from medtryck.com
export const GET_MT_PRODUCT = '[Mstore] GET_MEDTRYCK_PRODUCT'
export const GET_MT_PRODUCT_SUCCESS = '[Mstore] GET_MEDTRYCK_PRODUCT_SUCCESS'
export const GET_MT_PRODUCT_ERROR = '[Mstore] GET_MEDTRYCK_PRODUCT_ERROR'
export const RESET_MT_PRODUCT = '[Mstore] RESET_MEDTRYCK_PRODUCT'

//Search
export const searchProducts = query => {
  return (dispatch, getState) => {
    dispatch({
      type: SEARCH_PRODUCTS
    })

    SearchApi.searchProducts(query).then(res => {
      dispatch(searchProductsSuccess(res.data))
    })
  }
}

export const searchProductsSuccess = products => {
  return {
    type: SEARCH_PRODUCTS_SUCCESS,
    payload: products
  }
}

export const searchProductsFailure = error => {
  return {
    type: SEARCH_PRODUCTS_ERROR,
    payload: error
  }
}

//Read
export const getProduct = id => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_MT_PRODUCT
    })

    SearchApi.getProduct(id).then(res => {
      dispatch(getProductSuccess(res.data))
    })
  }
}

export const getProductSuccess = product => {
  return {
    type: GET_MT_PRODUCT_SUCCESS,
    payload: product
  }
}

export const getProductFailure = error => {
  return {
    type: GET_MT_PRODUCT_ERROR,
    payload: error
  }
}

export const resetProductSearch = () => {
  return {
    type: RESET_MT_PRODUCT
  }
}