/* eslint-disable react/prop-types */
import React from 'react'
import {CSS} from '@dnd-kit/utilities'
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

const SortingContext = ({ data, setData, sortArray, DataComponent, onUpdate, optionalParams={}, addDelay=false }) => {

  const sensors = useSensors(
    addDelay ? useSensor(PointerSensor, { activationConstraint: { delay: '200' } }) : useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragEnd = event => {
    const { active, over } = event
    if (active?.id !== over?.id) {
      const oldIndex = data.findIndex(v => v.id ?  v.id === active?.id : v._id === active?.id )
      const newIndex = data.findIndex(v => v.id ?  v.id === over?.id : v._id === over?.id )
      var reordered = arrayMove(data, oldIndex, newIndex)
      reordered = reordered.map((v, index) => { return {...v, position: index} })
      setData(reordered)
      sortArray(reordered)
    }
  }

  return(
    <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext 
        items={data}
        strategy={verticalListSortingStrategy}
      >
        {data.map((row, index) => (
          <SortableItem key={`item-${(row?.id || row?._id || index)}`} objects={data} data={row} DataComponent={DataComponent} object={row} onUpdate={onUpdate} optionalParams={optionalParams} />
        ))
        }
      </SortableContext>
    </DndContext>
  )
}

const SortableItem = ((props) => {
  const { data, DataComponent, onUpdate, objects, optionalParams } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable(
    { id: (data.id) },
  )

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} style={style} >
      {<DataComponent objects={objects} object={data} onUpdate={onUpdate} attributes={attributes} listeners={listeners} optionalParams={optionalParams} />}
    </div>
  )
})

  
export default SortingContext