export const categoryCSVHeaders = [
  { label: 'Id', key: 'id' },
  { label: 'Name', key: 'name' },
  { label: 'Visible', key: 'visible' },
  { label: 'Show in top menu', key: 'show_in_top_menu' },
  { label: 'Show in side menu', key: 'show_in_side_menu' },
  { label: 'Show in footer', key: 'show_in_footer' },
  { label: 'Show on homepage', key: 'show_on_homepage' },
  { label: 'Description', key: 'description' },
  { label: 'Created at', key: 'createdAt' },
]

export const collectionCSVHeaders = [
  { label: 'Id', key: 'id' },
  { label: 'Name', key: 'name' },
  { label: 'Active', key: 'active' },
  { label: 'Is shopable', key: 'is_shopable' },
  { label: 'Description', key: 'description' },
  { label: 'Image url', key: 'image' },
  { label: 'Created at', key: 'createdAt' },
]

export const discountCSVHeaders = [
  { label: 'Id', key: 'id' },
  { label: 'Name', key: 'name' },
  { label: 'Active', key: 'active' },
  { label: 'Single use', key: 'single_use' },
  { label: 'Expires at', key: 'expires_at' },
  { label: 'Prefix', key: 'prefix' },
  { label: 'Value', key: 'value' },
  { label: 'Is Percentage', key: 'is_percentage' },
  { label: 'Multiple Coupons', key: 'multiple_coupons' },
  { label: 'Limit Items', key: 'limit_no_of_items' },
  { label: 'Number of items', key: 'no_of_items' },
  { label: 'Is Shopable', key: 'is_shopable' },
  { label: 'Description', key: 'description' },
  { label: 'Image url', key: 'image' },
  { label: 'Created at', key: 'createdAt' },
]

export const productVariantCSVHeaders = [
  { label: 'Variant Name', key: 'name' },
  { label: 'Product Name', key: 'product' },
  { label: 'Article', key: 'article' },
  { label: 'SKU', key: 'sku' },
  { label: 'Price', key: 'price' },
  { label: 'Quantity', key: 'quantity' },
  { label: 'Incoming Stock Quantity', key: 'incoming_stock_quantity' },
  { label: 'Product Inventories', key: 'product_inventories' },
  { label: 'Created at', key: 'createdAt' },
]
export const productCSVHeaders =  [
  { label: 'Name', key: 'name' },
  { label: 'Visible', key: 'visible' },
  { label: 'Category Name', key: 'category' },
  { label: 'Origin Country', key: 'origin_country' },
  { label: 'HS Code', key: 'hs_code' },
  { label: 'Article', key: 'article_no' },
  { label: 'SKU', key: 'sku' },
  { label: 'Freight', key: 'freight' },
  { label: 'Price', key: 'price' },
  { label: 'Stock', key: 'stock' },
  { label: 'Has Variants', key: 'has_variants' },
  { label: 'Is Bundle', key: 'is_bundle' },
  { label: 'Manage Stock', key: 'manage_stock' },
  { label: 'Allow Backorders', key: 'allow_backorders' },
  { label: 'Variants', key: 'variants' },
  { label: 'Listing Price', key: 'listing_price' },
  { label: 'Created at', key: 'createdAt' },
]
