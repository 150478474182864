import React, { useState, useEffect } from "react";

import ListingPage from "../../components/Shared/ListingPage";
import { ListTable } from "../../components/Shared/ListingTable";
import ListTableActions from '../../components/Shared/ListingTable/listTableActions'
import ProductInventory from "../../components/Product/productInventory";
import { ProductApi } from "../../api/productApi";
import { getSerialNumber } from "../../utils/sharedFunctions";
import { productVariantSorting } from "../../config/sortingLabels";
import useListingActions from '../../hooks/useListingActions'
import { Grid, Autocomplete, TextField } from '@mui/material';
import { StoreApi } from '../../api/storeApi'

const styles = {
  actionBtn: {
    textTransform: 'none',
    marginTop: '2%',
    float: 'right'
  },
  autocomplete: {
    width: 200,
  }
}

const ProductInventoryContainer = () => {
  const [stores, setStores] = useState([])
  const [selectedStore, setSelectedStore] = useState(null)

  useEffect(() => {
    const fetchAllStores = async () => {
      const response = await StoreApi.getStores({ page: -1 });
      const stores = response.data.data.map((store) => ({
        id: store.id,
        name: store.name
      }));
      setStores(stores);
      if (stores.length > 0) {
        setSelectedStore(stores[0]);
      }
    };

    fetchAllStores();
  }, []);

  const handleStoreChange = (e, value) => {
    setSelectedStore(value)
    fetchData({ page: 1, per_page: perPage, q: searchTerm, sort_by: sortOptionSelected, ...value && {store_id: value.id} }, true)
  }


  const handleExtraParameters = () => {
    if (selectedStore) {
      return { store_id: selectedStore.id }
    } else {
      return {}
    }
  }

  const {
    data,
    count,
    page,
    perPage,
    searchTerm,
    setData,
    sortOptionSelected,
    handlerForSearchingAndSortingChange,
    fetchData,
  } = useListingActions('product_inventory', ProductApi.getProductVariants, 'latest', handleExtraParameters );

    useEffect(() => {
    if (selectedStore) {
      fetchData({ page: 1, per_page: perPage, q: searchTerm, sort_by: sortOptionSelected, store_id: selectedStore.id }, true);
    }
  }, [selectedStore]);

  const tableContent = productTableContent( data, page, perPage, selectedStore?.id)

  const tableActions = (
    <ListTableActions
      searchTerm={searchTerm}
      searchText='Search Products and Variants'
      sortOptionSelected={sortOptionSelected}
      getData={handlerForSearchingAndSortingChange}
      sortOptions={productVariantSorting}
    />
  )
  const buttons = (
    <Grid container justifyContent="flex-end" spacing={1}>
    <Grid item>
      <Autocomplete
        disablePortal
        id='stores'
        size='small'
        options={stores}
        getOptionLabel={(store) => store.name}
        onChange={handleStoreChange}
        sx={styles.autocomplete}
        renderInput={(params) => (<TextField {...params} label='Search Store' />
        )}
      />
    </Grid>
  </Grid>
  )

  return (
    <ListingPage
      title="Product Inventory"
      actions={buttons}
      table={
        <ListTable
          tableHeadings={tableContent.productInventoryHeading}
          tableContent={tableContent.productInventoryBody}
          tableActions={tableActions}
          count={count}
          page_no={page}
          rowsPerPage={perPage}
          onPageChange={(event, pageNo) => handlerForSearchingAndSortingChange('page', pageNo + 1)}
          handleChangeRowsPerPage={(event) => handlerForSearchingAndSortingChange('pageSize', event.target.value)}
          rowsControl={true} />
      } />
  )
}

const productTableContent = ( productVariants=[], page_no, per_page, storeId) => {
  const productInventoryBody = productVariants.map( (productVariant,index) => (
    <ProductInventory key={index} productVariant={productVariant} index={getSerialNumber(page_no, per_page, index)} storeId={storeId}/>
  ))
  return { productInventoryBody: productInventoryBody, productInventoryHeading: ['Id', '', 'Name', 'SKU', 'Incoming', 'Available', 'Waitlist', ''] }
}

export default ProductInventoryContainer;