import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { closeNotification } from '../actions/notificationAction';

const NotifyInfo = () => {
    const dispatch = useDispatch()
    const notification = useSelector( state => state.notifications)

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = () => {
        dispatch(closeNotification())
    }

    return (
        <React.Fragment>
        {
          <Snackbar
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={notification?.open}
          autoHideDuration={notification?.duration ?? 3000}
          onClose={handleClose}>
              <Alert onClose={handleClose} severity={notification?.notificationType}>
                {notification?.message}
              </Alert>
          </Snackbar>
        }
        </React.Fragment>
    )
};

export default NotifyInfo
