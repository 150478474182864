import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'

const ListingPage = ({ title, actions, table }) => (
  <Grid container spacing={3} alignItems="center">
    <Grid item xs={12} md={6} lg={6} xl={7}>
      <Typography variant="h3" component="div">{title}</Typography>
    </Grid>
    <Grid container item xs={12} md={6} lg={6} xl={5} justifyContent="flex-end" >
      {actions}
    </Grid>
    <Grid container item xs={12}>
      <Grid item xs={12}>
        {table}
      </Grid>
    </Grid>
  </Grid>
)
  
  
ListingPage.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.object,
  table: PropTypes.object.isRequired
}
  
export default ListingPage
