import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Select, MenuItem, Typography, FormHelperText, InputLabel, FormControl } from '@mui/material';
import FieldGroup from '../fieldGroup';
import EditPageUpperContainer from '../Shared/EditPageHeader';
import EditPageLowerContainer from '../Shared/EditPageLowerContainer';
import AddStores from '../Shared/AddStores';

const EditFulfillmentCenter = ({
  fulfillmentCenter,
  validationErrors,
  onChange,
  onSave,
  stores,
  updateStore
}) => {
  const actionType = fulfillmentCenter?.id > 0 ? 'Edit' : 'New';
  const [selectedStore, setSelectedStore] = useState('');

  const onRemoveStoreClicked = (storeId) => {
    if (storeId) updateStore(storeId, fulfillmentCenter.id, 'remove');
  }

  const onStoreSelected = (event, option) => {
    if (option?.id) updateStore(option.id, fulfillmentCenter.id);
  }

  const leftFirstContent = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <FieldGroup
          id="fulfillmentCenterName"
          type="text"
          label="Name"
          name="name"
          value={fulfillmentCenter?.name || ""}
          validationState={validationErrors?.hasOwnProperty('name') ? 'error' : null}
          placeholder="Name"
          onChange={onChange}
          required
        />
        {validationErrors?.name && (
          <FormHelperText error>{validationErrors.name}</FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldGroup
          id="fulfillmentCenterEmail"
          type="email"
          label="Email"
          name="email"
          value={fulfillmentCenter?.email || ""}
          validationState={validationErrors?.hasOwnProperty('email') ? 'error' : null}
          placeholder="Email"
          onChange={onChange}
          required
        />
        {validationErrors?.email && (
          <FormHelperText error>{validationErrors.email}</FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldGroup
          id="fulfillmentCenterAddress"
          type="text"
          label="Address"
          name="address"
          value={fulfillmentCenter?.address || ""}
          validationState={validationErrors?.hasOwnProperty('address') ? 'error' : null}
          placeholder="Address"
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldGroup
          id="fulfillmentCenterCity"
          type="text"
          label="City"
          name="city"
          value={fulfillmentCenter?.city || ""}
          validationState={validationErrors?.hasOwnProperty('city') ? 'error' : null}
          placeholder="City"
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldGroup
          id="fulfillmentCenterState"
          type="text"
          label="State"
          name="state"
          value={fulfillmentCenter?.state || ""}
          validationState={validationErrors?.hasOwnProperty('state') ? 'error' : null}
          placeholder="State"
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldGroup
          id="fulfillmentCenterCountry"
          type="text"
          label="Country"
          name="country"
          value={fulfillmentCenter?.country || ""}
          validationState={validationErrors?.hasOwnProperty('country') ? 'error' : null}
          placeholder="Country"
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldGroup
          id="fulfillmentCenterZipCode"
          type="text"
          label="Zip Code"
          name="zip_code"
          value={fulfillmentCenter?.zip_code || ""}
          validationState={validationErrors?.hasOwnProperty('zip_code') ? 'error' : null}
          placeholder="Zip Code"
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldGroup
          id="fulfillmentCenterProviderType"
          type="text"
          label="Provider Type"
          name="provider_type"
          value="custom"
          validationState={validationErrors?.hasOwnProperty('provider_type') ? 'error' : null}
          placeholder="Provider Type"
          disabled={true}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  )

  const rightFirstContent = () => (
    <>
      <Grid item xs={12} md={12}>
        <Typography gutterBottom variant="h5" component="h2">
          Status
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl fullWidth>
          <InputLabel id="fulfillmentCenterStatus-label">Status</InputLabel>
          <Select
            labelId="fulfillmentCenterStatus-label"
            id="fulfillmentCenterStatus"
            name="status"
            value={fulfillmentCenter?.status || 'inactive'} // default value as 'inactive'
            onChange={onChange}
            label="Status"
            error={validationErrors?.hasOwnProperty('status')}
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </Select>
          {validationErrors?.hasOwnProperty('status') && (
            <FormHelperText error>{validationErrors['status']}</FormHelperText>
          )}
        </FormControl>
        {
          fulfillmentCenter?.id > 0 && (
            <AddStores
              stores={stores}
              selectedStore={selectedStore}
              setSelectedStore={setSelectedStore}
              onStoreSelected={onStoreSelected}
              onRemoveStoreClicked={onRemoveStoreClicked}
              fulfillmentCenter={fulfillmentCenter}
            />
          )
        }
      </Grid>
    </>
  )

  return (
    <div>
      <EditPageUpperContainer actionType={actionType} page='Fulfillment Center' onSave={onSave} />
      <form onSubmit={onSave}>
        <EditPageLowerContainer
          leftContent={[leftFirstContent]}
          rightContent={[rightFirstContent]}
          actionType={actionType}
          onSave={onSave}
        />
      </form>
    </div>
  );
}

EditFulfillmentCenter.propTypes = {
  fulfillmentCenter: PropTypes.object.isRequired,
  validationErrors: PropTypes.object,
  isSaving: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  stores: PropTypes.array,
  selectedStore: PropTypes.string,
  setSelectedStore: PropTypes.func.isRequired,
  updateStore: PropTypes.func.isRequired
};

export default EditFulfillmentCenter;