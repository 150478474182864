import { OrderApi } from '../api/orderApi'

// Read
export const GET_ORDERS = '[Mstore] GET_ORDERS'
export const GET_ORDERS_SUCCESS = '[Mstore] GET_ORDERS_SUCCESS'
export const GET_ORDERS_ERROR = '[Mstore] GET_ORDERS_ERROR'

// Create
export const CREATE_ORDER = '[Mstore] CREATE_ORDER'
export const CREATE_ORDER_SUCCESS = '[Mstore] CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_ERROR = '[Mstore] CREATE_ORDER_ERROR'


export const GET_ORDER = '[Mstore] GET_ORDER'
export const GET_ORDER_SUCCESS = '[Mstore] GET_ORDER_SUCCESS'
export const GET_ORDER_ERROR = '[Mstore] GET_ORDER_ERROR'

// Update
export const UPDATE_ORDER = '[Mstore] UPDATE_ORDER'
export const UPDATE_ORDER_SUCCESS = '[Mstore] UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_ERROR = '[Mstore] UPDATE_ORDER_ERROR'

// Delete
export const DELETE_ORDER = '[Mstore] DELETE_ORDER'
export const DELETE_ORDER_SUCCESS = '[Mstore] DELETE_ORDER_SUCCESS'
export const DELETE_ORDER_ERROR = '[Mstore] DELETE_ORDER_ERROR'

//Read
export const getOrders = (params) => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_ORDERS
    })
    OrderApi.getOrders(params).then(res => {
      dispatch(getOrdersSuccess(res.data))
    })
  }
}

export const getOrdersSuccess = (data) => {
  return {
    type: GET_ORDERS_SUCCESS,
    payload: data
  }
}

export const getOrdersFailure = (error) => {
  return {
    type: GET_ORDERS_ERROR,
    payload: error
  }
}

export const getOrder = (id) => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_ORDER
    })

    OrderApi.getOrder(id).then(res => {
      dispatch(getOrderSuccess(res.data))
    })
  }
}

export const getOrderSuccess = (order) => {
  return {
    type: GET_ORDER_SUCCESS,
    payload: order
  }
}

export const getOrderFailure = (error) => {
  return {
    type: GET_ORDER_ERROR,
    payload: error
  }
}

// Create
export const createOrder = order => {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_ORDER
    })

    OrderApi.createOrder(order).then(res => {
      dispatch(createOrderSuccess(res.data))
    })
  }
}

export const createOrderSuccess = order => {
  return {
    type: CREATE_ORDER_SUCCESS,
    payload: order
  }
}

export const createOrderFailure = error => {
  return {
    type: CREATE_ORDER_ERROR,
    payload: error
  }
}

//Update
export const updateOrder = (id, order) => {
  return (dispatch, getState) => {
    //Multiple actions can be sent usign the Redux-Thunk middleware
    dispatch({
      type: UPDATE_ORDER,
      payload: order
    })
    OrderApi.updateOrder(id, order).then(res => {
      dispatch(updateOrderSuccess(res.data))
    })
  }
}
export const updateOrderSuccess = order => {
  return {
    type: UPDATE_ORDER_SUCCESS,
    payload: order
  }
}

//Delete
export const deleteOrder = (order) => {
  return (dispatch, getState) => {
    dispatch({
      type: DELETE_ORDER,
      payload: order
    })

    OrderApi.removeOrder(order).then(res => {
      if (res.status === 204) {
        dispatch(deleteOrderSuccess(order))
      }
    })
  }
}

export const deleteOrderSuccess = (order) => {
  return {
    type: DELETE_ORDER_SUCCESS,
    payload: order
  }
}
