import React from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Grid
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import PropTypes from 'prop-types'
import CustomTablePagination from '../CustomTablePagination';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 'bold',
    fontSize: 14,
    backgroundColor: theme.palette.action.hover
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const ListTable = ({ tableActions, tableHeadings, tableContent, count, page_no, onPageChange, rowsPerPage, handleChangeRowsPerPage, rowsControl }) => {
  return (
    <>
      <Grid container alignItems={'center'}>
        <Grid item xs={7}>
          {tableActions}
        </Grid>
        {(parseInt(count) > 0 ) ? (
          <Grid item xs={5}>
            <CustomTablePagination
              count={count}
              page_no={page_no}
              onPageChange={onPageChange}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsControl={rowsControl} />
          </Grid>

        ): (<br />)}
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              { tableHeadings.map((heading, index) => (
                <StyledTableCell sx={{ minWidth: (index === 1 && '50px')}} key={index}>{heading}</StyledTableCell>)
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            { tableContent.map((row) => (row)) }
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justifyContent="flex-end">
        <Grid item>
          {parseInt(count) > 0 && (
            <CustomTablePagination
              count={count}
              page_no={page_no}
              onPageChange={onPageChange}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsControl={rowsControl} />
          )}
        </Grid>
      </Grid>
    </>
  )
}

ListTable.propTypes = {
  tableHeadings: PropTypes.array.isRequired,
  tableContent: PropTypes.array.isRequired,
  count: PropTypes.number,
  page_no: PropTypes.number,
  onPageChange: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  rowsControl: PropTypes.bool
}

export default ListTable