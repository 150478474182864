import React, { useState, useEffect } from 'react';
import { Modal, Fade, Paper, Button, Backdrop, Divider, TextField, Grid, IconButton, Box, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: { xs: '95%', sm: '70%', md: '60%', lg: '50%' },
    boxShadow: 24
  },
  container: {
    padding: '2%'
  }
}
const CustomModal = (props) => {
  const { modalBody, title, modalState, handleModalState } = props;

  return (
    <Modal
      open={modalState}
      onClose={() => handleModalState(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalState}>
        <Paper sx={styles.modal}>
          <Grid container alignItems="center">
            <Grid item xs={11} sx={{paddingLeft: '2%'}}>
              <h3>{title}</h3>
            </Grid>
            <Grid item xs={1}>
              <IconButton sx={{float: 'right'}} onClick={() => handleModalState(false)}><CloseIcon /></IconButton>
            </Grid>
            <Divider width='100%' />
            <Grid item xs={12} md={12}>
              {modalBody}
            </Grid>
            <Divider width="100%" />
            <Grid item sx={styles.container} xs={12} sm={12} textAlign="end">
              <Button variant='outlined' onClick={() => handleModalState(false)}>{global.constants.labels.close}</Button>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  );
}

export default CustomModal;