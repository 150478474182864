import React, { useState, useCallback, useMemo } from 'react'
import {
  Modal, Paper, Button, TextField, Radio, RadioGroup, FormControlLabel,
  FormControl, Grid, IconButton, Divider, CircularProgress, Backdrop, MenuItem, Select, InputLabel
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import { StoreApi } from '../api/storeApi'
import { makeStyles } from '@mui/styles'
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
    boxShadow: 24,
    padding: '20px',
  },
  container: {
    padding: '2%',
    flexDirection: 'column',
    alignItems: 'left',
  },
  actionBtn: {
    textTransform: 'none',
    marginRight: '2%',
  },
}))

const CreateStoreModal = ({ open, onClose, reloadStores, stores }) => {
  const classes = useStyles()
  const { t } = useTranslation();

  const [storeName, setStoreName] = useState('')
  const [customerId, setCustomerId] = useState('')
  const [option, setOption] = useState('demo')
  const [selectedStoreId, setSelectedStoreId] = useState('')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({ storeName: false, customerId: false })

  const handleCreateStore = useCallback(async () => {
    if (!storeName || !customerId) {
      setErrors({
        storeName: !storeName,
        customerId: !customerId,
      });
      return;
    }
  
    setLoading(true);
    try {
      let newStore;
      if (option === 'demo') {
        newStore = await StoreApi.createStoreWithDemo({ name: storeName, isDemoData: true, customerId });
      } else if (option === 'duplicate') {
        newStore = await StoreApi.duplicateStore({ name: storeName, storeId: selectedStoreId, customerId });
      } else if (option === 'no demo') {
        newStore = await StoreApi.createStoreWithDemo({ name: storeName, isDemoData: false, customerId });
      }
  
      onClose();
      reloadStores(newStore);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [storeName, customerId, option, selectedStoreId, onClose, reloadStores]);  

  const storeOptions = useMemo(() => (
    stores.map((store) => (
      <MenuItem key={store.id} value={store.id}>
        {store.name}
      </MenuItem>
    ))
  ), [stores])

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Paper className={classes.modal}>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container alignItems="center">
          <Grid item xs={11} sx={{ paddingLeft: '2%' }}>
            <h3>{t('labels.create_store')}</h3>
          </Grid>
          <Grid item xs={1}>
            <IconButton sx={{ float: 'right' }} onClick={onClose}><CloseIcon /></IconButton>
          </Grid>
          <Divider width='100%' />
          <Grid item xs={12}>
            <div className={classes.container}>
              <TextField
                label={t('labels.store_name')}
                fullWidth
                value={storeName}
                onChange={(e) => setStoreName(e.target.value)}
                error={errors.storeName}
                helperText={errors.storeName ? 'Store Name cannot be blank' : ''}
                sx={{ marginBottom: '20px' }}
              />
              <TextField
                label={t('labels.customer_id')}
                fullWidth
                value={customerId}
                onChange={(e) => setCustomerId(e.target.value)}
                error={errors.customerId}
                helperText={errors.customerId ? 'Customer ID cannot be blank' : ''}
                sx={{ marginBottom: '20px' }}
              />
              <FormControl component="fieldset" sx={{ marginBottom: '20px' }}>
                <RadioGroup
                  value={option}
                  onChange={(e) => setOption(e.target.value)}
                >
                  <FormControlLabel value="demo" control={<Radio />} label={t('labels.create_store_with_demo_data')} />
                  <FormControlLabel value="duplicate" control={<Radio />} label={t('labels.duplicate_existing_store')} />
                  <FormControlLabel value="no demo" control={<Radio />} label={t('labels.create_store_without_demo_data')} />
                </RadioGroup>
              </FormControl>
              {option === 'duplicate' && (
                <FormControl fullWidth sx={{ marginBottom: '20px' }}>
                  <InputLabel id="select-store-label">{t('labels.select_store')}</InputLabel>
                  <Select
                    labelId="select-store-label"
                    value={selectedStoreId}
                    onChange={(e) => setSelectedStoreId(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {storeOptions}
                  </Select>
                </FormControl>
              )}
            </div>
          </Grid>
          <Divider width="100%" />
          <Grid item className={classes.container} xs={12} textAlign="end">
            <Button 
              variant="contained" 
              className={classes.actionBtn} 
              color="primary" 
              onClick={handleCreateStore} 
              disabled={loading || !storeName || !customerId}
            >
              {t('labels.create')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  )
}

CreateStoreModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reloadStores: PropTypes.func.isRequired,
  stores: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default CreateStoreModal