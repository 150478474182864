import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import OrderFulfillmentCenterContainer from '../containers/OrderFulfillmentCenter/orderFulfillmentCenterContainer'
import ShowOrderFulfillmentCenterContainer from '../containers/OrderFulfillmentCenter/showOrderFulfillmentCenterContainer'

const OrderFulfillmentCenter = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={OrderFulfillmentCenterContainer} />
    <Route path={`${match.path}/:id`} component={ShowOrderFulfillmentCenterContainer} />
  </Switch>
)

OrderFulfillmentCenter.propTypes = {  
  match: PropTypes.object,
}

export default OrderFulfillmentCenter
